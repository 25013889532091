import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";

import { BoilerConfigFloorPlan } from "./config.floorPlan";
import { BoilerConfigImages } from "./config.images";
import { BoilerConfigTexts } from "./config.texts";
import { BoilerTypes } from "./enums";

type Config = GuideItemTypeConfig<GuideItemTypeCategory.BOILER, BoilerTypes>;

const types: Config["types"] = [BoilerTypes.DEFAULT];

export const BoilerConfig: Config = {
  guideType: GuideType.MECHANICAL,
  category: GuideItemTypeCategory.BOILER,
  types,
  typesOrder: getOrderedMap(types),
  texts: BoilerConfigTexts,
  images: BoilerConfigImages,
  floorPlan: BoilerConfigFloorPlan,
  allowedInRooms: undefined,
  allowedIntents: undefined,
} as const;
