import { ReactComponent as IconFlashmount } from "constants/guides/Electrical/LightingFixtures/assets/icon_recessed.svg";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { ReactComponent as CategoryIcon } from "./assets/icon_category_wall_material.svg";
import brickFallback from "./assets/wall_brick.png";
import brick from "./assets/wall_brick.webp";
import dryWallFallback from "./assets/wall_drywall.png";
import dryWall from "./assets/wall_drywall.webp";
import lathAndPlasterFallback from "./assets/wall_lath_and_plaster.png";
import lathAndPlaster from "./assets/wall_lath_and_plaster.webp";
import mainImageFallback from "./assets/wall_main.jpeg";
import mainImage from "./assets/wall_main.webp";
import stoneFallback from "./assets/wall_stone.png";
import stone from "./assets/wall_stone.webp";
import tileFallback from "./assets/wall_tile.png";
import tile from "./assets/wall_tile.webp";
import woodFallback from "./assets/wall_wood.png";
import wood from "./assets/wall_wood.webp";
import { WallMaterialTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.WALL_MATERIAL,
  WallMaterialTypes
>["images"];

const images: Config["images"] = {
  [WallMaterialTypes.DRYWALL]: { src: dryWall, fallbackSrc: dryWallFallback },
  [WallMaterialTypes.LATH_AND_PLASTER]: {
    src: lathAndPlaster,
    fallbackSrc: lathAndPlasterFallback,
  },
  [WallMaterialTypes.WOOD]: { src: wood, fallbackSrc: woodFallback },
  [WallMaterialTypes.TILE]: { src: tile, fallbackSrc: tileFallback },
  [WallMaterialTypes.BRICK]: { src: brick, fallbackSrc: brickFallback },
  [WallMaterialTypes.STONE]: { src: stone, fallbackSrc: stoneFallback },
};

// TODO: Correct icons
const icons: Config["icons"] = {
  [WallMaterialTypes.DRYWALL]: IconFlashmount,
  [WallMaterialTypes.LATH_AND_PLASTER]: IconFlashmount,
  [WallMaterialTypes.WOOD]: IconFlashmount,
  [WallMaterialTypes.TILE]: IconFlashmount,
  [WallMaterialTypes.BRICK]: IconFlashmount,
  [WallMaterialTypes.STONE]: IconFlashmount,
};

export const WallMaterialsConfigImages: Config = {
  images,
  icons,
  categoryIcon: CategoryIcon,
  defaultIcon: icons[WallMaterialTypes.DRYWALL],
  defaultImage: {
    src: mainImage,
    fallbackSrc: mainImageFallback,
  },
};
