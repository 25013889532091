import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { ROOMS_PIECES_VALUES, RoomType } from "constants/rooms/roomPieces";
import { RULE_DEFAULT_CANNOT_OVERLAP } from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/PixiFloorPlanItem/PixiFloorPlanItem.rules";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.ROOM,
  RoomType
>["floorPlan"];

const sizes = ROOMS_PIECES_VALUES.reduce((acc, curr) => {
  acc[curr] = { width: 15, height: 15 };

  return acc;
}, {} as Config["sizes"]);

const rules = ROOMS_PIECES_VALUES.reduce((acc, curr) => {
  acc[curr] = RULE_DEFAULT_CANNOT_OVERLAP;

  return acc;
}, {} as Config["rules"]);

export const RoomConfigFloorPlan: Config = {
  sizes,
  rules,
};
