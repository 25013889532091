import { OrchestratorHIPSContext } from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { uuid } from "shared/util/uuid";

import { HIPsDashboardMachineContext } from "./dashboard/HIPsDashboardMachineContext";

export const createHIPsContext = (): OrchestratorHIPSContext => ({
  id: uuid(),
  onboarding: {
    title: undefined,
    budget: undefined,
    reserve: undefined,
    people: [],
  },
  dashboard: HIPsDashboardMachineContext,
  planningAreas: {
    rooms: [],
  },
  coverPhoto: undefined,
  output: {
    notes: {},
  },
  scopeOfWork: {
    guides: [],
    fakeGuides: [],
  },
});
