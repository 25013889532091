import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { LightingFixtureTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.LIGHTING_FIXTURE,
  LightingFixtureTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [LightingFixtureTypes.FLUSHMOUNT]: t("Flush Mount"),
  [LightingFixtureTypes.HANGING_LIGHT]: t("Pendant"),
  [LightingFixtureTypes.RECESSED_LIGHT]: t("Recessed"),
  [LightingFixtureTypes.WALL_SCONCES]: t("Wall Sconce"),
  [LightingFixtureTypes.MONORAIL]: t("Track"),
  [LightingFixtureTypes.LED]: t("LED"),
  [LightingFixtureTypes.SEMI_FLUSHMOUNT]: t("Semi Flush Mount"),
  [LightingFixtureTypes.CHANDELIER]: t("Chandelier"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [LightingFixtureTypes.FLUSHMOUNT]: t("Flush Mount"),
  [LightingFixtureTypes.HANGING_LIGHT]: t("Pendant"),
  [LightingFixtureTypes.RECESSED_LIGHT]: t("Recessed"),
  [LightingFixtureTypes.WALL_SCONCES]: t("Wall Sconce"),
  [LightingFixtureTypes.MONORAIL]: t("Track"),
  [LightingFixtureTypes.LED]: t("LED"),
  [LightingFixtureTypes.SEMI_FLUSHMOUNT]: t("Semi Flush Mount"),
  [LightingFixtureTypes.CHANDELIER]: t("Chandelier"),
});

export const LightingFixturesConfigTexts: Config = {
  categoryName: (t) => t("Lighting Fixtures"),
  itemName: (t) => t("Lighting Fixture"),
  categoryDescription: (t) =>
    t(
      "Interior lighting design is one of the most important interior design basics, yet it's often the most neglected by DIY decorators. No matter how carefully you've furnished, arranged, and accessorized, you do not have a well-decorated home until you've implemented some lighting design basics."
    ),
  itemNames,
  typeNames,
};
