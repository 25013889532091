import { assign } from "@xstate/immer";
import type { RoomRouteParams } from "common/hooks/useRoomParams";
import { getActivePropertyContext } from "core/state/global/OrchestratorMachine/actions.utils";
import {
  AddFilteredTasksEventData,
  HIPsOutputNotePayload,
  PlanningAreaEventData,
} from "core/state/global/OrchestratorMachine/hips/Models";
import {
  OrchestratorEvent,
  OrchestratorMachineContext,
} from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { useStableNavigate } from "core/state/hooks/useStableNavigate";
import { findLast } from "lodash-es";
import { GuideIntentType } from "pages/Guides/enums";
import { HIPsRouteParams } from "router/models";
import { GUIDE, HIPS_ROOM } from "router/routes";
import { findBy } from "shared/util/findBy";

interface Props {
  navigate: ReturnType<typeof useStableNavigate>;
}

export const HIPsDashboardMachineActions = ({ navigate }: Props) => ({
  updateRooms: assign(
    (
      _ctx: OrchestratorMachineContext,
      event: OrchestratorEvent<AddFilteredTasksEventData>
    ) => {
      const ctx = getActivePropertyContext(_ctx);
      const hips = findBy(ctx.hips, "id", event.data.hipsId);
      hips.planningAreas.rooms = [...event.data.rooms];
    }
  ),
  addAllRoomsToPlanningArea: assign(
    (_ctx: OrchestratorMachineContext, event: OrchestratorEvent<string>) => {
      const ctx = getActivePropertyContext(_ctx);
      const hips = findBy(ctx.hips, "id", event.data);
      hips.planningAreas.rooms = [...ctx.rooms];
    }
  ),
  addFilteredRoomsToPlanningArea: assign(
    (
      _ctx: OrchestratorMachineContext,
      event: OrchestratorEvent<AddFilteredTasksEventData>
    ) => {
      const ctx = getActivePropertyContext(_ctx);
      const hips = findBy(ctx.hips, "id", event.data.hipsId);
      hips.planningAreas.rooms = [...event.data.rooms];
    }
  ),
  removeAllRoomsFromPlanningArea: assign(
    (_ctx: OrchestratorMachineContext, event: OrchestratorEvent<string>) => {
      const ctx = getActivePropertyContext(_ctx);
      const hips = findBy(ctx.hips, "id", event.data);
      hips.planningAreas.rooms = [];
    }
  ),
  addRoomToPlanningArea: assign(
    (
      _ctx: OrchestratorMachineContext,
      event: OrchestratorEvent<PlanningAreaEventData>
    ) => {
      const ctx = getActivePropertyContext(_ctx);
      const { hipsId, roomId } = event.data;
      const room = findBy(ctx.rooms, "id", roomId);
      if (room) {
        const hips = findBy(ctx.hips, "id", hipsId);
        hips.planningAreas.rooms.push(room);
      }
    }
  ),
  removeRoomFromPlanningArea: assign(
    (
      _ctx: OrchestratorMachineContext,
      event: OrchestratorEvent<PlanningAreaEventData>
    ) => {
      const ctx = getActivePropertyContext(_ctx);
      const { hipsId, roomId } = event.data;
      const hips = findBy(ctx.hips, "id", hipsId);
      hips.planningAreas.rooms = hips.planningAreas.rooms.filter(
        (room) => room.id !== roomId
      );
    }
  ),
  goToRoomPage(
    _ctx: OrchestratorMachineContext,
    event: OrchestratorEvent<HIPsRouteParams>
  ) {
    const { hips } = getActivePropertyContext(_ctx);
    const { hipsId, propertyId } = event.data;
    const hip = findBy(hips, "id", hipsId);

    const guideResultsIds = hip.planningAreas.rooms.flatMap((room) => {
      const guideResultsKeys: Array<GuideIntentType | "cs"> = [
        "cs",
        ...Object.values(GuideIntentType),
      ];

      const idsForRoom = guideResultsKeys.flatMap((guideResultKey) => {
        if (!room.guideResults) {
          return [];
        }

        const guideResultsRoomIds = room.guideResults[
          guideResultKey
        ].items.flatMap((it) => it.id);
        return guideResultsRoomIds;
      });

      return idsForRoom;
    });

    const nextGuide = hip.scopeOfWork.guides.find(
      (guide) => !guideResultsIds.includes(guide.id)
    );

    if (!nextGuide) {
      throw Error(`No next guide found for hipsId: ${hipsId}`);
    }

    const params: RoomRouteParams = {
      hipsId,
      roomId: nextGuide.roomId,
      propertyId,
    };

    navigate(HIPS_ROOM(params));
  },
  continueGuide(
    _ctx: OrchestratorMachineContext,
    event: OrchestratorEvent<HIPsRouteParams>
  ) {
    const { property, activeGuides } = getActivePropertyContext(_ctx);
    const propertyId = property.id;
    const { hipsId } = event.data;

    const activeGuide = findLast(
      activeGuides,
      (guide) => guide._hipId === hipsId
    );

    if (!activeGuide) {
      throw new Error(`Cannot continue guide with HIP ID [${hipsId}]`);
    }

    const params: RoomRouteParams = {
      hipsId,
      roomId: activeGuide._roomId,
      propertyId,
    };

    navigate(HIPS_ROOM(params));
  },
  startGuides(
    _ctx: OrchestratorMachineContext,
    event: OrchestratorEvent<HIPsRouteParams>
  ) {
    const ctx = getActivePropertyContext(_ctx);
    const propertyId = ctx.property.id;
    const { hipsId } = event.data;

    const hip = findBy(ctx.hips, "id", hipsId);

    const firstRoom = hip.planningAreas.rooms[0];
    const roomId = firstRoom?.id;

    if (!roomId) {
      throw new Error(`Cannot start guide with HIP ID [${hipsId}]`);
    }

    const guide = hip.scopeOfWork.guides.find((item) => {
      if (item.roomId !== roomId) {
        return false;
      }

      const isAlreadyDone = ctx.activeGuides.some(
        (ag) => ag._done && ag._SOWGuides.some((g) => g.id === item.id)
      );

      if (isAlreadyDone) {
        return false;
      }

      return true;
    });

    if (!guide) {
      throw new Error(
        `Cannot start guide with HIP ID [${hipsId}] and room ID [${roomId}]`
      );
    }

    navigate(
      GUIDE({
        propertyId,
        hipsId,
        roomId,
        id: guide.id,
      })
    );
  },
  cleanUpGuides: assign(
    (
      _ctx: OrchestratorMachineContext,
      event: OrchestratorEvent<HIPsRouteParams>
    ) => {
      const ctx = getActivePropertyContext(_ctx);
      const { hipsId } = event.data;
      const hip = findBy(ctx.hips, "id", hipsId);
      const roomsIds = hip.planningAreas.rooms.map((room) => room.id);

      hip.scopeOfWork.guides = hip.scopeOfWork.guides.filter((guide) =>
        roomsIds.includes(guide.roomId)
      );

      ctx.activeGuides = ctx.activeGuides.filter(
        (guide) =>
          !(guide._hipId === hipsId && !roomsIds.includes(guide._roomId))
      );
    }
  ),
  addHIPsOutputNote: assign(
    (
      _ctx: OrchestratorMachineContext,
      event: OrchestratorEvent<HIPsOutputNotePayload>
    ) => {
      const { category, message, type, hipsId } = event.data;
      const ctx = getActivePropertyContext(_ctx);
      const hip = findBy(ctx.hips, "id", hipsId);
      if (!hip.output.notes[category]) {
        hip.output.notes[category] = {};
      }
      hip.output.notes[category][type] = message;
    }
  ),
});
