import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { FurnaceTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.FURNACE,
  FurnaceTypes
>["floorPlan"];

export const FurnaceConfigFloorPlan: Config = {
  sizes: {
    [FurnaceTypes.DEFAULT]: { width: 40, height: 40 },
  },
};
