import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";

import { BoilerConfig } from "./Boiler/config";
import { FurnaceConfig } from "./Furnace/config";
import { MiniSplitSystemConfig } from "./MiniSplitSystem/config";
import { PackagedSystemConfig } from "./PackagedSystem/config";
import { SplitSystemConfig } from "./SplitSystem/config";
import { SplitSystemExteriorUnitConfig } from "./SplitSystemExteriorUnit/config";
import { SplitSystemInteriorUnitConfig } from "./SplitSystemInteriorUnit/config";

export const Mechanical = {
  [GuideItemTypeCategory.SPLIT_SYSTEM]: SplitSystemConfig,
  [GuideItemTypeCategory.SPLIT_SYSTEM_INTERIOR_UNIT]:
    SplitSystemInteriorUnitConfig,
  [GuideItemTypeCategory.SPLIT_SYSTEM_EXTERIOR_UNIT]:
    SplitSystemExteriorUnitConfig,
  [GuideItemTypeCategory.PACKAGED_SYSTEM]: PackagedSystemConfig,
  [GuideItemTypeCategory.MINISPLIT_SYSTEM]: MiniSplitSystemConfig,
  [GuideItemTypeCategory.FURNACE]: FurnaceConfig,
  [GuideItemTypeCategory.BOILER]: BoilerConfig,
};
