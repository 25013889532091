import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { DoorTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.DOOR,
  DoorTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [DoorTypes.INTERIOR]: t("Interior Door"),
  [DoorTypes.EXTERIOR]: t("Exterior Door"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [DoorTypes.INTERIOR]: t("Interior Door"),
  [DoorTypes.EXTERIOR]: t("Exterior Door"),
});

export const DoorsConfigTexts: Config = {
  categoryName: (t) => t("Doors"),
  itemName: (t) => t("Door"),
  categoryDescription: (t) =>
    t(
      "Doors come in diverse styles, sizes, and materials, catering to different functional and aesthetic requirements. Their role extends beyond appearance, as they provide privacy, improve home security, and contribute to energy efficiency. Additionally, doors help in noise reduction and managing the layout of a house, making them essential for both functionality and the overall design of living spaces."
    ),
  itemNames,
  typeNames,
};
