export enum ShapeAdjustmentTypes {
  PULL_WALL = "pull_wall",
  PUSH_WALL = "push_wall",
  FLATTEN_CORNER = "flatten_corner",
  UN_FLATTEN_CORNER = "un_flatten_corner",
  ROUND_CORNER = "round_corner",
  UN_ROUND_CORNER = "un_round_corner",
}

export enum WallAdjustmentTypes {
  PLACE_SHORT_WALL = "place_short_wall",
  REMOVE_WALL_OR_SECTION = "remove_wall_or_section",
}

export type AdjustmentTypes = ShapeAdjustmentTypes | WallAdjustmentTypes;
