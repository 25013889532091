import { ImageItem } from "shared/types/ImageItem";

import FourFallback from "./assets/four.png";
import Four from "./assets/four.webp";
import OneFallback from "./assets/one.png";
import One from "./assets/one.webp";
import ThreeFallback from "./assets/three.png";
import Three from "./assets/three.webp";
import TwoFallback from "./assets/two.png";
import Two from "./assets/two.webp";

const SplitSystemCountImages: Record<string, ImageItem> = {
  "1": {
    src: One,
    fallbackSrc: OneFallback,
  },
  "2": {
    src: Two,
    fallbackSrc: TwoFallback,
  },
  "3": {
    src: Three,
    fallbackSrc: ThreeFallback,
  },
  "4": {
    src: Four,
    fallbackSrc: FourFallback,
  },
};

export const getSplitSystemCountImage = (count: number) => {
  return SplitSystemCountImages[String(count)];
};
