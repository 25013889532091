import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { SplitSystemInteriorUnitTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.SPLIT_SYSTEM_INTERIOR_UNIT,
  SplitSystemInteriorUnitTypes
>["floorPlan"];

export const SplitSystemInteriorUnitConfigFloorPlan: Config = {
  sizes: {
    [SplitSystemInteriorUnitTypes.DEFAULT]: { width: 40, height: 40 },
  },
};
