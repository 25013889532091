import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import {
  AllAllowed,
  GuideItemTypeConfig,
} from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";

import { ElectricalPanelConfigFloorPlan } from "./config.floorPlan";
import { ElectricalPanelConfigImages } from "./config.images";
import { ElectricalPanelConfigTexts } from "./config.texts";
import { ElectricalPanelTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.ELECTRICAL_PANEL,
  ElectricalPanelTypes
>;

const types: Config["types"] = [
  ElectricalPanelTypes.MAIN_PANEL,
  ElectricalPanelTypes.SUB_PANEL,
];

const allAllowed: AllAllowed = undefined;

export const ElectricalPanelConfig: Config = {
  guideType: GuideType.ELECTRICAL,
  category: GuideItemTypeCategory.ELECTRICAL_PANEL,
  types,
  typesOrder: getOrderedMap(types),
  texts: ElectricalPanelConfigTexts,
  images: ElectricalPanelConfigImages,
  floorPlan: ElectricalPanelConfigFloorPlan,
  allowedIntents: allAllowed,
  allowedInRooms: allAllowed,
} as const;
