import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import twoFortyVBoxFallback from "./assets/240vBox.png";
import twoFortyVBox from "./assets/240vBox.webp";
import doubleBoxFallback from "./assets/doubleBox.png";
import doubleBox from "./assets/doubleBox.webp";
import { ReactComponent as IconTwoFortyBox } from "./assets/icon_240v_box.svg";
import { ReactComponent as CategoryIcon } from "./assets/icon_category_outlets.svg";
import { ReactComponent as Icon240VDisabled } from "./assets/icon_disabled_240v.svg";
import { ReactComponent as IconDoubleFilledDisabled } from "./assets/icon_disabled_both_filled.svg";
import { ReactComponent as IconLeftFilledDisabled } from "./assets/icon_disabled_left.svg";
import { ReactComponent as IconRightFilledDisabled } from "./assets/icon_disabled_right.svg";
import { ReactComponent as IconWeatherResistantDisabled } from "./assets/icon_disabled_weather_resistant.svg";
import { ReactComponent as IconSingleFilledDisabled } from "./assets/icon_disbled_single_filled.svg";
import { ReactComponent as IconDoubleBox } from "./assets/icon_double_box.svg";
import { ReactComponent as IconDoubleFilled } from "./assets/icon_double_filled.svg";
import { ReactComponent as IconDoubleLeftFilled } from "./assets/icon_double_left_filled.svg";
import { ReactComponent as IconDoubleRightFilled } from "./assets/icon_double_right_filled.svg";
import { ReactComponent as IconSingleBox } from "./assets/icon_single_box.svg";
import { ReactComponent as IconSingleFilled } from "./assets/icon_single_filled.svg";
import { ReactComponent as IconWeatherResistantBox } from "./assets/icon_weather_resistant_box.svg";
import mainImageFallback from "./assets/outlet_main.png";
import mainImage from "./assets/outlet_main.webp";
import singleBoxFallback from "./assets/singleBox.png";
import singleBox from "./assets/singleBox.webp";
import weatherResistantBoxFallback from "./assets/weatherResistantBox.png";
import weatherResistantBox from "./assets/weatherResistantBox.webp";
import { OutletIconStateTypes, OutletTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.OUTLET,
  OutletTypes
>["images"];

const icons: Config["icons"] = {
  [OutletTypes.SINGLE]: IconSingleBox,
  [OutletTypes.DOUBLE]: IconDoubleBox,
  [OutletTypes.TWO_FORTY_V]: IconTwoFortyBox,
  [OutletTypes.WEATHER_RESISTANT]: IconWeatherResistantBox,
};

const modifiedIcons: Config["modifiedIcons"] = {
  [OutletIconStateTypes.SINGLE_FILLED]: IconSingleFilled,
  [OutletIconStateTypes.DOUBLE_FIRST_FILLED]: IconDoubleLeftFilled,
  [OutletIconStateTypes.DOUBLE_SECOND_FILLED]: IconDoubleRightFilled,
  [OutletIconStateTypes.DOUBLE_BOTH_FILLED]: IconDoubleFilled,
  [OutletIconStateTypes.SINGLE_FILLED_DISABLED]: IconSingleFilledDisabled,
  [OutletIconStateTypes.DOUBLE_BOTH_FILLED_DISABLED]: IconDoubleFilledDisabled,
  [OutletIconStateTypes.DOUBLE_FIRST_FILLED_DISABLED]: IconLeftFilledDisabled,
  [OutletIconStateTypes.DOUBLE_SECOND_FILLED_DISABLED]: IconRightFilledDisabled,
  [OutletIconStateTypes.WEATHER_RESISTANT_DISABLED]:
    IconWeatherResistantDisabled,
  [OutletIconStateTypes.TWO_FORTY_V_DISABLED]: Icon240VDisabled,
};

const images: Config["images"] = {
  [OutletTypes.SINGLE]: {
    src: singleBox,
    fallbackSrc: singleBoxFallback,
  },
  [OutletTypes.DOUBLE]: {
    src: doubleBox,
    fallbackSrc: doubleBoxFallback,
  },
  [OutletTypes.WEATHER_RESISTANT]: {
    src: weatherResistantBox,
    fallbackSrc: weatherResistantBoxFallback,
  },
  [OutletTypes.TWO_FORTY_V]: {
    src: twoFortyVBox,
    fallbackSrc: twoFortyVBoxFallback,
  },
};

export const OutletsConfigImages: Config = {
  categoryIcon: CategoryIcon,
  images,
  icons,
  modifiedIcons,
  defaultIcon: IconSingleBox,
  defaultImage: {
    src: mainImage,
    fallbackSrc: mainImageFallback,
  },
};
