import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";

import { OutletsConfigFloorPlan } from "./config.floorPlan";
import { OutletsConfigImages } from "./config.images";
import { OutletsConfigTexts } from "./config.texts";
import { OutletTypes } from "./enums";

type Config = GuideItemTypeConfig<GuideItemTypeCategory.OUTLET, OutletTypes>;

const types: Config["types"] = [
  OutletTypes.SINGLE,
  OutletTypes.DOUBLE,
  OutletTypes.TWO_FORTY_V,
  OutletTypes.WEATHER_RESISTANT,
];

export const OutletsConfig: Config = {
  guideType: GuideType.ELECTRICAL,
  category: GuideItemTypeCategory.OUTLET,
  types,
  typesOrder: getOrderedMap(types),
  texts: OutletsConfigTexts,
  images: OutletsConfigImages,
  floorPlan: OutletsConfigFloorPlan,
  allowedInRooms: undefined,
  allowedIntents: undefined,
} as const;
