import { getGuideItemCategoriesConfigList } from "constants/categories/category.utils";
import { GuideType } from "constants/guides/GuideType";
import { intersection } from "lodash-es";
import { getGuideTypes } from "pages/Guides/consts";
import { TFunction } from "react-i18next";

import { HIPsSOWTasksDefaultSelectValue } from "./consts";

export const orderCategories = (categories: GuideType[]) => {
  return intersection(getGuideTypes(), categories);
};

export const searchTasksByCategory = (
  categories: GuideType[],
  category: string
) => {
  return category !== HIPsSOWTasksDefaultSelectValue
    ? categories.filter((item) => item === category)
    : categories;
};

export const searchTasksBySubCategory = (t: TFunction, search: string) => {
  const query = search.toLowerCase();
  const items = getGuideItemCategoriesConfigList().filter((item) =>
    item.texts.categoryName(t).toLowerCase().includes(query)
  );
  return [...new Set(items.map((item) => item.guideType))];
};
