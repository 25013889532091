import { ROOMS_PIECES } from "constants/rooms/roomPieces";
import { GuideIntentType } from "pages/Guides/enums";

export const WALL_AND_CEILING_MATERIAL_ROOMS_ALLOWED = [
  ROOMS_PIECES.BEDROOM,
  ROOMS_PIECES.BATHROOM,
  ROOMS_PIECES.HALF_BATH,
  ROOMS_PIECES.LIVING_ROOM,
  ROOMS_PIECES.DINING_ROOM,
  ROOMS_PIECES.KITCHEN,
  ROOMS_PIECES.FAMILY_ROOM,
  ROOMS_PIECES.DEN,
  ROOMS_PIECES.NURSERY,
  ROOMS_PIECES.LOFT,
  ROOMS_PIECES.OFFICE,
  ROOMS_PIECES.LIBRARY,
  ROOMS_PIECES.PLAY_ROOM,
  ROOMS_PIECES.GAME_ROOM,
  ROOMS_PIECES.HOME_THEATER,
  ROOMS_PIECES.GYM,
  ROOMS_PIECES.SUN_ROOM,
  ROOMS_PIECES.MUSIC_ROOM,
  ROOMS_PIECES.WALK_IN_CLOSET,
  ROOMS_PIECES.LAUNDRY,
  ROOMS_PIECES.HVAC_CLOSET,
  ROOMS_PIECES.WALKIN_PANTRY,
  ROOMS_PIECES.WINE_CELLAR,
  ROOMS_PIECES.ATTIC,
  ROOMS_PIECES.BASEMENT,
  ROOMS_PIECES.STORAGE_ROOM,
  ROOMS_PIECES.UTILITY_ROOM,
  ROOMS_PIECES.FOYER,
  ROOMS_PIECES.HALLWAY,
  ROOMS_PIECES.STAIRCASE,
  ROOMS_PIECES.GARAGE,
  ROOMS_PIECES.WALKWAY,
];

export const WALL_AND_CEILING_MATERIAL_INTENTS_ALLOWED = [
  GuideIntentType.ADD,
  GuideIntentType.REPLACE,
  GuideIntentType.REMOVE,
];
