import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { RULE_DEFAULT_CANNOT_OVERLAP } from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/PixiFloorPlanItem/PixiFloorPlanItem.rules";

import { SmokeAlarmTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.SMOKE_ALARM,
  SmokeAlarmTypes
>["floorPlan"];

const sizes: Config["sizes"] = {
  [SmokeAlarmTypes.BATTERY_OPERATED]: { width: 29, height: 29 },
  [SmokeAlarmTypes.HARDWIRED]: { width: 30, height: 30 },
};

const rules: Config["rules"] = {
  [SmokeAlarmTypes.BATTERY_OPERATED]: RULE_DEFAULT_CANNOT_OVERLAP,
  [SmokeAlarmTypes.HARDWIRED]: RULE_DEFAULT_CANNOT_OVERLAP,
};

export const SmokeAlarmConfigFloorPlan: Config = {
  sizes,
  rules,
};
