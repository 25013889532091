import { Property } from "api/models/Property";
import { SharedCollectionType } from "api/models/SharedCollectionType";
import type { RoomRouteParams } from "common/hooks/useRoomParams";
import { lazy } from "common/utils/lazy";
import {
  BLOG_INSPIRATION_ENABLED,
  HELP_CENTER_ENABLED,
  INSPIRATION_ENABLED,
  ROOMS_AND_SPACES_ENABLED,
  SUBSCRIPTIONS_ENABLED,
} from "constants/env";
import type { GuideParams } from "pages/Guides/hooks/useGuidesParams";
import type { LoginParams } from "pages/Login/useLoginParams";
import type { Address } from "pages/PropertyAddition/types";
import { stringify } from "qs";
import { enumToSlug } from "shared/util/enum";

import {
  CategoryRouteParams,
  CategoryRouteRoomParams,
  GetEducatedGuideParams,
  GetEducatedGuidePostParams,
  GetEducatedQueryParams,
  HIPsRoomRouteParams,
  HIPsRouteParams,
  MediaInspirationCategoryParams,
  MediaInspirationCategoryResultsParams,
  PresetRouteParams,
  PropertyClaimRejectedRouteParams,
  RoomImagesQueryParams,
} from "./models";

export const BASE_ROUTE = () => "/";

const PROPERTY = (params: { propertyId: Property["id"] }) =>
  `${BASE_ROUTE()}property/${params.propertyId}`;

export const HOME = () => `${BASE_ROUTE()}home`;
export const PRO_MARKETING = () => `${BASE_ROUTE()}for-professionals`;
export const NOT_AVAILABLE_MOBILE = () => `${BASE_ROUTE()}no-mobile`;
export const SIGN_UP = () => `${BASE_ROUTE()}sign-up`;
export const BUSINESS_SIGN_UP = () => `${BASE_ROUTE()}sign-up-pro`;
export const BUSINESS_DETAILS_SIGN_UP = () =>
  `${BASE_ROUTE()}sign-up-pro/details`;
export const ACCOUNT_SETTINGS = () => `${BASE_ROUTE()}account-settings`;
const PROPERTY_TRANSFER = () => `${BASE_ROUTE()}property-transfer`;
export const HELP_CENTER = () => `${BASE_ROUTE()}help`;
export const RESET_PASSWORD = () => `${BASE_ROUTE()}reset-password`;
export const FORGOT_PASSWORD = () => `${BASE_ROUTE()}forgot-password`;
export const VERIFY_EMAIL = () => `${BASE_ROUTE()}verify-email`;
export const VERIFY_EMAIL_ERROR = () => `${VERIFY_EMAIL()}/error`;
export const PROPERTY_ADDITION = () => `${BASE_ROUTE()}property-addition`;
export const PROPERTY_ADDITION_CONFIRMATION = () =>
  `${PROPERTY_ADDITION()}/confirmation`;
export const PROPERTY_ADDITION_ROOMS_AND_SPACES = () =>
  `${PROPERTY_ADDITION()}/rooms-and-spaces`;
export const PROPERTY_ADDITION_ROOM_IMAGES = (
  queryParams?: RoomImagesQueryParams
) => {
  let url = `${PROPERTY_ADDITION()}/room-images`;
  if (queryParams) {
    url += `?${stringify(queryParams)}`;
  }
  return url;
};

export const GET_INSPIRED = () => `${BASE_ROUTE()}get-inspired`;
export const GET_INSPIRED_CATEGORY = (params: MediaInspirationCategoryParams) =>
  `${GET_INSPIRED()}/${enumToSlug(params.categoryId)}`;
export const GET_INSPIRED_RESULTS = (
  params: MediaInspirationCategoryResultsParams
) => `${GET_INSPIRED_CATEGORY(params)}/${enumToSlug(params.itemId)}`;

export const GET_EDUCATED = (queryParams?: GetEducatedQueryParams) => {
  if (queryParams) {
    return `${BASE_ROUTE()}get-educated?${stringify(queryParams)}`;
  }
  return `${BASE_ROUTE()}get-educated`;
};

export const GET_EDUCATED_POST = ({ postId }: { postId: string }) => {
  return `${GET_EDUCATED()}/article/${postId}`;
};

export const GET_EDUCATED_GUIDE = ({
  guideId,
  chapterId,
  sectionId,
}: GetEducatedGuideParams) => {
  if (chapterId && sectionId) {
    return `${GET_EDUCATED()}/guide/${guideId}/${chapterId}/${sectionId}`;
  }
  return `${GET_EDUCATED()}/guide/${guideId}`;
};

export const GET_EDUCATED_GUIDE_POST = ({
  postId,
  ...rest
}: GetEducatedGuidePostParams) => `${GET_EDUCATED_GUIDE(rest)}/${postId}`;
export const GET_EDUCATED_MY_STUFF = () => `${GET_EDUCATED()}/my-library`;
export const TERMS = () => `${BASE_ROUTE()}terms`;
export const PRIVACY = () => `${BASE_ROUTE()}privacy`;
export const NOT_FOUND = () => `${BASE_ROUTE()}404`;
export const ERROR = () => `${BASE_ROUTE()}500`;
const REDIRECT_SHARED_GALLERY = () => `${BASE_ROUTE()}view-shared-gallery`;
const REDIRECT_SHARED_ALBUM = () => `${BASE_ROUTE()}view-shared-album`;
const REDIRECT_SHARED_ALBUM_INVITE = () =>
  `${BASE_ROUTE()}view-shared-album-invite`;
const REDIRECT_SHARED_UNSUBSCRIBE = () =>
  `${BASE_ROUTE()}view-shared-album-unsubscribe`;
const REDIRECT_SUPPORT = () => `${BASE_ROUTE()}/support`;
const REDIRECT_PROPERTY_DASHBOARD = () => `${BASE_ROUTE()}/property/dashboard`;
export const MEDIA = (
  params: { propertyId: Property["id"] },
  search?: Record<string, string>
) => {
  const media = `${PROPERTY(params)}/media`;
  if (search) {
    return `${media}?${stringify(search)}`;
  }
  return media;
};
const SHARED_PLAN = ({ key }: { key: string }) =>
  `${BASE_ROUTE()}shared-plan/${key}`;
export const SHARED_PLAN_OUTPUT = ({ key }: { key: string }) =>
  `${BASE_ROUTE()}shared-plan-output/${key}`;
export const SHARED_PLAN_ROOM_OUTPUT = ({ key }: { key: string }) =>
  `${BASE_ROUTE()}shared-plan-room-output/${key}`;

export const MEDIA_SHARED_ALBUMS = (params: {
  propertyId: Property["id"];
  albumId?: string;
  shared: SharedCollectionType;
}) =>
  `${MEDIA(params)}/shared/${params.shared}${
    params.albumId ? `/${params.albumId}` : ""
  }`;

export const MEDIA_ALBUMS = (params: {
  propertyId: Property["id"];
  albumId?: string;
}) => {
  return `${PROPERTY(params)}/media/albums${
    params.albumId ? `/${params.albumId}` : ""
  }`;
};

export const DASHBOARD = (params: { propertyId: Property["id"] }) =>
  `${PROPERTY(params)}/dashboard`;

export const BUSINESS_DASHBOARD = () => `${BASE_ROUTE()}dashboard-business`;

export const DASHBOARD_NO_PROPERTY = () => `${BASE_ROUTE()}no-property`;

const HIPS = (params: HIPsRouteParams) =>
  `${PROPERTY(params)}/hips/${params.hipsId}`;

export const HIPS_ONBOARDING = (params: HIPsRouteParams) =>
  `${HIPS(params)}/onboarding`;

export const HIPS_DASHBOARD = (params: HIPsRouteParams) =>
  `${HIPS(params)}/dashboard`;

export const HIPS_PLANNING_AREA = (params: HIPsRouteParams) =>
  `${HIPS(params)}/planning-area`;

export const HIPS_PROJECT_SCOPE = (params: HIPsRouteParams) =>
  `${HIPS(params)}/create-project-scope`;

export const HIPS_PROJECT_SCOPE_ROOM = (params: HIPsRoomRouteParams) =>
  `${HIPS(params)}/${params.roomId}/create-project-scope`;

export const HIPS_PROJECT_SCOPE_OVERVIEW = (params: HIPsRouteParams) =>
  `${HIPS(params)}/create-project-scope/overview`;

export const HIPS_PROJECT_SCOPE_CHOOSE_PRESET = (params: HIPsRouteParams) =>
  `${HIPS_PROJECT_SCOPE(params)}/preset`;

export const HIPS_PROJECT_SCOPE_PRESET_GROUP_PAGE = (
  params: PresetRouteParams
) => `${HIPS_PROJECT_SCOPE(params)}/preset/${params.presetId}`;

export const HIPS_PROJECT_SCOPE_CHOOSE_TASKS = (params: HIPsRouteParams) =>
  `${HIPS_PROJECT_SCOPE(params)}/tasks`;

export const HIPS_PROJECT_SCOPE_ROOM_CHOOSE_TASKS = (
  params: HIPsRoomRouteParams
) => `${HIPS_PROJECT_SCOPE_ROOM(params)}/tasks`;

export const HIPS_PROJECT_SCOPE_CHOOSE_TASKS_CATEGORY = (
  params: CategoryRouteParams
) => `${HIPS_PROJECT_SCOPE(params)}/tasks/${params.category}`;

export const HIPS_PROJECT_SCOPE_ROOM_CHOOSE_TASKS_CATEGORY = (
  params: CategoryRouteRoomParams
) => `${HIPS_PROJECT_SCOPE_ROOM(params)}/tasks/${params.category}`;

export const HIPS_ROOM = (params: HIPsRouteParams & RoomRouteParams) =>
  `${HIPS(params)}/room/${params.roomId}`;

export const HIPS_PLAN_DETAILS = (params: HIPsRouteParams) =>
  `${HIPS(params)}/plan-details`;

export const HIPS_PLAN_DETAILS_OUTPUT = (params: HIPsRouteParams) =>
  `${HIPS(params)}/plan-details-output`;

export const HIPS_PROJECT_BUDGET = (params: HIPsRouteParams) =>
  `${HIPS(params)}/project-budget`;

export const LOGIN = (params?: LoginParams) => {
  const { passwordReset, passwordResetError, next } = params || {};
  const base = `${BASE_ROUTE()}login`;

  if (passwordReset) {
    return `${base}?${stringify({ passwordReset })}`;
  }

  if (passwordResetError) {
    return `${base}?${stringify({ passwordResetError })}`;
  }

  if (next) {
    return `${base}?${stringify({ next })}`;
  }

  return base;
};

export const PROPERTY_ADDITION_ADDRESS = (params?: Address) => {
  const { address, flat } = params || {};
  const base = `${PROPERTY_ADDITION()}/address`;

  if (address || flat) {
    return `${base}?${stringify({
      address,
      flat,
    })}`;
  }

  return base;
};

export const PROPERTY_ADDITION_ADDRESS_DETAILS = (params?: Address) => {
  const { address, flat } = params || {};
  const base = `${PROPERTY_ADDITION_ADDRESS()}/details`;

  if (address || flat) {
    return `${base}?${stringify({
      address,
      flat,
    })}`;
  }

  return base;
};

export const PROPERTY_ADDITION_REJECTED = (
  params: PropertyClaimRejectedRouteParams
) => {
  const base = `${PROPERTY_ADDITION()}/rejected`;
  return params ? `${base}?${stringify(params)}` : base;
};

const ROOM = (params: RoomRouteParams) =>
  `${PROPERTY(params)}/rooms/${params.roomId}`;

export const GUIDE = (params: GuideParams) =>
  `${ROOM(params)}/guide/${params.hipsId}/${params.id}`;

export interface Route {
  path: string;
  component: ReturnType<typeof lazy>;
  skipPreload?: boolean;
  live: boolean;
  mobile: boolean;
}

// NOTE(clemens): this listing shows the pages of the app roughly in the order in which
//  a user would progress through the app so that we can preload bundles and have them
//  most likely be loaded already by the time that a user gets to the page
export const ROUTES: Array<Array<Route>> = [
  [
    {
      path: BASE_ROUTE(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "base_route" */
            "pages/RootPage/RootPage"
          )
      ),
      skipPreload: true,
      live: true,
      mobile: true,
    },
    {
      path: HOME(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "home" */
            "pages/Home/Home"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: PRO_MARKETING(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "home" */
            "pages/ProMarketing/ProMarketingPage"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: REDIRECT_SUPPORT(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "redirect_support" */
            "pages/RedirectPages/SupportPage"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: REDIRECT_SHARED_GALLERY(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "redirect_shared_gallery" */
            "pages/RedirectPages/ViewSharedGalleryPage"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: REDIRECT_SHARED_ALBUM(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "redirect_shared_album" */
            "pages/RedirectPages/ViewSharedAlbumPage"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: REDIRECT_SHARED_ALBUM_INVITE(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "redirect_shared_album_invite" */
            "pages/RedirectPages/ViewSharedAlbumInvitePage"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: REDIRECT_SHARED_UNSUBSCRIBE(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "redirect_shared_album_unsubscribe" */
            "pages/RedirectPages/ViewSharedAlbumUnsubscribePage"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: REDIRECT_PROPERTY_DASHBOARD(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "redirect_property_dashboard" */
            "pages/RedirectPages/PropertyDashboardPage"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: NOT_AVAILABLE_MOBILE(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "no_mobile" */
            "pages/NotAvailableMobile/NotAvailableMobile"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: LOGIN(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "login" */
            "pages/Login/Login"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: PROPERTY_ADDITION(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "property_addition" */
            "pages/PropertyAddition/PropertyAdditionInformation/PropertyAdditionInformation"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: PROPERTY_ADDITION_ADDRESS(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "property_addition_address" */
            "pages/PropertyAddition/PropertyAddress/PropertyAddress"
          )
      ),
      live: true,
      mobile: true,
    },
  ],
  [
    {
      path: PROPERTY_ADDITION_ADDRESS_DETAILS(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "property_addition_address_details" */
            "pages/PropertyAddition/PropertyAddressDetails/PropertyAddressDetails"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: PROPERTY_ADDITION_CONFIRMATION(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "property_addition_confirmation" */
            "pages/PropertyAddition/Confirmation/Confirmation"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: SIGN_UP(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "sign_up" */
            "pages/SignUp/SignUp"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: BUSINESS_SIGN_UP(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "sign_up_pro" */
            "pages/SignUp/business/pages/CreateAccount/BusinessSignUp"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: BUSINESS_DETAILS_SIGN_UP(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "sign_up_pro_details" */
            "pages/SignUp/business/pages/BusinessDetails/BusinessDetailsSignUp"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: VERIFY_EMAIL(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "email_verify" */
            "pages/SignUp/Verify/Verify"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: PROPERTY_ADDITION_ROOMS_AND_SPACES(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "property_addition_rooms_and_spaces" */
            "pages/RoomsAndSpaces/RoomsAndSpaces"
          )
      ),
      live: ROOMS_AND_SPACES_ENABLED,
      mobile: false,
    },
    {
      path: PROPERTY_ADDITION_ROOM_IMAGES(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "property_addition_room_images" */
            "pages/RoomImages/RoomImages"
          )
      ),
      live: true,
      mobile: false,
    },
    {
      path: DASHBOARD({ propertyId: ":propertyId" as unknown as number }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "dashboard" */
            "pages/Dashboard/DashboardPage"
          )
      ),
      live: true,
      mobile: true,
    },
  ],
  [
    {
      path: GET_EDUCATED(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "get_educated" */
            "pages/GetEducated/pages/MainPage/GetEducatedPage"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: GET_EDUCATED_POST({ postId: ":postId" }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "get_educated_post" */
            "pages/GetEducated/pages/BlogPost/BlogPost"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: GET_EDUCATED_GUIDE({
        guideId: ":guideId",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "get_educated_project_guide" */
            "pages/GetEducated/pages/BlogGuide/BlogGuide"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: GET_EDUCATED_GUIDE({
        guideId: ":guideId",
        chapterId: ":chapterId",
        sectionId: ":sectionId",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "get_educated_project_guide" */
            "pages/GetEducated/pages/BlogGuide/BlogGuide"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: GET_EDUCATED_GUIDE_POST({
        guideId: ":guideId",
        chapterId: ":chapterId",
        sectionId: ":sectionId",
        postId: ":postId",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "get_educated_post" */
            "pages/GetEducated/pages/BlogPost/BlogPost"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: GET_EDUCATED_MY_STUFF(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "project_guides_my_stuff" */
            "pages/GetEducated/pages/MyStuff/MyStuff"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: GET_INSPIRED(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "get_inspired" */
            "pages/Media/modules/inspiration/pages/dashboard/MediaInspirationPage"
          )
      ),
      live: INSPIRATION_ENABLED,
      mobile: false,
    },
    {
      path: GET_INSPIRED_CATEGORY({
        categoryId: ":categoryId",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "get_inspired_category" */
            "pages/Media/modules/inspiration/pages/category/MediaInspirationCategoryPage"
          )
      ),
      live: INSPIRATION_ENABLED,
      mobile: false,
    },
    {
      path: GET_INSPIRED_RESULTS({
        categoryId: ":categoryId",
        itemId: ":itemId",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "get_inspired_results" */
            "pages/Media/modules/inspiration/pages/results/MediaInspirationResultsPage"
          )
      ),
      live: INSPIRATION_ENABLED || BLOG_INSPIRATION_ENABLED,
      mobile: true,
    },
    {
      path: DASHBOARD_NO_PROPERTY(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "dashboard_no_property" */
            "pages/Dashboard/DashboardPageNoProperty"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: BUSINESS_DASHBOARD(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "dashboard_business" */
            "pages/Business/pages/DashboardBusinessPage"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: PROPERTY_TRANSFER(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "property_transfer" */
            "pages/PropertyTransfer/PropertyTransfer"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: ACCOUNT_SETTINGS(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "account_settings" */
            "pages/AccountSettings/AccountSettingsPage"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: MEDIA({ propertyId: ":propertyId" as unknown as number }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "media" */
            "pages/Media/Media"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: MEDIA_SHARED_ALBUMS({
        propertyId: ":propertyId" as unknown as number,
        shared: ":shared" as unknown as SharedCollectionType,
        albumId: ":albumId?",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "media_albums_shared" */
            "pages/Media/modules/albums/MediaAlbumsPage"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: MEDIA_ALBUMS({
        propertyId: ":propertyId" as unknown as number,
        albumId: ":albumId?",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "media_albums" */
            "pages/Media/modules/albums/MediaAlbumsPage"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: HIPS_ONBOARDING({
        propertyId: ":propertyId" as unknown as number,
        hipsId: ":hipsId",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "hips_onboarding" */
            "pages/HIPs/Onboarding/HIPsOnboardingPage"
          )
      ),
      live: true,
      mobile: false,
    },
  ],
  [
    {
      path: HIPS_DASHBOARD({
        propertyId: ":propertyId" as unknown as number,
        hipsId: ":hipsId",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "hips_dashboard" */
            "pages/HIPs/Dashboard/HIPsDashboardPage"
          )
      ),
      live: true,
      mobile: false,
    },
    {
      path: HIPS_PLANNING_AREA({
        propertyId: ":propertyId" as unknown as number,
        hipsId: ":hipsId",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "hips_planning_area" */
            "pages/HIPs/PlanningArea/HIPsPlanningAreaPage"
          )
      ),
      live: true,
      mobile: false,
    },
    {
      path: HIPS_PROJECT_SCOPE_OVERVIEW({
        propertyId: ":propertyId" as unknown as number,
        hipsId: ":hipsId",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "hips_project_scope_overview" */
            "pages/HIPs/ProjectScope/modules/Overview/HIPsOverviewPage"
          )
      ),
      live: true,
      mobile: false,
    },
    {
      path: HIPS_PROJECT_SCOPE({
        propertyId: ":propertyId" as unknown as number,
        hipsId: ":hipsId",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "hips_project_scope" */
            "pages/HIPs/ProjectScope/HIPsProjectScopePage"
          )
      ),
      live: true,
      mobile: false,
    },
    {
      path: HIPS_PROJECT_SCOPE_ROOM({
        propertyId: ":propertyId" as unknown as number,
        hipsId: ":hipsId",
        roomId: ":roomId",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "hips_project_scope" */
            "pages/HIPs/ProjectScope/HIPsProjectScopePage"
          )
      ),
      live: true,
      mobile: false,
    },
    {
      path: HIPS_PROJECT_SCOPE_CHOOSE_PRESET({
        propertyId: ":propertyId" as unknown as number,
        hipsId: ":hipsId",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "hips_project_scope_choose_preset" */
            "pages/HIPs/ProjectScope/modules/Presets/HIPsSOWPresetPage"
          )
      ),
      live: true,
      mobile: false,
    },
    {
      path: HIPS_PROJECT_SCOPE_CHOOSE_TASKS({
        propertyId: ":propertyId" as unknown as number,
        hipsId: ":hipsId",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "hips_project_scope_choose_individually" */
            "pages/HIPs/ProjectScope/modules/Tasks/HIPsSOWTasksPage"
          )
      ),
      live: true,
      mobile: false,
    },
    {
      path: HIPS_PROJECT_SCOPE_ROOM_CHOOSE_TASKS({
        propertyId: ":propertyId" as unknown as number,
        hipsId: ":hipsId",
        roomId: ":roomId",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "hips_project_scope_choose_individually" */
            "pages/HIPs/ProjectScope/modules/Tasks/HIPsSOWTasksPage"
          )
      ),
      live: true,
      mobile: false,
    },
    {
      path: HIPS_PROJECT_SCOPE_CHOOSE_TASKS_CATEGORY({
        propertyId: ":propertyId" as unknown as number,
        hipsId: ":hipsId",
        category: ":category",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "hips_project_scope_choose_individually_category" */
            "pages/HIPs/ProjectScope/modules/TasksCategory/HIPsSOWTasksCategoryPage"
          )
      ),
      live: true,
      mobile: false,
    },
    {
      path: HIPS_PROJECT_SCOPE_ROOM_CHOOSE_TASKS_CATEGORY({
        propertyId: ":propertyId" as unknown as number,
        hipsId: ":hipsId",
        category: ":category",
        roomId: ":roomId",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "hips_project_scope_choose_individually_category" */
            "pages/HIPs/ProjectScope/modules/TasksCategory/HIPsSOWTasksCategoryPage"
          )
      ),
      live: true,
      mobile: false,
    },
    {
      path: HIPS_PROJECT_SCOPE_PRESET_GROUP_PAGE({
        propertyId: ":propertyId" as unknown as number,
        hipsId: ":hipsId",
        presetId: ":presetId",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "hips_project_scope_choose_preset" */
            "pages/HIPs/ProjectScope/modules/PresetGroupPage/HIPsSOWPresetGroupPage"
          )
      ),
      live: true,
      mobile: false,
    },
    {
      path: HIPS_PROJECT_BUDGET({
        propertyId: ":propertyId" as unknown as number,
        hipsId: ":hipsId",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "hips_project_budget" */
            "pages/HIPs/HIPsBudgets/HIPBudgetsPage"
          )
      ),
      live: true,
      mobile: false,
    },
    {
      path: HIPS_ROOM({
        propertyId: ":propertyId" as unknown as number,
        roomId: ":roomId",
        hipsId: ":hipsId",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "hips_room" */
            "pages/HIPs/HIPsRoom/HIPsRoomPage"
          )
      ),
      live: true,
      mobile: false,
    },
    {
      path: HIPS_PLAN_DETAILS({
        propertyId: ":propertyId" as unknown as number,
        hipsId: ":hipsId",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "hips_plan_details" */
            "pages/HIPs/PlanDetails/PlanDetails"
          )
      ),
      live: true,
      mobile: false,
    },
    {
      path: HIPS_PLAN_DETAILS_OUTPUT({
        propertyId: ":propertyId" as unknown as number,
        hipsId: ":hipsId",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "hips_plan_output" */
            "pages/HIPs/PlanOutput/PlanOutputPage"
          )
      ),
      live: true,
      mobile: false,
    },
    {
      path: SHARED_PLAN({
        key: ":key",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "shared_plan" */
            "pages/SharedPlan/pages/SharedPlanPage"
          )
      ),
      live: true,
      mobile: false,
    },
    {
      path: SHARED_PLAN_OUTPUT({
        key: ":key",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "shared_plan_output" */
            "pages/SharedPlan/pages/SharedPlanOutputPage"
          )
      ),
      live: true,
      mobile: false,
    },
    {
      path: SHARED_PLAN_ROOM_OUTPUT({
        key: ":key",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "shared_plan_room_output" */
            "pages/SharedPlan/pages/SharedPlanRoomOutputPage"
          )
      ),
      live: true,
      mobile: false,
    },
  ],
  [
    {
      path: GUIDE({
        propertyId: ":propertyId" as unknown as number,
        roomId: ":roomId",
        hipsId: ":hipsId",
        id: ":id",
      }),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "guide" */
            "pages/Guides/GuidesPage"
          )
      ),
      live: true,
      mobile: false,
    },
  ],
  [
    {
      path: PROPERTY_ADDITION_REJECTED(
        undefined as unknown as PropertyClaimRejectedRouteParams
      ),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "property_addition_rejected" */
            "pages/PropertyAddition/PropertyClaimRejected/PropertyClaimRejected"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: VERIFY_EMAIL_ERROR(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "verify_email_error" */
            "pages/SignUp/VerificationError/VerificationError"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: FORGOT_PASSWORD(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "login" */
            "pages/Login/Login"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: RESET_PASSWORD(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "reset_password" */
            "pages/ResetPassword/ResetPasswordConfirmation"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: HELP_CENTER(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "help_center" */
            "pages/HelpCenter/HelpCenterPage"
          )
      ),
      live: HELP_CENTER_ENABLED,
      mobile: false,
    },
    {
      path: PRIVACY(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "privacy" */
            "pages/Legal/Privacy"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: TERMS(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "terms" */
            "pages/Legal/Terms"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      path: NOT_FOUND(),
      component: lazy(
        () =>
          import(
            /* webpackChunkName: "not_found" */
            "pages/NotFound/NotFound"
          )
      ),
      live: true,
      mobile: true,
    },
    {
      // TODO: remove this test route once the checkout is integrated into the pro signup flow
      //  see https://tree.taiga.io/project/homebase-engineering/us/1423
      path: "/stripe-test",
      component: lazy(
        () =>
          import(
            /*webpackChunkName: "stripe_test"*/
            "pages/StripeTest"
          )
      ),
      live: SUBSCRIPTIONS_ENABLED,
      mobile: false,
    },
    {
      path: ERROR(),
      component: lazy(
        () =>
          import(
            /*webpackChunkName: "error"*/
            "pages/Error/Error"
          )
      ),
      live: true,
      mobile: true,
    },
  ],
];
