import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { LightingControlTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.LIGHTING_CONTROL,
  LightingControlTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [LightingControlTypes.DIMMER]: t("Dimmer"),
  [LightingControlTypes.SENSOR]: t("Sensor"),
  [LightingControlTypes.CONTROL_PANEL]: t("Control Panel"),
  [LightingControlTypes.SWITCH]: t("Switch"),
  [LightingControlTypes.PULL_CHORD]: t("Pull Chord"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [LightingControlTypes.DIMMER]: t("Dimmer"),
  [LightingControlTypes.SENSOR]: t("Sensor"),
  [LightingControlTypes.CONTROL_PANEL]: t("Control Panel"),
  [LightingControlTypes.SWITCH]: t("Switch"),
  [LightingControlTypes.PULL_CHORD]: t("Pull Chord"),
});

export const LightingControlsConfigTexts: Config = {
  itemName: (t) => t("Lighting Control"),
  categoryName: (t) => t("Lighting Controls"),
  categoryDescription: (t) =>
    t(
      "Lighting controls are tools that help manage how bright or dim lights are in different spaces. They come in various types, like dimmer switches that adjust brightness, timers that turn lights on/off automatically, and smart systems that can be controlled with phones or voice commands. These controls are useful for creating the right atmosphere, saving energy, and making lighting more convenient."
    ),
  itemNames,
  typeNames,
};
