import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { getShouldBeInTheWallRule } from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/PixiFloorPlanItem/PixiFloorPlanItem.rules";
import {
  RestrictPositionRuleFn,
  UpdatePositionParams,
} from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/PixiFloorPlanItem/PixiFloorPlanItem.types";
import { WALL_ITEMS_SPACING, WALL_THICKNESS } from "shared/floorPlan/constants";

import { DoorTypes } from "./enums";
import { Door } from "./types";

const DoorStickToTheWallRule: RestrictPositionRuleFn = (
  updatePositionParams: UpdatePositionParams
) => {
  const { floorPlanItem } = updatePositionParams;
  const door = floorPlanItem.item as Door;
  const rotationRad = door.rotationRad;
  const offsetRotationRad = Math.PI;

  return getShouldBeInTheWallRule({
    wallsGap: {
      absoluteGap: WALL_THICKNESS,
    },
    itemsGap: {
      absoluteGap: WALL_ITEMS_SPACING,
    },
    offsetRotationRad,
    rotationRad,
  })(updatePositionParams);
};

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.DOOR,
  DoorTypes
>["floorPlan"];

const sizes: Config["sizes"] = {
  [DoorTypes.INTERIOR]: { width: 40, height: 40 },
  [DoorTypes.EXTERIOR]: { width: 40, height: 40 },
};

const rules: Config["rules"] = {
  [DoorTypes.INTERIOR]: DoorStickToTheWallRule,
  [DoorTypes.EXTERIOR]: DoorStickToTheWallRule,
};

export const DoorConfigFloorPlan: Config = {
  sizes,
  rules,
};
