import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { RULE_DEFAULT_CANNOT_OVERLAP } from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/PixiFloorPlanItem/PixiFloorPlanItem.rules";

import { CeilingFanTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.CEILING_FAN,
  CeilingFanTypes
>["floorPlan"];

const sizes: Config["sizes"] = {
  [CeilingFanTypes.STANDARD_FAN]: { width: 171, height: 146 },
  [CeilingFanTypes.FAN_WITH_LIGHT]: { width: 171, height: 146 },
};

const rules: Config["rules"] = {
  [CeilingFanTypes.STANDARD_FAN]: RULE_DEFAULT_CANNOT_OVERLAP,
  [CeilingFanTypes.FAN_WITH_LIGHT]: RULE_DEFAULT_CANNOT_OVERLAP,
};

export const CeilingFansConfigFloorPlan: Config = {
  sizes,
  rules,
};
