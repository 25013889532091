import { useSelector } from "@xstate/react";
import { useGlobalState } from "core/state/global/GlobalStateContext";
import { getActivePropertyContext } from "core/state/global/OrchestratorMachine/actions.utils";
import type {
  OrchestratorHIPSContext,
  OrchestratorMachineContext,
} from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { useMemo } from "react";
import { findBy } from "shared/util/findBy";

const orchestratorHIPsSelector = (state: {
  context: OrchestratorMachineContext;
}) => getActivePropertyContext(state.context).hips;

export const useOrchestratorHIPs = () => {
  const { orchestrator } = useGlobalState();
  return useSelector(orchestrator, orchestratorHIPsSelector);
};

export const useOrchestratorHIPsMap = () => {
  const hips = useOrchestratorHIPs();
  return useMemo(
    () =>
      hips.reduce<Record<string, OrchestratorHIPSContext>>(
        (acc, hip) => ({ ...acc, [hip.id]: hip }),
        {}
      ),
    [hips]
  );
};

export const useOrchestratorHIP = (hipsId: string) => {
  const hips = useOrchestratorHIPs();
  return findBy(hips, "id", hipsId);
};
