import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import {
  GuideItemTypeConfig,
  RuleType,
} from "constants/guides/GuideItems.types";
import { RULE_DEFAULT_STICK_TO_THE_WALL } from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/PixiFloorPlanItem/PixiFloorPlanItem.rules";

import { OutletTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.OUTLET,
  OutletTypes
>["floorPlan"];

const sizes: Config["sizes"] = {
  [OutletTypes.SINGLE]: { width: 16, height: 24 },
  [OutletTypes.DOUBLE]: { width: 23, height: 23 },
  [OutletTypes.TWO_FORTY_V]: { width: 23, height: 33 },
  [OutletTypes.WEATHER_RESISTANT]: { width: 23, height: 22 },
};

const rules: Config["rules"] = {
  [OutletTypes.SINGLE]: RULE_DEFAULT_STICK_TO_THE_WALL,
  [OutletTypes.DOUBLE]: RULE_DEFAULT_STICK_TO_THE_WALL,
  [OutletTypes.WEATHER_RESISTANT]: RULE_DEFAULT_STICK_TO_THE_WALL,
  [OutletTypes.TWO_FORTY_V]: RULE_DEFAULT_STICK_TO_THE_WALL,
};

const ruleSets: Config["ruleSets"] = {
  [OutletTypes.SINGLE]: [RuleType.STICK_TO_WALL],
  [OutletTypes.DOUBLE]: [RuleType.STICK_TO_WALL],
  [OutletTypes.WEATHER_RESISTANT]: [RuleType.STICK_TO_WALL],
  [OutletTypes.TWO_FORTY_V]: [RuleType.STICK_TO_WALL],
};

export const OutletsConfigFloorPlan: Config = {
  sizes,
  rules,
  ruleSets,
};
