import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import mainImageFallback from "./assets/ceiling_fan_main.png";
import mainImage from "./assets/ceiling_fan_main.webp";
import fanWithLightFallback from "./assets/fan_with_light.png";
import fanWithLight from "./assets/fan_with_light.webp";
import { ReactComponent as CategoryIcon } from "./assets/icon_category_ceiling_fans.svg";
import { ReactComponent as IconFanWithLight } from "./assets/icon_fan_with_light.svg";
import { ReactComponent as IconStandard } from "./assets/icon_standard_fan.svg";
import { ReactComponent as IconUnknown } from "./assets/icon_unknown.svg";
import standardFanFallback from "./assets/standard_fan.png";
import standardFan from "./assets/standard_fan.webp";
import { ReactComponent as SymbolFanWithLight } from "./assets/symbol_fan_with_light.svg";
import { ReactComponent as SymbolStandardFan } from "./assets/symbol_standard_fan.svg";
import { CeilingFanTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.CEILING_FAN,
  CeilingFanTypes
>["images"];

const icons: Config["icons"] = {
  [CeilingFanTypes.STANDARD_FAN]: IconStandard,
  [CeilingFanTypes.FAN_WITH_LIGHT]: IconFanWithLight,
};

const images: Config["images"] = {
  [CeilingFanTypes.STANDARD_FAN]: {
    src: standardFan,
    fallbackSrc: standardFanFallback,
  },
  [CeilingFanTypes.FAN_WITH_LIGHT]: {
    src: fanWithLight,
    fallbackSrc: fanWithLightFallback,
  },
};

const symbols: Config["symbols"] = {
  [CeilingFanTypes.STANDARD_FAN]: SymbolStandardFan,
  [CeilingFanTypes.FAN_WITH_LIGHT]: SymbolFanWithLight,
};

export const CeilingFansConfigImages: Config = {
  categoryIcon: CategoryIcon,
  defaultIcon: IconUnknown,
  defaultImage: {
    src: mainImage,
    fallbackSrc: mainImageFallback,
  },
  symbols,
  icons,
  images,
};
