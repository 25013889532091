import { Polygon } from "@flatten-js/core";
import { FLOOR_PLAN_MAIN_CONTAINER_NAME } from "components/HBFloorPlan/FloorPlan/components/FloorPlanMainContainer/FloorPlanMainContainer.consts";
import { Coords } from "pages/Guides/types";
import { Container as PixiContainer } from "pixi.js";

import {
  mapPointsToList,
  minusCoords,
  rotatePointWithAngle,
  transformPointWithMatrix,
} from "./points.utils";
import { rotatePolygon } from "./polygon.utils";

const getMainContainer = (container: PixiContainer) => {
  let currentContainer = container;

  while (
    currentContainer &&
    currentContainer.name !== FLOOR_PLAN_MAIN_CONTAINER_NAME
  ) {
    currentContainer = currentContainer.parent;
  }

  return currentContainer || container.parent;
};

export const getPixiContainerGeometry = (container: PixiContainer) => {
  const { rotation, position } = container;
  const root = getMainContainer(container);

  const containerWT = container.worldTransform.clone();
  const localBounds = container.getLocalBounds();

  if (!localBounds.width || !localBounds.height) {
    return undefined;
  }

  const coords = {
    x: position.x,
    y: position.y,
  };
  const { width: normalWidth, height: normalHeight } = localBounds;

  const normalCenterOffset = {
    x: localBounds.x + localBounds.width * 0.5,
    y: localBounds.y + localBounds.height * 0.5,
  };
  const centerOffset = rotatePointWithAngle(normalCenterOffset, rotation);

  const localNormalCorners: Coords[] = [
    {
      x: localBounds.x,
      y: localBounds.y,
    },
    {
      x: localBounds.x + localBounds.width,
      y: localBounds.y,
    },
    {
      x: localBounds.x + localBounds.width,
      y: localBounds.y + localBounds.height,
    },
    {
      x: localBounds.x,
      y: localBounds.y + localBounds.height,
    },
  ];
  let corners: Coords[] = localNormalCorners.map((c) =>
    transformPointWithMatrix(c, containerWT)
  );
  let parent = container.parent;

  while (parent) {
    const matrix = parent.localTransform.clone().invert();
    corners = corners.map((c) => transformPointWithMatrix(c, matrix));

    if (parent === root) {
      break;
    }

    parent = parent.parent;
  }

  const P = new Polygon(mapPointsToList(corners));
  const normalCorners = rotatePolygon(
    P,
    -1 * rotation,
    minusCoords(P.box.center, centerOffset)
  );

  return {
    coords,
    normalWidth,
    normalHeight,
    normalCorners,
    normalCenterOffset,
    corners,
    centerOffset,
  };
};
