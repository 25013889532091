import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";

import { CeilingFanControlsConfig } from "./CeilingFanControls/config";
import { CeilingFansConfig } from "./CeilingFans/config";
import { ElectricalPanelConfig } from "./ElectricalPanel/config";
import { LightingControlsConfig } from "./LightingControls/config";
import { LightingFixturesConfig } from "./LightingFixtures/config";
import { OutletsConfig } from "./Outlets/config";
import { SmokeAlarmConfig } from "./SmokeAlarms/config";

export const Electrical = {
  [GuideItemTypeCategory.ELECTRICAL_PANEL]: ElectricalPanelConfig,
  [GuideItemTypeCategory.LIGHTING_FIXTURE]: LightingFixturesConfig,
  [GuideItemTypeCategory.LIGHTING_CONTROL]: LightingControlsConfig,
  [GuideItemTypeCategory.OUTLET]: OutletsConfig,
  [GuideItemTypeCategory.CEILING_FAN]: CeilingFansConfig,
  [GuideItemTypeCategory.CEILING_FAN_CONTROL]: CeilingFanControlsConfig,
  [GuideItemTypeCategory.SMOKE_ALARM]: SmokeAlarmConfig,
};
