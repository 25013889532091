import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";

import { DoorConfigFloorPlan } from "./config.floorPlan";
import { DoorsConfigImages } from "./config.images";
import { DoorsConfigTexts } from "./config.texts";
import { DoorTypes } from "./enums";

type Config = GuideItemTypeConfig<GuideItemTypeCategory.DOOR, DoorTypes>;

const types: Config["types"] = [DoorTypes.INTERIOR, DoorTypes.EXTERIOR];

export const DoorsConfig: Config = {
  guideType: GuideType.WINDOWS_AND_DOORS,
  category: GuideItemTypeCategory.DOOR,
  types,
  typesOrder: getOrderedMap(types),
  texts: DoorsConfigTexts,
  images: DoorsConfigImages,
  floorPlan: DoorConfigFloorPlan,
  allowedInRooms: undefined,
  allowedIntents: undefined,
} as const;
