import { ReactComponent as IconBookmark } from "assets/icons/buttons/bookmark.svg";
import { ReactComponent as IconBookmarkBorder } from "assets/icons/buttons/bookmark_border.svg";
import { ReactComponent as IconFavorite } from "assets/icons/buttons/favorite.svg";
import { ReactComponent as IconFavoriteBorder } from "assets/icons/buttons/favorite_border.svg";
import { ReactComponent as IconFloorPlan } from "assets/icons/filters/floor_plan.svg";
import { ReactComponent as IconForward } from "assets/icons/filters/forward.svg";
import { ReactComponent as IconHandyman } from "assets/icons/filters/handyman.svg";
import { ReactComponent as IconHome } from "assets/icons/filters/home.svg";
import { ReactComponent as IconHomePlan } from "assets/icons/filters/home_plan.svg";
import { ReactComponent as IconInspiration } from "assets/icons/filters/inspiration.svg";
import { ReactComponent as IconRender } from "assets/icons/filters/render.svg";
import { ReactComponent as IconRewind } from "assets/icons/filters/rewind.svg";
import { ReactComponent as IconGroup } from "assets/icons/group.svg";
import { getOrderedMap } from "common/utils/listUtils";
import { CategoryTypeToIconsKeysMap } from "constants/categories/category.icons";
import { GuideType } from "constants/guides/GuideType";
import { InspirationFiltersMapping } from "constants/inspiration/inspiration.types";
import {
  ROOMS_PIECES_NAMES,
  ROOMS_PIECES_ORDERS,
} from "constants/rooms/roomPieces";
import { SubCategoryToTitleKeysMap } from "constants/subcategories/subcategory.types";
import { ORCHESTRATOR_STATES } from "core/state/global/OrchestratorMachine/constants";
import { getGuideTypes, GuideTypesToTitleKeysMap } from "pages/Guides/consts";
import { TFunction } from "react-i18next";
import { SvgIconComponent } from "shared/types/SvgIcon";

import {
  BATHROOM_SPECIFIC_TAGS_LABELS,
  BUDGET_TAGS_LABELS,
  COLOR_TAGS_LABELS,
  DESIGN_STYLES_TAGS_LABELS,
  FINISH_TAGS_LABELS,
  KITCHEN_SPECIFIC_TAGS_LABELS,
  LOOK_TAGS_LABELS,
  MATERIAL_TAGS_LABELS,
  RELEVANCE_TAGS_LABELS,
  SHAPE_TAGS_LABELS,
} from "./filters/proFilters.const";
import { SelectedImage } from "./types";

// TODO(Andrei): For some reason `satisfies` feature doesn't work in our current babel-core version
// SyntaxError: /frontend/src/pages/Media/constants.ts: Missing semicolon. (31:11)
// Enable it after upgrading to a newer version of babel-core to ensure typesafety
export const SharedCollectionWithMeValue = "with-me" as const; // satisfies SharedCollectionType;
export const SharedCollectionByMeValue = "by-me" as const; // satisfies SharedCollectionType;
export const SharedCollectionInactiveValue = "inactive" as const; // satisfies SharedCollectionType;

export const RECENT_UPLOADS_SHARE_WITH_CLIENTS_ID =
  "recent-uploads-share-with-clients" as const;
export const MEDIA_SCROLL_CONTAINER_ID = "media-scroll-container";
export const MEDIA_SCROLL_CONTAINER_ITEM_ID_BASE =
  "media-scroll-container-item";
export const MEDIA_MOBILE_ACTIONS_MENU_ID = "media-mobile-actions-menu";

export const SHARED_ALBUM_ACTIVE_STATE_ID = "shared" as const;
export const PHOTOS_ACTIVE_STATE_ID = "photos" as const;
export const ALBUMS_ACTIVE_STATE_ID = "albums" as const;
const GET_INSPIRED_ACTIVE_STATE_ID = "inspired" as const;

export const mediaActiveRouteState = {
  [ORCHESTRATOR_STATES.MEDIA]: PHOTOS_ACTIVE_STATE_ID,
  [ORCHESTRATOR_STATES.MEDIA_ALBUMS]: ALBUMS_ACTIVE_STATE_ID,
  [ORCHESTRATOR_STATES.MEDIA_ALBUMS_SHARED_BY_ME]: SHARED_ALBUM_ACTIVE_STATE_ID,
  [ORCHESTRATOR_STATES.MEDIA_ALBUMS_SHARED_WITH_ME]:
    SHARED_ALBUM_ACTIVE_STATE_ID,
  [ORCHESTRATOR_STATES.MEDIA_ALBUMS_INACTIVE]: SHARED_ALBUM_ACTIVE_STATE_ID,
  [ORCHESTRATOR_STATES.GET_INSPIRED]: GET_INSPIRED_ACTIVE_STATE_ID,
  [ORCHESTRATOR_STATES.GET_INSPIRED_CATEGORY]: GET_INSPIRED_ACTIVE_STATE_ID,
  [ORCHESTRATOR_STATES.GET_INSPIRED_CATEGORY_RESULTS]:
    GET_INSPIRED_ACTIVE_STATE_ID,
};

const INSPIRATION = "true";
const NOT_INSPIRATION = "false";
const IN_ALBUM = "true";
const NOT_IN_ALBUM = "false";

export const favoriteAlbumsIcons = {
  [INSPIRATION]: {
    [IN_ALBUM]: IconFavorite,
    [NOT_IN_ALBUM]: IconFavoriteBorder,
  },
  [NOT_INSPIRATION]: {
    [IN_ALBUM]: IconBookmark,
    [NOT_IN_ALBUM]: IconBookmarkBorder,
  },
} as const;

const HOMEOWNER_TAG_PREFIX = "homeowner_tag_value:";

export const removeHomeownerTagValuePrefix = (tag: string) =>
  tag.replace(HOMEOWNER_TAG_PREFIX, "");
export const getHomeownerTagValuePrefix = (tag: string) =>
  HOMEOWNER_TAG_PREFIX + tag;
export const isHomeownerTag = (tag: string) =>
  tag.startsWith(HOMEOWNER_TAG_PREFIX);

export enum COMMON_TAG_CATEGORIES {
  HOMEOWNER = "shared",
  IMPROVEMENT_PROJECTS = "improvement_projects",
  BUILD_STATUS = "build_status",
  ROOMS_AND_SPACES = "rooms_and_spaces",
  PLANNING_CATEGORIES = "planning_categories",
  BUDGET = "budget",
  RELEVANCE = "relevance",
  DESIGN_STYLE = "design_style",
  KITCHEN_SPECIFIC = "kitchen_specific",
  BATHROOM_SPECIFIC = "bathroom_specific",
  MATERIAL = "material",
  SHAPE = "shape",
  LOOK = "look",
  COLOR = "color",
  FINISH = "finish",
  SHARED_WITH_ME = "shared_with_me",
  OTHER = "other",
}

export const COMMON_TAG_CATEGORIES_NAMES = (
  t: TFunction
): Record<COMMON_TAG_CATEGORIES, string> => ({
  [COMMON_TAG_CATEGORIES.HOMEOWNER]: t("Homeowner"),
  [COMMON_TAG_CATEGORIES.BUILD_STATUS]: t("Build Status"),
  [COMMON_TAG_CATEGORIES.ROOMS_AND_SPACES]: t("Rooms & Spaces"),
  [COMMON_TAG_CATEGORIES.PLANNING_CATEGORIES]: t("Build Categories"),
  [COMMON_TAG_CATEGORIES.BUDGET]: t("Budget"),
  [COMMON_TAG_CATEGORIES.RELEVANCE]: t("Relevance"),
  [COMMON_TAG_CATEGORIES.DESIGN_STYLE]: t("Design Style"),
  [COMMON_TAG_CATEGORIES.KITCHEN_SPECIFIC]: t("Kitchen Specific"),
  [COMMON_TAG_CATEGORIES.BATHROOM_SPECIFIC]: t("Bathroom Specific"),
  [COMMON_TAG_CATEGORIES.MATERIAL]: t("Material"),
  [COMMON_TAG_CATEGORIES.SHAPE]: t("Shape"),
  [COMMON_TAG_CATEGORIES.LOOK]: t("Look"),
  [COMMON_TAG_CATEGORIES.COLOR]: t("Color"),
  [COMMON_TAG_CATEGORIES.FINISH]: t("Finish"),
  [COMMON_TAG_CATEGORIES.IMPROVEMENT_PROJECTS]: t("Improvement Projects"),
  [COMMON_TAG_CATEGORIES.SHARED_WITH_ME]: t("Shared With Me"),
  [COMMON_TAG_CATEGORIES.OTHER]: t("Other"),
});

export const getCommonTagCategoriesOrder = Object.values(COMMON_TAG_CATEGORIES);

export const IMPROVEMENT_PROJECTS_TAGS_ICON = IconHomePlan;

export enum BUILD_STATUS_TAGS {
  BEFORE = "before",
  DURING = "during",
  AFTER = "after",
}

export enum OTHER_TAGS {
  ARCHITECTURAL_DRAWING = "architectural_drawing",
  INSPIRATION = "inspiration",
  RENDER = "render",
}

export const OTHER_ORDERS = getOrderedMap(Object.values(OTHER_TAGS));

export const UPLOADS_IDS = {
  REGULAR: "regular",
  ...BUILD_STATUS_TAGS,
} as const;

export const BUILD_STATUS_TAGS_NAMES = (
  t: TFunction
): Record<BUILD_STATUS_TAGS, string> => ({
  [BUILD_STATUS_TAGS.BEFORE]: t("Before"),
  [BUILD_STATUS_TAGS.DURING]: t("During"),
  [BUILD_STATUS_TAGS.AFTER]: t("After"),
});

export const BUILD_STATUS_TAGS_ORDERS = getOrderedMap(
  Object.values(BUILD_STATUS_TAGS)
);

export const BUILD_STATUS_TAGS_ICONS: Record<
  BUILD_STATUS_TAGS | "default",
  SvgIconComponent
> = {
  [BUILD_STATUS_TAGS.BEFORE]: IconRewind,
  [BUILD_STATUS_TAGS.DURING]: IconHandyman,
  [BUILD_STATUS_TAGS.AFTER]: IconForward,
  default: IconHome,
};

const BUILD_CATEGORY_TYPES = getGuideTypes();

export const BUILD_CATEGORY_TYPES_NAMES = (t: TFunction) => {
  const texts = GuideTypesToTitleKeysMap(t);
  return BUILD_CATEGORY_TYPES.reduce((result, type) => {
    result[type] = texts[type];
    return result;
  }, {} as Record<GuideType, string>);
};
export const BUILD_CATEGORY_TYPES_ORDERS = getOrderedMap(BUILD_CATEGORY_TYPES);
export const BUILD_CATEGORY_TYPES_ICONS = CategoryTypeToIconsKeysMap;

export const OTHER_ICONS = {
  [OTHER_TAGS.ARCHITECTURAL_DRAWING]: IconFloorPlan,
  [OTHER_TAGS.INSPIRATION]: IconInspiration,
  [OTHER_TAGS.RENDER]: IconRender,
};

export const SHARED_WITH_ME_ICONS = {
  default: IconGroup,
};

export const OTHER_NAMES = (t: TFunction) => ({
  [OTHER_TAGS.ARCHITECTURAL_DRAWING]: t("Floor Plans"),
  [OTHER_TAGS.INSPIRATION]: t("Inspiration"),
  [OTHER_TAGS.RENDER]: t("3D Render"),
});

export const ALL_TAG_NAMES_FROM_ALL_CATEGORIES = (t: TFunction) => ({
  ...BUILD_STATUS_TAGS_NAMES(t),
  ...BUILD_CATEGORY_TYPES_NAMES(t),
  ...ROOMS_PIECES_NAMES(t),
  ...OTHER_NAMES(t),
  ...SubCategoryToTitleKeysMap(t),
  ...GuideTypesToTitleKeysMap(t),
  ...BUDGET_TAGS_LABELS(t),
  ...RELEVANCE_TAGS_LABELS(t),
  ...DESIGN_STYLES_TAGS_LABELS(t),
  ...KITCHEN_SPECIFIC_TAGS_LABELS(t),
  ...BATHROOM_SPECIFIC_TAGS_LABELS(t),
  ...MATERIAL_TAGS_LABELS(t),
  ...SHAPE_TAGS_LABELS(t),
  ...LOOK_TAGS_LABELS(t),
  ...COLOR_TAGS_LABELS(t),
  ...FINISH_TAGS_LABELS(t),
  ...Object.values(InspirationFiltersMapping).reduce((result, data) => {
    return { ...result, ...data.texts(t) };
  }, {}),
});

export const TAG_NAMES_BY_CATEGORY = (t: TFunction) => ({
  [COMMON_TAG_CATEGORIES.BUILD_STATUS]: {
    ...BUILD_STATUS_TAGS_NAMES(t),
  },
  [COMMON_TAG_CATEGORIES.PLANNING_CATEGORIES]: {
    ...BUILD_CATEGORY_TYPES_NAMES(t),
  },
  [COMMON_TAG_CATEGORIES.ROOMS_AND_SPACES]: {
    ...ROOMS_PIECES_NAMES(t),
  },
  [COMMON_TAG_CATEGORIES.OTHER]: {
    ...OTHER_NAMES(t),
  },
  [COMMON_TAG_CATEGORIES.BUDGET]: {
    ...BUDGET_TAGS_LABELS(t),
  },
  [COMMON_TAG_CATEGORIES.RELEVANCE]: {
    ...RELEVANCE_TAGS_LABELS(t),
  },
  [COMMON_TAG_CATEGORIES.DESIGN_STYLE]: {
    ...DESIGN_STYLES_TAGS_LABELS(t),
  },
  [COMMON_TAG_CATEGORIES.KITCHEN_SPECIFIC]: {
    ...KITCHEN_SPECIFIC_TAGS_LABELS(t),
  },
  [COMMON_TAG_CATEGORIES.BATHROOM_SPECIFIC]: {
    ...BATHROOM_SPECIFIC_TAGS_LABELS(t),
  },
  [COMMON_TAG_CATEGORIES.MATERIAL]: {
    ...MATERIAL_TAGS_LABELS(t),
  },
  [COMMON_TAG_CATEGORIES.SHAPE]: {
    ...SHAPE_TAGS_LABELS(t),
  },
  [COMMON_TAG_CATEGORIES.LOOK]: {
    ...LOOK_TAGS_LABELS(t),
  },
  [COMMON_TAG_CATEGORIES.COLOR]: {
    ...COLOR_TAGS_LABELS(t),
  },
  [COMMON_TAG_CATEGORIES.FINISH]: {
    ...FINISH_TAGS_LABELS(t),
  },
});

export const TAG_ORDERS_BY_CATEGORY = {
  [COMMON_TAG_CATEGORIES.BUILD_STATUS]: {
    ...BUILD_STATUS_TAGS_ORDERS,
  },
  [COMMON_TAG_CATEGORIES.PLANNING_CATEGORIES]: {
    ...BUILD_CATEGORY_TYPES_ORDERS,
  },
  [COMMON_TAG_CATEGORIES.ROOMS_AND_SPACES]: {
    ...ROOMS_PIECES_ORDERS,
  },
  [COMMON_TAG_CATEGORIES.OTHER]: {
    ...OTHER_ORDERS,
  },
};

export const getTagOrdersByCategory = TAG_ORDERS_BY_CATEGORY;

export const DEFAULT_IMAGE: SelectedImage = {
  id: undefined,
  name: undefined,
  tags: [],
  collections: [],
  reactions: [],
  get_url: undefined,
  get_url_fallback: undefined,
  download_url: undefined,
  thumbnail_url: undefined,
  thumbnail_url_fallback: undefined,
  created_on: undefined,
  uploaded_on: undefined,
  index: undefined,
  uploadsId: undefined,
};

export const SAVE_SHARE_IMAGE_ALLOWED_TAGS_CATEGORIES: COMMON_TAG_CATEGORIES[] =
  [
    COMMON_TAG_CATEGORIES.BUILD_STATUS,
    COMMON_TAG_CATEGORIES.OTHER,
    COMMON_TAG_CATEGORIES.PLANNING_CATEGORIES,
  ];

export const mediaUseQueryKeys = {
  GET_ALBUMS: "GET_ALBUMS",
  GET_UPLOAD: "GET_UPLOAD",
  GET_UPLOADS: "GET_UPLOADS",
  GET_SHARE_RECEIVED_REQUESTS: "GET_SHARE_RECEIVED_REQUESTS",
  GET_SHARE_RECEIVED_REQUEST: "GET_SHARE_RECEIVED_REQUEST",
  GET_SHARE_SENT_REQUESTS: "GET_SHARE_SENT_REQUESTS",
  POST_ACCEPT_RECEIVED_REQUEST: "POST_ACCEPT_RECEIVED_REQUEST",
  POST_UPDATE_SHARE_SETTINGS: "POST_UPDATE_SHARE_SETTINGS",
  POST_REJOIN_COLLECTION: "POST_REJOIN_COLLECTION",
  POST_UPDATE_ALBUM_IMAGES: "POST_UPDATE_ALBUM_IMAGES",
} as const;
