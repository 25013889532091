export type RoomPanelActiveView = "Categories" | "Images";

export interface InfoRowAction {
  icon: JSX.Element;
  label: string;
  onIconClick: () => void;
}

export enum SubCategoryStatus {
  CAN_NOT_START = "can_not_start",
  CAN_START = "can_start",
  ACTIVE = "active",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
}

export enum CategoryPanelStatus {
  NO_LAYOUT = "no_layout",
  ACTIVE = "active",
  LOCKED = "locked",
  COMPLETED = "completed",
}
