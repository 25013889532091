import rgbHex from "rgb-hex";

const RGBA_REGEX = /^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i;
const SPACE_REGEX = /\s/g;
const HASH_REGEX = /^#/;

export interface ColorData {
  /* long #hex @example #aabbcc */
  color: string;
  /* @example 0.25 */
  alpha: number;
}

export const toColorData = (value: string): ColorData => {
  const cleansedHex = value.replace(HASH_REGEX, "").replace(SPACE_REGEX, "");
  const isRGB = cleansedHex.match(RGBA_REGEX);
  const isShortenedHex = cleansedHex.length === 3 || cleansedHex.length === 4;

  const hex = isRGB
    ? `#${rgbHex(cleansedHex)}`
    : isShortenedHex
    ? `#${shortHexToLongHex(cleansedHex)}`
    : `#${cleansedHex}`;
  const withAlpha = hex.length > 7;

  if (withAlpha) {
    const color = hex.substring(0, 7);
    const alpha = Number(`0x${hex.substring(color.length)}`) / 255 ?? 1;

    return {
      color,
      alpha,
    };
  }

  return {
    color: hex,
    alpha: 1.0,
  };
};

export const to0xHex = (value: string): number => {
  const cleansedHex = value.replace(HASH_REGEX, "").replace(SPACE_REGEX, "");

  const isRGB = cleansedHex.match(RGBA_REGEX);

  if (isRGB) {
    return Number(`0x${rgbHex(cleansedHex)}`);
  }

  const isShortenedHex = cleansedHex.length === 3 || cleansedHex.length === 4;

  if (isShortenedHex) {
    const longHex = shortHexToLongHex(cleansedHex);
    return Number(`0x${longHex}`);
  }

  return Number(`0x${cleansedHex}`);
};

const shortHexToLongHex = (value: string) =>
  value
    .split("")
    .map((item) => `${item}${item}`)
    .join("");
