import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import {
  GuideItemTypeConfig,
  RuleType,
} from "constants/guides/GuideItems.types";
import {
  RULE_DEFAULT_CANNOT_OVERLAP,
  RULE_DEFAULT_STICK_TO_THE_WALL,
} from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/PixiFloorPlanItem/PixiFloorPlanItem.rules";

import { LightingFixtureTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.LIGHTING_FIXTURE,
  LightingFixtureTypes
>["floorPlan"];

const sizes: Config["sizes"] = {
  [LightingFixtureTypes.RECESSED_LIGHT]: { width: 29, height: 29 },
  [LightingFixtureTypes.HANGING_LIGHT]: { width: 29, height: 29 },
  [LightingFixtureTypes.CHANDELIER]: { width: 29, height: 29 },
  [LightingFixtureTypes.LED]: { width: 15, height: 10 },
  [LightingFixtureTypes.FLUSHMOUNT]: { width: 29, height: 30 },
  [LightingFixtureTypes.SEMI_FLUSHMOUNT]: { width: 29, height: 30 },
  [LightingFixtureTypes.MONORAIL]: { width: 20, height: 20 },
  [LightingFixtureTypes.WALL_SCONCES]: { width: 22, height: 29 },
};

const rules: Config["rules"] = {
  [LightingFixtureTypes.RECESSED_LIGHT]: RULE_DEFAULT_CANNOT_OVERLAP,
  [LightingFixtureTypes.HANGING_LIGHT]: RULE_DEFAULT_CANNOT_OVERLAP,
  [LightingFixtureTypes.LED]: RULE_DEFAULT_CANNOT_OVERLAP,
  [LightingFixtureTypes.FLUSHMOUNT]: RULE_DEFAULT_CANNOT_OVERLAP,
  [LightingFixtureTypes.MONORAIL]: RULE_DEFAULT_CANNOT_OVERLAP,
  [LightingFixtureTypes.SEMI_FLUSHMOUNT]: RULE_DEFAULT_CANNOT_OVERLAP,
  [LightingFixtureTypes.CHANDELIER]: RULE_DEFAULT_CANNOT_OVERLAP,
  [LightingFixtureTypes.WALL_SCONCES]: RULE_DEFAULT_STICK_TO_THE_WALL,
};

const ruleSets: Config["ruleSets"] = {
  [LightingFixtureTypes.RECESSED_LIGHT]: [],
  [LightingFixtureTypes.HANGING_LIGHT]: [],
  [LightingFixtureTypes.LED]: [],
  [LightingFixtureTypes.FLUSHMOUNT]: [],
  [LightingFixtureTypes.MONORAIL]: [],
  [LightingFixtureTypes.CHANDELIER]: [],
  [LightingFixtureTypes.SEMI_FLUSHMOUNT]: [],
  [LightingFixtureTypes.WALL_SCONCES]: [RuleType.STICK_TO_WALL],
};

export const LightingFixturesConfigFloorPlan: Config = {
  sizes,
  rules,
  ruleSets,
};
