import { getDevicePixelRatio } from "common/utils/deviceUtils";
import { Coords } from "pages/Guides/types";
import { IApplicationOptions } from "pixi.js";

/**
 * Note(pavel): There is a `constants.color.ts` file for every color-related constant.
 * In this file MUST be no imports from `scss` files as it is a transitive dependency
 * in many utils and some of them are used in WebWorker.
 */

const STAGE_OPTIONS: Partial<IApplicationOptions> = {
  backgroundAlpha: 0,
  antialias: true,
};

const STAGE_DIMENSION = 1000;
export const DEFAULT_PLAN_SIZE = 917;
export const STAGE_RELATIVE_DIMENSION = 1000;
export const NULL_POSITION: Coords = {
  x: STAGE_RELATIVE_DIMENSION * -0.5,
  y: STAGE_RELATIVE_DIMENSION * -0.5,
};
export const STAGE_PADDING = 50;

export const STAGE_CONFIG = {
  options: STAGE_OPTIONS,
  width: STAGE_DIMENSION,
  height: STAGE_DIMENSION,
  resolution: getDevicePixelRatio(),
};

export const WALL_THICKNESS = 15;
export const WINDOW_BASE_THICKNESS = WALL_THICKNESS;
export const WINDOW_THICKNESS = 3;

export const CORNER_HOVERED_HIT_RADIUS = 30;
export const CORNER_MARKER_RADIUS = 7.5;
export const CORNER_MARKER_STROKE = 1;

export const CORNER_VIRTUAL_WALL_THICKNESS = 2;

export const WALL_ITEMS_SPACING = 10;
export const WALL_ITEM_BASE_THICKNESS = WALL_THICKNESS;

export const OPEN_FLOOR_PLAN_ROOM_LABEL_VERTICAL_CENTERING_OFFSET = 3;

export const BASE_SHAPE_RECTANGLE_OR_SQUARE_LINE_COUNT = 4;

export const CUSTOM_SHAPE_MINIAL_CLOSE_COUNT = 3;
export const CUSTOM_SHAPE_AUTOADJUSTING_DELTA = 10;
export const CUSTOM_SHAPE_CLOSE_OFFSET = 30;

export const GRID_LINE_THIN = 1;

export const HALF_WALL_PATTERN_LINE_THICKNESS = 2;

export const HALF_WALL_SPLIT_LENGTH = 14;

export const AUTO_ALIGN_OFFSET = 5;
export const ALIGN_LINE_THICKNESS = 0.5;
export const DISTANCEHELPER_LINE_THICKNESS = 1;

const INCHES = 1;

export const WINDOW_LENGTH_MIN = 12 * INCHES;
export const WINDOW_LENGTH_MAX = 96 * INCHES;
export const WINDOW_LENGTH_DEFAULT = 24 * INCHES;

export const DEFAULT_FLOORPLAN_WIDTH = 868;
export const DEFAULT_FLOORPLAN_HEIGHT = 868;

export const EPSILON = 0.00001;
