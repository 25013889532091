import { assign } from "@xstate/immer";
import { StateActionEvent } from "common/state/types";
import { getOrchestratorActions } from "core/state/global/OrchestratorMachine/actions";
import {
  ORCHESTRATOR_ACTIONS as ACTIONS,
  ORCHESTRATOR_MACHINE_ID,
  ORCHESTRATOR_STATES as STATES,
} from "core/state/global/OrchestratorMachine/constants";
import { OrchestratorMachineContext } from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { useStableNavigate } from "core/state/hooks/useStableNavigate";
import { TFunction } from "react-i18next";
import { createMachine } from "xstate";

import {
  createRestoreOrchestratorMachineContext,
  getCurrentMachineTargetNode,
  getOrchestratorMachineContext,
  getOrchestratorMachineTargetNode,
} from "./actions.utils";
import { getEducatedActions } from "./getEducated/GetEducated.actions";
import { getOrchestratorGoto } from "./goto";
import { HIPsDashboardMachineActions } from "./hips/dashboard/HIPsDashboardMachineActions";
import { HIPsDashboardMachineConditions } from "./hips/dashboard/HIPsDashboardMachineConditions";
import { getHIPsMachineNavigateActions } from "./hips/HIPsMachineNavigate.actions";
import { getHIPsSOWMachineActions } from "./hips/sow/HIPsSOWMachine.actions";

export const getOrchestratorMachine = (deps: {
  navigate: ReturnType<typeof useStableNavigate>;
  t: TFunction;
}) => {
  const { actions } = getOrchestratorActions(deps);
  const { goto } = getOrchestratorGoto(deps);
  const hipsActions = HIPsDashboardMachineActions(deps);
  const hipsConditions = HIPsDashboardMachineConditions();
  const hipsNavigateActions = getHIPsMachineNavigateActions(deps);
  const roomNavigateActions = getHIPsMachineNavigateActions({
    ...deps,
    isNavigateFromRoom: true,
  });
  const sowActions = getHIPsSOWMachineActions();
  const blogGetEducatedActions = getEducatedActions();

  const mediaNavigationActions = {
    [ACTIONS.MEDIA.GO_TO_PHOTOS]: {
      actions: goto.media,
      target: STATES.MEDIA,
    },
    [ACTIONS.MEDIA.GO_TO_ALBUMS]: {
      actions: goto.mediaAlbums,
      target: STATES.MEDIA_ALBUMS,
    },
    [ACTIONS.MEDIA.OPEN_ALBUM]: {
      actions: goto.mediaAlbums,
      target: STATES.MEDIA_ALBUMS,
    },
    [ACTIONS.MEDIA.GO_TO_INSPIRED]: {
      actions: goto.getInspired,
      target: STATES.MEDIA,
    },
    [ACTIONS.MEDIA.GO_TO_SHARED_WITH_ME]: {
      actions: goto.mediaSharedWithMe,
      target: STATES.MEDIA_ALBUMS_SHARED_WITH_ME,
    },
    [ACTIONS.MEDIA.GO_TO_SHARED_BY_ME]: {
      actions: goto.mediaSharedByMe,
      target: STATES.MEDIA_ALBUMS_SHARED_BY_ME,
    },
    [ACTIONS.MEDIA.GO_TO_SHARED_INACTIVE]: {
      actions: goto.mediaSharedInactive,
      target: STATES.MEDIA_ALBUMS_INACTIVE,
    },
  };

  const mediaHelperTextActions = {
    [ACTIONS.MEDIA.SET_HAS_SEEN_MEDIA_HELPER_TEXT]: {
      actions: actions.media.setHasSeenMediaHelperText,
    },
  };

  const orchestratorGetEducatedActions = {
    [ACTIONS.GET_EDUCATED.SET_POST_READ]: {
      actions: blogGetEducatedActions.setPostRead,
    },
    [ACTIONS.GET_EDUCATED.REMOVE_POST_READ]: {
      actions: blogGetEducatedActions.removePostRead,
    },
    [ACTIONS.GET_EDUCATED.ADD_BOOKMARK]: {
      actions: blogGetEducatedActions.addBookmark,
    },
    [ACTIONS.GET_EDUCATED.REMOVE_BOOKMARK]: {
      actions: blogGetEducatedActions.removeBookmark,
    },
    [ACTIONS.GET_EDUCATED.REMOVE_ALL_BOOKMARK]: {
      actions: blogGetEducatedActions.removeAllBookmarks,
    },
  };

  const drawerActions = {
    [ACTIONS.DRAWER.HELP]: {
      actions: [actions.ephemeral.help],
    },
    [ACTIONS.DRAWER.MEDIA]: {
      actions: [goto.media],
      target: STATES.MEDIA,
    },
    [ACTIONS.DRAWER.SET_ACTIVE_PROPERTY]: {
      actions: [actions.setActivePropertyId],
    },
    [ACTIONS.DRAWER.DASHBOARD]: {
      actions: [actions.setActivePropertyId, goto.dashboard],
      target: STATES.DASHBOARD,
    },
    [ACTIONS.DRAWER.ADD_PROPERTY]: {
      actions: [goto.propertyAdditionAddProperty],
      target: STATES.DASHBOARD,
    },
    [ACTIONS.DRAWER.GET_EDUCATED]: {
      actions: [goto.getEducated],
      target: STATES.GET_EDUCATED.MAIN,
    },
  };

  const sidebarActions = {
    [ACTIONS.SIDEBAR.OPEN]: {
      actions: [actions.ephemeral.openSidebar],
    },
    [ACTIONS.SIDEBAR.CLOSE_SIDEBAR]: {
      actions: [actions.ephemeral.closeSidebar],
    },
  };

  const anyModalActions = {
    [ACTIONS.ANY_MODAL.OPEN]: {
      actions: [actions.ephemeral.openAnyModal],
    },
    [ACTIONS.ANY_MODAL.CLOSE]: {
      actions: [actions.ephemeral.closeAnyModal],
    },
    [ACTIONS.CONFIRMATION_MODAL_SEEN]: {
      actions: [actions.media.setHasSeenConfirmationWarning],
    },
  };

  const footerActions = {
    [ACTIONS.FOOTER.HELP_CENTER]: {
      actions: [goto.helpCenter],
      target: STATES.HELP_CENTER,
    },
    [ACTIONS.FOOTER.HELP]: {
      actions: [actions.ephemeral.help],
    },
    [ACTIONS.FOOTER.MAKE_SUGGESTION]: {
      actions: [actions.ephemeral.makeSuggestion],
    },
    [ACTIONS.FOOTER.REPORT_BUG]: {
      actions: [actions.ephemeral.reportBug],
    },
  };

  const headerActions = {
    [ACTIONS.HEADER.DASHBOARD]: {
      actions: [goto.dashboard],
      target: STATES.DASHBOARD,
    },
    [ACTIONS.HEADER.DASHBOARD_SELECT_PROPERTY]: {
      actions: [actions.setActivePropertyId, goto.dashboard],
      target: STATES.DASHBOARD,
    },
    [ACTIONS.HEADER.MEDIA]: {
      actions: [goto.media],
      target: STATES.MEDIA,
    },
    [ACTIONS.HEADER.GET_EDUCATED]: {
      actions: [goto.getEducated],
      target: STATES.GET_EDUCATED.MAIN,
    },
    [ACTIONS.HEADER.ACCOUNT_SETTINGS]: {
      actions: [goto.accountSettings],
      target: STATES.ACCOUNT_SETTINGS,
    },
    [ACTIONS.HEADER.REPORT_BUG]: {
      actions: [actions.ephemeral.reportBug],
    },
    [ACTIONS.HEADER.MAKE_SUGGESTION]: {
      actions: [actions.ephemeral.makeSuggestion],
    },
    [ACTIONS.HEADER.SUBSCRIBE]: {
      actions: [actions.ephemeral.subscribe],
    },
    [ACTIONS.HEADER.HELP]: {
      actions: [actions.ephemeral.help],
    },
    [ACTIONS.HEADER.HELP_CENTER]: {
      actions: [goto.helpCenter],
      target: STATES.HELP_CENTER,
    },
    [ACTIONS.HEADER.LOG_OUT]: {
      actions: [goto.loginAfterLogout],
      target: STATES.LOGIN,
    },
    [ACTIONS.HEADER.HOME]: {
      actions: [goto.home],
      target: STATES.HOME,
    },
    [ACTIONS.EARLY_ACCESS.REQUEST_EARLY_ACCESS]: {
      actions: [goto.signUp],
      target: STATES.SIGN_UP.INITIAL,
    },
    [ACTIONS.EARLY_ACCESS.PRO_MARKETING]: {
      actions: [goto.proMarketing],
      target: STATES.PRO_MARKETING,
    },
  };

  const pricesActions = {
    [ACTIONS.PRICES.UPDATE_PRICES]: {
      actions: [actions.prices.updatePrices],
    },
  };

  const CleanUpAfterUploadDeleteActions = {
    [ACTIONS.MEDIA.CLEAN_UP_AFTER_UPLOAD_DELETE]: {
      actions: [
        actions.media.removeInspirationMapping,
        actions.media.removeCoverPhotos,
        actions.media.removePropertyCardImage,
        actions.media.removePinnedPhoto,
      ],
    },
  };

  const globalActions = {
    ...CleanUpAfterUploadDeleteActions,
    [ACTIONS.GO_BACK]: {
      actions: [goto.goBack],
    },
  };

  const sowStates = {
    [STATES.HIPS.CREATE_SOW.INTRO]: {
      on: {
        [ACTIONS.HIPS.CREATE_SOW.BACK]: {
          actions: hipsNavigateActions.goToDashboard,
          target: getOrchestratorMachineTargetNode(STATES.HIPS.DASHBOARD),
        },
      },
    },
    [STATES.HIPS.CREATE_SOW.CHOOSE_PRESET]: {
      on: {
        [ACTIONS.HIPS.CREATE_SOW.OPEN_PRESET_GROUP]: {
          actions: hipsNavigateActions.goToPresetGroup,
          target: STATES.HIPS.CREATE_SOW.PRESET_GROUP,
        },
      },
    },
    [STATES.HIPS.CREATE_SOW.OVERVIEW]: {
      on: {
        [ACTIONS.HIPS.CREATE_SOW.REMOVE_ALL_TASKS]: {
          actions: [sowActions.removeAllTasks, sowActions.cleanUpActiveGuides],
        },
        [ACTIONS.HIPS.CREATE_SOW.REMOVE_ROOM_FROM_PLANING_AREA]: {
          actions: sowActions.removeRoomFromPlanningArea,
        },
        [ACTIONS.HIPS.CREATE_SOW.OPEN_HIPS_DASHBOARD]: {
          actions: hipsNavigateActions.goToDashboard,
          target: getOrchestratorMachineTargetNode(STATES.HIPS.DASHBOARD),
        },
      },
    },
    [STATES.HIPS.CREATE_SOW.PRESET_GROUP]: {
      on: {
        [ACTIONS.HIPS.CREATE_SOW.BACK]: {
          actions: hipsNavigateActions.goToSOWChooseFromPreset,
          target: STATES.HIPS.CREATE_SOW.CHOOSE_PRESET,
        },
        [ACTIONS.HIPS.CREATE_SOW.ADD_PRESET_GROUP]: {
          actions: sowActions.addPresetGroup,
        },
        [ACTIONS.HIPS.CREATE_SOW.START_OVER_PRESET_GROUP]: {
          actions: sowActions.startOverPresetGroup,
        },
      },
    },
    [STATES.HIPS.CREATE_SOW.CHOOSE_INDIVIDUALLY]: {
      on: {
        [ACTIONS.GO_BACK]: {
          actions: goto.goBack,
        },
        [ACTIONS.HIPS.CREATE_SOW.BACK]: {
          actions: hipsNavigateActions.goToProjectScope,
          target: STATES.HIPS.CREATE_SOW.INTRO,
        },
        [ACTIONS.HIPS.CREATE_SOW.OPEN_CHOOSE_SUB_CATEGORY]: {
          actions: hipsNavigateActions.goToSubCategory,
          target: STATES.HIPS.CREATE_SOW.CHOOSE_SUB_CATEGORY,
        },
        [ACTIONS.HIPS.CREATE_SOW.OPEN_CHOOSE_ROOM_INDIVIDUALLY]: {
          actions: hipsNavigateActions.goToSOWRoomChooseIndividually,
          target: STATES.HIPS.CREATE_SOW.CHOOSE_INDIVIDUALLY,
        },
        [ACTIONS.HIPS.CREATE_SOW.OPEN_HIPS_DASHBOARD]: {
          actions: hipsNavigateActions.goToDashboard,
          target: getOrchestratorMachineTargetNode(STATES.HIPS.DASHBOARD),
        },
      },
    },
    [STATES.HIPS.CREATE_SOW.CHOOSE_SUB_CATEGORY]: {
      on: {
        [ACTIONS.HIPS.CREATE_SOW.BACK]: {
          actions: hipsNavigateActions.goToSOWChooseIndividually,
          target: STATES.HIPS.CREATE_SOW.CHOOSE_INDIVIDUALLY,
        },
        [ACTIONS.HIPS.CREATE_SOW.ADD_GUIDE]: {
          actions: sowActions.addGuideToScopeOfWork,
        },
        [ACTIONS.HIPS.CREATE_SOW.ADD_MULTIPLE_GUIDES]: {
          actions: sowActions.addMultipleGuidesToScopeOfWork,
        },
        [ACTIONS.HIPS.CREATE_SOW.REMOVE_MULTIPLE_GUIDES]: {
          actions: [
            sowActions.removeMultipleGuidesFromScopeOfWork,
            sowActions.cleanUpActiveGuides,
          ],
        },
        [ACTIONS.HIPS.CREATE_SOW.REMOVE_GUIDE]: {
          actions: [
            sowActions.removeGuideFromScopeOfWork,
            sowActions.cleanUpActiveGuides,
          ],
        },
      },
    },
  };

  const states = {
    [STATES.INITIAL]: {
      // NOTE(clemens): dummy state that we use only to dispatch to other states
      //  we can remove this once we refactor xstate to own routing
      //  see https://tree.taiga.io/project/homebase-engineering/us/388
    },
    [STATES.HOME]: {
      on: {
        [ACTIONS.HOME.LOGIN]: {
          actions: [goto.login],
          target: STATES.LOGIN,
        },
        [ACTIONS.HOME.GET_EDUCATED]: {
          actions: [goto.getEducated],
          target: STATES.GET_EDUCATED.MAIN,
        },
      },
    },
    [STATES.PRO_MARKETING]: {
      on: {
        [ACTIONS.PRO_MARKETING.LOGIN]: {
          actions: [goto.login],
          target: STATES.LOGIN,
        },
        [ACTIONS.PRO_MARKETING.BUSINESS_SIGN_UP]: {
          actions: [goto.businessSignUp],
          target: STATES.BUSINESS_SIGN_UP,
        },
      },
    },
    [STATES.COMING_SOON]: {
      on: {
        [ACTIONS.COMING_SOON.BLOG]: {
          actions: [goto.getEducated],
          target: STATES.GET_EDUCATED.MAIN,
        },
        [ACTIONS.COMING_SOON.DASHBOARD]: {
          actions: [goto.dashboard],
          target: STATES.DASHBOARD,
        },
      },
    },
    [STATES.NOT_AVAILABLE_MOBILE]: {
      on: {
        [ACTIONS.NOT_AVAILABLE_MOBILE.DASHBOARD]: {
          actions: [goto.dashboard],
          target: STATES.DASHBOARD,
        },
      },
    },
    [STATES.ERROR]: {
      on: {
        [ACTIONS.ERROR.HOME]: {
          actions: [goto.root],
          target: STATES.INITIAL,
        },
      },
    },
    [STATES.NOT_FOUND]: {
      on: {
        [ACTIONS.NOT_FOUND.HOME]: {
          actions: [goto.root],
          target: STATES.INITIAL,
        },
      },
    },
    [STATES.BUSINESS_DASHBOARD]: {
      on: {
        [ACTIONS.DRAWER.DASHBOARD]: {
          actions: [goto.businessDashboard],
        },
        [ACTIONS.DRAWER.MEDIA]: {
          actions: [goto.media],
        },
        [ACTIONS.HEADER.MEDIA]: {
          actions: [goto.media],
        },
        [ACTIONS.BUSINESS_DASHBOARD.SHARED_ALBUMS]: {
          actions: [goto.mediaSharedByMe],
        },
        [ACTIONS.BUSINESS_DASHBOARD.MY_GALLERY]: {
          actions: [goto.media],
        },
        [ACTIONS.BUSINESS_DASHBOARD.SET_CLIENT_IMAGE]: {
          actions: [actions.businessDashboard.setClientImages],
        },
      },
    },
    [STATES.DASHBOARD_NO_PROPERTY]: {
      on: {
        [ACTIONS.DASHBOARD_NO_PROPERTY.ADD_PROPERTY]: {
          actions: [goto.propertyAdditionAddProperty],
          target: STATES.DASHBOARD,
        },
      },
    },
    [STATES.TERMS]: {},
    [STATES.PRIVACY]: {},
    [STATES.LOGIN]: {
      on: {
        [ACTIONS.LOGIN.SIGN_UP]: {
          actions: [goto.signUp],
          target: STATES.SIGN_UP.INITIAL,
        },
        [ACTIONS.LOGIN.RESET_PASSWORD]: {
          actions: [goto.resetPassword],
          target: STATES.RESET_PASSWORD,
        },
        [ACTIONS.LOGIN.SUCCESS]: {
          actions: [goto.root],
          target: STATES.INITIAL,
        },
      },
    },
    [STATES.BUSINESS_SIGN_UP]: {
      on: {
        [ACTIONS.BUSINESS_SIGN_UP.SET_FAKE_PROPERTY]: {
          actions: [actions.property.setSingleFakeProperty],
        },
        [ACTIONS.BUSINESS_SIGN_UP.LOGIN]: {
          actions: [goto.login],
          target: STATES.LOGIN,
        },
        [ACTIONS.BUSINESS_SIGN_UP.PRO_MARKETING]: {
          actions: [goto.proMarketing],
          target: STATES.PRO_MARKETING,
        },
      },
    },
    [STATES.SIGN_UP.INITIAL]: {
      on: {
        [ACTIONS.SIGN_UP.INITIAL.PROPERTY_ADDITION]: {
          actions: [goto.propertyAddition],
          target: STATES.PROPERTY_ADDITION.INITIAL,
        },
        [ACTIONS.SIGN_UP.INITIAL.DASHBOARD]: {
          actions: [goto.dashboard],
          target: STATES.DASHBOARD,
        },
        [ACTIONS.SIGN_UP.INITIAL.LOGIN]: {
          actions: [goto.login],
          target: STATES.LOGIN,
        },
      },
    },
    [STATES.SIGN_UP.VERIFY]: {
      on: {
        [ACTIONS.SIGN_UP.VERIFY.LOGIN]: {
          actions: [goto.login],
          target: STATES.LOGIN,
        },
        [ACTIONS.SIGN_UP.VERIFY.VERIFY_ERROR]: {
          actions: [goto.verifyError],
          target: STATES.SIGN_UP.VERIFY_ERROR,
        },
      },
    },
    [STATES.SIGN_UP.VERIFY_ERROR]: {
      on: {
        [ACTIONS.SIGN_UP.VERIFY_ERROR.HOME]: {
          actions: [goto.root],
          target: STATES.INITIAL,
        },
        [ACTIONS.SIGN_UP.VERIFY_ERROR.HELP]: {
          actions: [actions.ephemeral.help],
        },
      },
    },
    [STATES.RESET_PASSWORD]: {
      on: {
        [ACTIONS.RESET_PASSWORD.SUCCESS]: {
          actions: [goto.loginAfterPasswordReset],
          target: STATES.LOGIN,
        },
        [ACTIONS.LOGIN.SIGN_UP]: {
          actions: [goto.signUp],
          target: STATES.SIGN_UP.INITIAL,
        },
        [ACTIONS.RESET_PASSWORD.HELP]: {
          actions: [actions.ephemeral.help],
        },
        [ACTIONS.RESET_PASSWORD.RESET_PASSWORD]: {
          actions: [goto.loginAfterPasswordResetError],
          target: STATES.LOGIN,
        },
      },
    },
    [STATES.PROPERTY_ADDITION.INITIAL]: {
      on: {
        [ACTIONS.PROPERTY_ADDITION.INITIAL.LOGIN]: {
          actions: [goto.login],
          target: STATES.LOGIN,
        },
        [ACTIONS.PROPERTY_ADDITION.INITIAL.SELECT_ADDRESS]: {
          actions: [goto.propertyAdditionAddressSelect],
          target: STATES.PROPERTY_ADDITION.SELECT_ADDRESS,
        },
        [ACTIONS.PROPERTY_ADDITION.INITIAL.DASHBOARD]: {
          actions: [goto.dashboard],
          target: STATES.DASHBOARD,
        },
      },
    },
    [STATES.PROPERTY_ADDITION.SELECT_ADDRESS]: {
      on: {
        [ACTIONS.PROPERTY_ADDITION.SELECT_ADDRESS.ADDRESS_DETAILS]: {
          actions: [goto.propertyAdditionAddressDetails],
          target: STATES.PROPERTY_ADDITION.ADDRESS_DETAILS,
        },
        [ACTIONS.PROPERTY_ADDITION.INITIAL.DASHBOARD]: {
          actions: [goto.dashboard],
          target: STATES.DASHBOARD,
        },
        [ACTIONS.PROPERTY_ADDITION.SELECT_ADDRESS.LOGIN]: {
          actions: [goto.login],
          target: STATES.LOGIN,
        },
      },
    },
    [STATES.PROPERTY_ADDITION.ADDRESS_DETAILS]: {
      on: {
        [ACTIONS.PROPERTY_ADDITION.ADDRESS_DETAILS.SELECT_ADDRESS]: {
          actions: [goto.propertyAdditionAddressSelect],
          target: STATES.PROPERTY_ADDITION.SELECT_ADDRESS,
        },
        [ACTIONS.PROPERTY_ADDITION.ADDRESS_DETAILS.SET_PROPERTY]: {
          actions: actions.property.setProperty,
        },
        [ACTIONS.PROPERTY_ADDITION.ADDRESS_DETAILS.SET_ROOMS]: {
          actions: actions.property.setRoomsAndSpaces,
        },
        [ACTIONS.PROPERTY_ADDITION.ADDRESS_DETAILS.CLAIM_SUCCESS]: {
          actions: [goto.propertyAdditionAddressConfirmed],
          target: STATES.PROPERTY_ADDITION.ADDRESS_CONFIRMED,
        },
        [ACTIONS.PROPERTY_ADDITION.ADDRESS_DETAILS.CLAIM_FAILURE]: {
          actions: [goto.propertyAdditionAddressRejected],
          target: STATES.PROPERTY_ADDITION.ADDRESS_REJECTED,
        },
      },
    },
    [STATES.PROPERTY_ADDITION.ADDRESS_REJECTED]: {
      on: {
        [ACTIONS.PROPERTY_ADDITION.ADDRESS_REJECTED.BACK]: {
          actions: [goto.propertyAdditionAddressSelect],
          target: STATES.PROPERTY_ADDITION.SELECT_ADDRESS,
        },
        [ACTIONS.PROPERTY_ADDITION.ADDRESS_REJECTED.HOME]: {
          actions: [goto.root],
          target: STATES.INITIAL,
        },
      },
    },
    [STATES.PROPERTY_ADDITION.ADDRESS_CONFIRMED]: {
      on: {
        [ACTIONS.PROPERTY_ADDITION.ADDRESS_CONFIRMED.SIGN_UP]: {
          actions: [goto.signUp],
          target: STATES.SIGN_UP.INITIAL,
        },
        [ACTIONS.PROPERTY_ADDITION.ADDRESS_CONFIRMED.ROOMS_AND_SPACES]: {
          actions: [goto.propertyAdditionRoomsAndSpaces],
          target: STATES.PROPERTY_ADDITION.ROOMS_AND_SPACES,
        },
        [ACTIONS.PROPERTY_ADDITION.ADDRESS_CONFIRMED.DASHBOARD]: {
          actions: [goto.dashboard],
          target: STATES.DASHBOARD,
        },
      },
    },
    [STATES.PROPERTY_ADDITION.ROOMS_AND_SPACES]: {
      on: {
        [ACTIONS.PROPERTY_ADDITION.ROOMS_AND_SPACES.MEDIA]: {
          actions: [
            actions.property.setRoomsAndSpaces,
            goto.propertyAdditionRoomImages,
          ],
          target: STATES.PROPERTY_ADDITION.ROOM_IMAGES,
        },
      },
    },
    [STATES.PROPERTY_ADDITION.ROOM_IMAGES]: {
      on: {
        [ACTIONS.PROPERTY_ADDITION.ROOM_IMAGES.ROOMS_AND_SPACES]: {
          actions: [goto.propertyAdditionRoomsAndSpaces],
          target: STATES.PROPERTY_ADDITION.ROOMS_AND_SPACES,
        },
        [ACTIONS.PROPERTY_ADDITION.ROOM_IMAGES.DASHBOARD]: {
          actions: [goto.dashboard],
          target: STATES.DASHBOARD,
        },
        [ACTIONS.PROPERTY_ADDITION.ROOM_IMAGES.HIPS_DASHBOARD]: {
          actions: [goto.hipDashboard],
          target: STATES.HIPS.DASHBOARD,
        },
        [ACTIONS.PROPERTY_ADDITION.ROOM_IMAGES.HIPS_PLANNING_AREA]: {
          actions: [goto.hipPlanningAreas],
          target: STATES.HIPS.PLANNING_AREAS,
        },
        [ACTIONS.PROPERTY_ADDITION.ROOM_IMAGES.SET_HAS_FINISHED_ONBOARDING]: {
          actions: actions.dashboard.setHasFinishedOnboarding,
        },
        [ACTIONS.PROPERTY_ADDITION.ROOM_IMAGES.SET_COVER_PHOTOS]: {
          actions: actions.media.setCoverPhotos,
        },
        [ACTIONS.PROPERTY_ADDITION.ROOM_IMAGES.REMOVE_COVER_PHOTOS]: {
          actions: actions.media.removeCoverPhotos,
        },
        [ACTIONS.PROPERTY_ADDITION.ROOM_IMAGES.REMOVE_COVER_PHOTOS_FOR_ROOM]: {
          actions: actions.media.removeCoverPhotosForRoom,
        },
      },
    },
    [STATES.MEDIA]: {
      on: {
        [ACTIONS.MEDIA.SET_COVER_PHOTOS]: {
          actions: actions.media.setCoverPhotos,
        },
        [ACTIONS.EDIT_ROOMS_AND_SPACES.SAVE_CHANGES]: {
          actions: [actions.editRoomsAndSpaces.saveChanges],
        },
        ...mediaHelperTextActions,
        ...mediaNavigationActions,
        ...CleanUpAfterUploadDeleteActions,
      },
    },
    [STATES.MEDIA_ALBUMS]: {
      on: {
        [ACTIONS.MEDIA.SET_COVER_PHOTOS]: {
          actions: actions.media.setCoverPhotos,
        },
        [ACTIONS.MEDIA.SET_HAS_SEEN_MY_ALBUMS_SIDEBAR]: {
          actions: actions.media.setHasSeenMyAlbumsSidebar,
        },
        ...mediaHelperTextActions,
        ...mediaNavigationActions,
        ...CleanUpAfterUploadDeleteActions,
      },
    },
    [STATES.MEDIA_ALBUMS_SHARED_WITH_ME]: {
      on: {
        ...mediaNavigationActions,
        [ACTIONS.MEDIA.SET_HAS_SEEN_MY_ALBUMS_SIDEBAR]: {
          actions: actions.media.setHasSeenMyAlbumsSidebar,
        },
        [ACTIONS.MEDIA.OPEN_ALBUM]: {
          actions: goto.mediaSharedWithMe,
        },
        [ACTIONS.MEDIA.SAVE_INSPIRATION]: {
          actions: actions.media.saveInspirationMapping,
        },
        ...mediaHelperTextActions,
        ...CleanUpAfterUploadDeleteActions,
      },
    },
    [STATES.MEDIA_ALBUMS_SHARED_BY_ME]: {
      on: {
        ...mediaNavigationActions,
        [ACTIONS.MEDIA.SET_HAS_SEEN_MY_ALBUMS_SIDEBAR]: {
          actions: actions.media.setHasSeenMyAlbumsSidebar,
        },
        [ACTIONS.MEDIA.OPEN_ALBUM]: {
          actions: goto.mediaSharedByMe,
        },
        ...mediaHelperTextActions,
        ...CleanUpAfterUploadDeleteActions,
      },
    },
    [STATES.MEDIA_ALBUMS_INACTIVE]: {
      on: {
        ...mediaNavigationActions,
        [ACTIONS.MEDIA.SET_HAS_SEEN_MY_ALBUMS_SIDEBAR]: {
          actions: actions.media.setHasSeenMyAlbumsSidebar,
        },
        [ACTIONS.MEDIA.SAVE_INSPIRATION]: {
          actions: actions.media.saveInspirationMapping,
        },
        [ACTIONS.MEDIA.OPEN_ALBUM]: {
          actions: goto.mediaSharedInactive,
        },
        ...mediaHelperTextActions,
        ...CleanUpAfterUploadDeleteActions,
      },
    },
    [STATES.GET_INSPIRED]: {
      on: {
        [ACTIONS.MEDIA.SAVE_INSPIRATION]: {
          actions: actions.media.saveInspirationMapping,
        },
        [ACTIONS.MEDIA.GO_TO_INSPIRED_CATEGORY_RESULTS]: {
          actions: goto.getInspiredCategoryResults,
          target: STATES.GET_INSPIRED_CATEGORY_RESULTS,
        },
        ...mediaNavigationActions,
        ...CleanUpAfterUploadDeleteActions,
      },
    },
    [STATES.GET_INSPIRED_CATEGORY]: {
      on: {
        [ACTIONS.GO_BACK]: {
          actions: goto.goBack,
        },
        [ACTIONS.MEDIA.SAVE_INSPIRATION]: {
          actions: actions.media.saveInspirationMapping,
        },
        [ACTIONS.MEDIA.GO_TO_INSPIRED_CATEGORY]: {
          actions: goto.getInspiredCategory,
          target: STATES.GET_INSPIRED_CATEGORY,
        },
        [ACTIONS.MEDIA.GO_TO_INSPIRED_CATEGORY_RESULTS]: {
          actions: goto.getInspiredCategoryResults,
          target: STATES.GET_INSPIRED_CATEGORY_RESULTS,
        },
        ...mediaNavigationActions,
        ...CleanUpAfterUploadDeleteActions,
      },
    },
    [STATES.GET_INSPIRED_CATEGORY_RESULTS]: {
      on: {
        [ACTIONS.MEDIA.GO_TO_ALBUMS]: {
          actions: goto.mediaAlbums,
          target: STATES.MEDIA_ALBUMS,
        },
        [ACTIONS.MEDIA.GO_TO_PHOTOS]: {
          actions: goto.media,
          target: STATES.MEDIA,
        },
        [ACTIONS.GET_EDUCATED.GO_TO_MAIN]: {
          actions: goto.getEducated,
          target: STATES.GET_EDUCATED.MAIN,
        },
        [ACTIONS.GET_EDUCATED.GO_TO_PROJECT_GUIDE]: {
          actions: goto.getEducatedProjectGuide,
          target: STATES.GET_EDUCATED.PROJECT_GUIDE,
        },
        [ACTIONS.GET_EDUCATED.GO_TO_MY_STUFF]: {
          actions: goto.getEducatedMyStuff,
          target: STATES.GET_EDUCATED.MY_STUFF,
        },
        [ACTIONS.GO_BACK]: {
          actions: goto.goBack,
        },
        [ACTIONS.MEDIA.SAVE_INSPIRATION]: {
          actions: actions.media.saveInspirationMapping,
        },
        [ACTIONS.MEDIA.GO_TO_INSPIRED_CATEGORY]: {
          actions: goto.getInspiredCategory,
          target: STATES.GET_INSPIRED_CATEGORY,
        },
        ...CleanUpAfterUploadDeleteActions,
      },
    },
    [STATES.GET_EDUCATED.MAIN]: {
      on: {
        [ACTIONS.GET_EDUCATED.GO_TO_MAIN]: {
          actions: goto.getEducated,
        },
        [ACTIONS.GET_EDUCATED.GO_TO_MY_STUFF]: {
          actions: goto.getEducatedMyStuff,
          target: STATES.GET_EDUCATED.MY_STUFF,
        },
        [ACTIONS.GET_EDUCATED.GO_TO_PROJECT_GUIDE]: {
          actions: goto.getEducatedProjectGuide,
          target: STATES.GET_EDUCATED.PROJECT_GUIDE,
        },
        [ACTIONS.GET_EDUCATED.GO_TO_PROJECT_GUIDE_POST]: {
          actions: goto.getEducatedProjectGuidePost,
          target: STATES.GET_EDUCATED.PROJECT_GUIDE_POST,
        },
        [ACTIONS.GET_EDUCATED.SUBSCRIBE]: {
          actions: [actions.ephemeral.subscribe],
        },
        ...orchestratorGetEducatedActions,
      },
    },
    [STATES.GET_EDUCATED.PROJECT_GUIDE_POST]: {
      on: {
        [ACTIONS.GO_BACK]: {
          actions: goto.goBack,
        },
        [ACTIONS.MEDIA.GO_TO_PHOTOS]: {
          actions: goto.media,
          target: STATES.MEDIA,
        },
        [ACTIONS.GET_EDUCATED.GO_TO_MAIN]: {
          actions: goto.getEducated,
        },
        [ACTIONS.GET_EDUCATED.GO_TO_MY_STUFF]: {
          actions: goto.getEducatedMyStuff,
          target: STATES.GET_EDUCATED.MY_STUFF,
        },
        [ACTIONS.GET_EDUCATED.GO_TO_PROJECT_GUIDE]: {
          actions: goto.getEducatedProjectGuide,
          target: STATES.GET_EDUCATED.PROJECT_GUIDE,
        },
        [ACTIONS.GET_EDUCATED.GO_TO_PROJECT_GUIDE_POST]: {
          actions: goto.getEducatedProjectGuidePost,
          target: STATES.GET_EDUCATED.PROJECT_GUIDE_POST,
        },
        [ACTIONS.MEDIA.SAVE_INSPIRATION]: {
          actions: actions.media.saveInspirationMapping,
        },
        ...CleanUpAfterUploadDeleteActions,
        ...orchestratorGetEducatedActions,
      },
    },
    [STATES.GET_EDUCATED.PROJECT_GUIDE]: {
      on: {
        [ACTIONS.GET_EDUCATED.GO_TO_MAIN]: {
          actions: goto.getEducated,
        },
        [ACTIONS.GET_EDUCATED.GO_TO_MY_STUFF]: {
          actions: goto.getEducatedMyStuff,
          target: STATES.GET_EDUCATED.MY_STUFF,
        },
        [ACTIONS.GET_EDUCATED.GO_TO_PROJECT_GUIDE]: {
          actions: goto.getEducatedProjectGuide,
          target: STATES.GET_EDUCATED.PROJECT_GUIDE,
        },
        [ACTIONS.GET_EDUCATED.GO_TO_PROJECT_GUIDE_POST]: {
          actions: goto.getEducatedProjectGuidePost,
          target: STATES.GET_EDUCATED.PROJECT_GUIDE_POST,
        },
        [ACTIONS.GET_EDUCATED.GO_TO_INSPIRATION]: {
          actions: goto.getEducatedProjectGuideInspiration,
          target: STATES.GET_INSPIRED_CATEGORY_RESULTS,
        },
        [ACTIONS.GO_BACK]: {
          actions: goto.goBack,
        },
        ...orchestratorGetEducatedActions,
      },
    },
    [STATES.GET_EDUCATED.MY_STUFF]: {
      on: {
        [ACTIONS.GO_BACK]: {
          actions: goto.goBack,
        },
        [ACTIONS.GET_EDUCATED.GO_TO_MAIN]: {
          actions: goto.getEducated,
        },
        [ACTIONS.GET_EDUCATED.GO_TO_MY_STUFF]: {
          actions: goto.getEducatedMyStuff,
          target: STATES.GET_EDUCATED.MY_STUFF,
        },
        [ACTIONS.GET_EDUCATED.GO_TO_PROJECT_GUIDE]: {
          actions: goto.getEducatedProjectGuide,
          target: STATES.GET_EDUCATED.PROJECT_GUIDE,
        },
        [ACTIONS.GET_EDUCATED.GO_TO_PROJECT_GUIDE_POST]: {
          actions: goto.getEducatedProjectGuidePost,
          target: STATES.GET_EDUCATED.PROJECT_GUIDE_POST,
        },
        ...orchestratorGetEducatedActions,
      },
    },
    [STATES.PROPERTY_TRANSFER]: {
      on: {
        [ACTIONS.PROPERTY_TRANSFER.DASHBOARD]: {
          actions: [actions.setActivePropertyId, goto.dashboard],
          target: STATES.DASHBOARD,
        },
      },
    },
    [STATES.DASHBOARD]: {
      on: {
        [ACTIONS.DASHBOARD.MEDIA]: {
          actions: [goto.media],
          target: STATES.MEDIA,
        },
        [ACTIONS.MEDIA.SET_HIPS_IMAGE]: {
          actions: actions.media.setHipsCardImage,
        },
        [ACTIONS.MEDIA.GO_TO_SHARED_WITH_ME]: {
          actions: goto.mediaSharedWithMe,
          target: STATES.MEDIA_ALBUMS_SHARED_WITH_ME,
        },
        [ACTIONS.MEDIA.GO_TO_SHARED_WITH_ME]: {
          actions: goto.mediaSharedWithMe,
          target: STATES.MEDIA_ALBUMS_SHARED_WITH_ME,
        },
        [ACTIONS.DASHBOARD.ADD_PROPERTY]: {
          actions: [goto.propertyAdditionAddProperty],
        },
        [ACTIONS.PROPERTY.SET_PROPERTY_UNLOCKED]: {
          actions: [actions.property.setPropertyUnlocked],
        },
        [ACTIONS.MEDIA.SET_PROPERTY_IMAGE]: {
          actions: actions.media.setPropertyCardImage,
        },
        [ACTIONS.DASHBOARD.HIPS.CREATE_HIPS]: {
          actions: actions.dashboard.hips.createHips,
          target: STATES.HIPS.ONBOARDING,
        },
        [ACTIONS.DASHBOARD.SEE_IN_MY_GALLERY]: {
          actions: actions.dashboard.seeInMyGallery,
          target: STATES.MEDIA,
        },
        [ACTIONS.DASHBOARD.HIPS.OPEN_ROOM]: {
          actions: actions.dashboard.hips.openRoom,
          target: STATES.HIPS.ROOM.DEFAULT,
        },
        [ACTIONS.DASHBOARD.HIPS.OPEN_HIPS]: {
          actions: actions.dashboard.hips.openHips,
          target: STATES.HIPS.DASHBOARD,
        },
        [ACTIONS.DASHBOARD.HIPS.REMOVE_HIPS]: {
          actions: actions.dashboard.hips.removeItem,
        },
        [ACTIONS.PROPERTY_ADDITION.ADDRESS_DETAILS.SET_PROPERTY_FIELD]: {
          actions: actions.property.setPropertyField,
        },
        [ACTIONS.EDIT_ROOMS_AND_SPACES.SAVE_CHANGES]: {
          actions: [actions.editRoomsAndSpaces.saveChanges],
        },
        [ACTIONS.EDIT_ROOMS_AND_SPACES.REMOVE_COVER_PHOTOS_FOR_ROOM]: {
          actions: actions.media.removeCoverPhotosForRoom,
        },
        [ACTIONS.DASHBOARD.SET_HAS_FINISHED_ONBOARDING]: {
          actions: actions.dashboard.setHasFinishedOnboarding,
        },
        [ACTIONS.DASHBOARD.PROPERTY_TRANSFER_SUCCESS]: {
          actions: [actions.setActivePropertyId],
        },
        ...mediaHelperTextActions,
      },
    },
    [STATES.ACCOUNT_SETTINGS]: {
      on: {
        [ACTIONS.ACCOUNT_SETTINGS.BACK]: {
          actions: [goto.dashboard],
          target: STATES.DASHBOARD,
        },
        [ACTIONS.ACCOUNT_SETTINGS.OPEN_PROPERTY_DASHBOARD]: {
          actions: [actions.property.selectProperty, goto.dashboard],
          target: STATES.DASHBOARD,
        },
        [ACTIONS.ACCOUNT_SETTINGS.ADD_PROPERTY]: {
          actions: [goto.propertyAdditionAddProperty],
          target: STATES.DASHBOARD,
        },
        [ACTIONS.PROPERTY.SET_PROPERTY_UNLOCKED]: {
          actions: [actions.property.setPropertyUnlocked],
        },
        [ACTIONS.PROPERTY.SET_PROPERTY_LOCKED]: {
          actions: [actions.property.setPropertyLocked],
        },
        [ACTIONS.ACCOUNT_SETTINGS.REMOVE_PROPERTY]: {
          actions: [actions.property.removeProperty],
        },
        [ACTIONS.ACCOUNT_SETTINGS.INITIAL]: {
          actions: [goto.rootAfterLogout],
          target: STATES.INITIAL,
        },
      },
    },
    [STATES.HELP_CENTER]: {
      on: {
        [ACTIONS.HELP.BACK]: {
          actions: [goto.dashboard],
          target: STATES.DASHBOARD,
        },
      },
    },
    [STATES.HIPS.ONBOARDING]: {
      on: {
        [ACTIONS.HIPS.ONBOARDING.DASHBOARD]: {
          actions: actions.hips.onboarding.dashboard,
          target: STATES.HIPS.DASHBOARD,
        },
      },
    },
    [STATES.HIPS.DASHBOARD]: {
      on: {
        [ACTIONS.HIPS.DASHBOARD.START_PLANNING]: {
          actions: hipsNavigateActions.goToPlaningArea,
          target: STATES.HIPS.PLANNING_AREAS,
        },
        [ACTIONS.HIPS.DASHBOARD.START_SCOPE_OF_WORK]: {
          actions: hipsNavigateActions.goToProjectScope,
          target: STATES.HIPS.CREATE_SOW.BASE,
        },
        [ACTIONS.HIPS.DASHBOARD.START_GUIDES]: {
          actions: hipsActions.goToRoomPage,
          target: STATES.HIPS.CREATE_SOW.BASE,
        },
        [ACTIONS.HIPS.DASHBOARD.CONTINUE_GUIDES]: [
          {
            actions: hipsActions.continueGuide,
            target: STATES.HIPS.CREATE_SOW.BASE,
            cond: hipsConditions.hasActiveGuide,
          },
          {
            actions: hipsActions.goToRoomPage,
            target: STATES.HIPS.CREATE_SOW.BASE,
          },
        ],
        [ACTIONS.HIPS.DASHBOARD.SCOPE_OF_WORK_OVERVIEW]: {
          actions: hipsNavigateActions.goToOverviewPage,
          target: STATES.HIPS.CREATE_SOW.BASE,
        },
        [ACTIONS.HIPS.DASHBOARD.FINISH_CONTINUE_GUIDES]: {
          actions: hipsNavigateActions.goToPlanOutputPage,
          target: STATES.HIPS.ROOM.PLAN_DETAILS_OUTPUT,
        },
        [ACTIONS.HIPS.DASHBOARD.OPEN_ROOM]: {
          actions: hipsNavigateActions.goToRoomPage,
        },
        [ACTIONS.HIPS.DASHBOARD.OPEN_MEDIA_PAGE]: {
          actions: [goto.media],
          target: STATES.MEDIA,
        },
        [ACTIONS.HIPS.DASHBOARD.OPEN_BUDGET_PAGE]: {
          actions: [goto.hipBudget],
          target: STATES.HIPS.BUDGET,
        },
        [ACTIONS.HIPS.DASHBOARD.SET_TITLE]: {
          actions: actions.hips.setTitle,
        },
        [ACTIONS.HIPS.DASHBOARD.SET_BUDGET]: {
          actions: actions.hips.setBudget,
        },
        [ACTIONS.HIPS.DASHBOARD.SET_RESERVE]: {
          actions: actions.hips.setReserve,
        },
        [ACTIONS.HIPS.DASHBOARD.SET_HAS_SEEN_HIP_TOUR]: {
          actions: actions.hips.setHasSeenHipTour,
        },
        [ACTIONS.MEDIA.SET_COVER_PHOTOS]: {
          actions: actions.media.setCoverPhotos,
        },
        [ACTIONS.HIPS.PLANNING_AREAS.REMOVE_ROOM_FROM_PLANNING_AREA]: {
          actions: hipsActions.removeRoomFromPlanningArea,
        },
        [ACTIONS.HIPS.DASHBOARD.OPEN_INDIVIDUAL_CATEGORY]: {
          actions: hipsNavigateActions.goToSOWChooseIndividually,
          target: STATES.HIPS.CREATE_SOW.BASE,
        },
        [ACTIONS.HIPS.DASHBOARD.OPEN_DASHBOARD]: {
          actions: hipsNavigateActions.goRootDashboard,
          target: STATES.DASHBOARD,
        },
        ...mediaHelperTextActions,
      },
    },
    [STATES.HIPS.PLANNING_AREAS]: {
      on: {
        [ACTIONS.HIPS.PLANNING_AREAS.BACK]: {
          actions: hipsNavigateActions.goToDashboard,
          target: STATES.HIPS.DASHBOARD,
        },
        [ACTIONS.HIPS.PLANNING_AREAS.GO_ROOM_IMAGES]: {
          actions: [goto.hipsPropertyAdditionRoomImages],
          target: STATES.PROPERTY_ADDITION.ROOM_IMAGES,
        },
        [ACTIONS.HIPS.PLANNING_AREAS.UPDATE_ROOMS]: {
          actions: hipsActions.updateRooms,
          target: STATES.HIPS.PLANNING_AREAS,
        },
        [ACTIONS.HIPS.PLANNING_AREAS.ADD_ROOM_TO_PLANNING_AREA]: {
          actions: hipsActions.addRoomToPlanningArea,
          target: STATES.HIPS.PLANNING_AREAS,
        },
        [ACTIONS.HIPS.PLANNING_AREAS.REMOVE_ROOM_FROM_PLANNING_AREA]: {
          actions: hipsActions.removeRoomFromPlanningArea,
        },
        [ACTIONS.HIPS.PLANNING_AREAS.ADD_ALL_ROOM_TO_PLANNING_AREA]: {
          actions: hipsActions.addAllRoomsToPlanningArea,
        },
        [ACTIONS.HIPS.PLANNING_AREAS.ADD_FILTERED_ROOMS_TO_PLANNING_AREA]: {
          actions: hipsActions.addFilteredRoomsToPlanningArea,
        },
        [ACTIONS.HIPS.PLANNING_AREAS.REMOVE_ALL_ROOM_FROM_PLANNING_AREA]: {
          actions: hipsActions.removeAllRoomsFromPlanningArea,
        },
        [ACTIONS.HIPS.PLANNING_AREAS.DONE]: {
          actions: [hipsActions.cleanUpGuides, goto.goBack],
        },
        [ACTIONS.EDIT_ROOMS_AND_SPACES.SAVE_CHANGES]: {
          actions: [actions.editRoomsAndSpaces.saveChanges],
        },
      },
    },
    [STATES.HIPS.BUDGET]: {
      on: {
        [ACTIONS.HIPS.BUDGET.BACK]: {
          actions: hipsNavigateActions.goToDashboard,
          target: STATES.HIPS.DASHBOARD,
        },
        [ACTIONS.HIPS.BUDGET.SET_BUDGET]: {
          actions: actions.hips.setBudget,
        },
        [ACTIONS.HIPS.BUDGET.SET_RESERVE]: {
          actions: actions.hips.setReserve,
        },
      },
    },
    [STATES.HIPS.ROOM.DEFAULT]: {
      on: {
        [ACTIONS.GUIDES.GUIDE.SET_IS_MEASUREMENTS_SHOWN]: {
          actions: actions.guides.guide.setIsMeasurementsShown,
        },
        [ACTIONS.HIPS.ROOM.DASHBOARD]: {
          actions: hipsNavigateActions.goToDashboard,
        },
        [ACTIONS.HIPS.ROOM.GUIDE]: {
          actions: hipsNavigateActions.goToGuidePage,
        },
        [ACTIONS.HIPS.ROOM.NAVIGATE_ROOM]: {
          actions: hipsNavigateActions.goToRoomPage,
          target: STATES.HIPS.ROOM.DEFAULT,
        },
        [ACTIONS.HIPS.ROOM.NAVIGATE_PLAN_DETAILS]: {
          actions: hipsNavigateActions.goToPlanOutputPage,
          target: STATES.HIPS.ROOM.PLAN_DETAILS_OUTPUT,
        },
        [ACTIONS.HIPS.ROOM.OPEN_BUDGET_PAGE]: {
          actions: [goto.hipBudget],
          target: STATES.HIPS.BUDGET,
        },
        [ACTIONS.HIPS.ROOM.OPEN_SCOPE_OF_WORK]: {
          actions: roomNavigateActions.goToProjectScope,
          target: STATES.HIPS.CREATE_SOW.BASE,
        },
        [ACTIONS.HIPS.ROOM.OPEN_SOW_OVERVIEW]: {
          actions: roomNavigateActions.goToOverviewPage,
          target: STATES.HIPS.CREATE_SOW.BASE,
        },
        [ACTIONS.HIPS.ROOM.OPEN_TASKS]: {
          actions: roomNavigateActions.goToSOWRoomChooseIndividually,
          target: STATES.HIPS.CREATE_SOW.BASE,
        },
        [ACTIONS.HIPS.ROOM.OPEN_TASKS_CATEGORY]: {
          actions: roomNavigateActions.goToSubCategory,
          target: STATES.HIPS.CREATE_SOW.BASE,
        },
        [ACTIONS.HIPS.ROOM.OPEN_ROOM_TASKS_CATEGORY]: {
          actions: roomNavigateActions.goToRoomSubCategory,
          target: STATES.HIPS.CREATE_SOW.BASE,
        },
        [ACTIONS.HIPS.ROOM.REMOVE_GUIDE_CATEGORY]: {
          actions: sowActions.removeGuideCategory,
        },
        [ACTIONS.MEDIA.SET_COVER_PHOTOS]: {
          actions: actions.media.setCoverPhotos,
        },
        ...CleanUpAfterUploadDeleteActions,
        [ACTIONS.HIPS.CREATE_SOW.ADD_GUIDE]: {
          actions: [
            sowActions.addGuideToScopeOfWork,
            hipsNavigateActions.goToCreateRoomLayout,
          ],
        },
      },
    },
    [STATES.HIPS.ROOM.PLAN_DETAILS]: {
      on: {
        [ACTIONS.HIPS.ROOM.DASHBOARD]: {
          actions: hipsNavigateActions.goToDashboard,
          target: STATES.HIPS.DASHBOARD,
        },
      },
    },
    [STATES.HIPS.ROOM.PLAN_DETAILS_OUTPUT]: {
      on: {
        [ACTIONS.HIPS.ROOM.DASHBOARD]: {
          actions: hipsNavigateActions.goToDashboard,
          target: STATES.HIPS.DASHBOARD,
        },
        [ACTIONS.HIPS.PLAN_DETAILS_OUTPUT.ADD_NOTE]: {
          actions: hipsActions.addHIPsOutputNote,
        },
        [ACTIONS.GUIDES.GUIDE.SET_IS_MEASUREMENTS_SHOWN]: {
          actions: actions.guides.guide.setIsMeasurementsShown,
        },
      },
    },
    [STATES.SHARED_PLAN]: {
      on: {
        [ACTIONS.SHARED_PLAN.GOTO_PROFESSIONALS]: {
          actions: goto.proMarketing,
          target: STATES.HIPS.DASHBOARD,
        },
        [ACTIONS.SHARED_PLAN.GOTO_HOME]: {
          actions: goto.home,
          target: STATES.HOME,
        },
        [ACTIONS.SHARED_PLAN.GOTO_LOGIN]: {
          actions: goto.login,
          target: STATES.LOGIN,
        },
        [ACTIONS.SHARED_PLAN.GOTO_SIGNUP]: {
          actions: goto.signUp,
          target: STATES.SIGN_UP.INITIAL,
        },
      },
    },
    [STATES.SHARED_ROOM_PLAN]: {
      on: {
        [ACTIONS.SHARED_PLAN.GOTO_PROFESSIONALS]: {
          actions: goto.proMarketing,
          target: STATES.HIPS.DASHBOARD,
        },
        [ACTIONS.SHARED_PLAN.GOTO_HOME]: {
          actions: goto.home,
          target: STATES.HOME,
        },
        [ACTIONS.SHARED_PLAN.GOTO_LOGIN]: {
          actions: goto.login,
          target: STATES.LOGIN,
        },
        [ACTIONS.SHARED_PLAN.GOTO_SIGNUP]: {
          actions: goto.signUp,
          target: STATES.SIGN_UP.INITIAL,
        },
      },
    },
    [STATES.HIPS.CREATE_SOW.BASE]: {
      initial: STATES.HIPS.CREATE_SOW.INTRO,
      states: sowStates,
      on: {
        [ACTIONS.HIPS.CREATE_SOW.OPEN_CHOOSE_FROM_PRESET]: {
          actions: hipsNavigateActions.goToSOWChooseFromPreset,
          target: getCurrentMachineTargetNode(
            STATES.HIPS.CREATE_SOW.CHOOSE_PRESET
          ),
        },
        [ACTIONS.HIPS.CREATE_SOW.OPEN_CHOOSE_INDIVIDUALLY]: {
          actions: hipsNavigateActions.goToSOWChooseIndividually,
          target: getCurrentMachineTargetNode(
            STATES.HIPS.CREATE_SOW.CHOOSE_INDIVIDUALLY
          ),
        },
        [ACTIONS.HIPS.CREATE_SOW.OPEN_CHOOSE_ROOM_INDIVIDUALLY]: {
          actions: hipsNavigateActions.goToSOWRoomChooseIndividually,
          target: getCurrentMachineTargetNode(
            STATES.HIPS.CREATE_SOW.CHOOSE_INDIVIDUALLY
          ),
        },
        [ACTIONS.HIPS.CREATE_SOW.OPEN_PLANNING_AREAS]: {
          actions: hipsNavigateActions.goToPlaningArea,
          target: getOrchestratorMachineTargetNode(STATES.HIPS.PLANNING_AREAS),
        },
        [ACTIONS.HIPS.CREATE_SOW.REMOVE_INTENT]: {
          actions: [sowActions.removeIntent, sowActions.cleanUpActiveGuides],
        },
        [ACTIONS.HIPS.CREATE_SOW.REMOVE_GUIDE_CATEGORY]: {
          actions: [
            sowActions.removeGuideCategory,
            sowActions.cleanUpActiveGuides,
          ],
        },
        [ACTIONS.HIPS.CREATE_SOW.OPEN_OVERVIEW]: {
          actions: hipsNavigateActions.goToOverviewPage,
          target: getCurrentMachineTargetNode(STATES.HIPS.CREATE_SOW.OVERVIEW),
        },
        [ACTIONS.HIPS.CREATE_SOW.OPEN_SOW_ROOM_PAGE]: {
          actions: hipsNavigateActions.goToRoomPage,
          target: STATES.HIPS.CREATE_SOW.BASE,
        },
        [ACTIONS.HIPS.CREATE_SOW.OPEN_SOW_PAGE]: {
          actions: hipsNavigateActions.goToProjectScope,
          target: getCurrentMachineTargetNode(STATES.HIPS.CREATE_SOW.INTRO),
        },
        [ACTIONS.HIPS.CREATE_SOW.OPEN_CHOOSE_ROOM_SUB_CATEGORY]: {
          actions: hipsNavigateActions.goToRoomSubCategory,
          target: getCurrentMachineTargetNode(
            STATES.HIPS.CREATE_SOW.CHOOSE_SUB_CATEGORY
          ),
        },
        [ACTIONS.SET_STATE]: Object.keys(sowStates).map((state) => {
          return {
            target: getCurrentMachineTargetNode(state),
            cond: (_, event) => event.data.state === state,
          };
        }),
      },
    },
    [STATES.GUIDES.GUIDE]: {
      on: {
        [ACTIONS.GUIDES.GUIDE.SAVE_PROGRESS]: {
          actions: actions.guides.guide.saveProgress,
        },
        [ACTIONS.GUIDES.GUIDE.DONE]: {
          actions: actions.guides.guide.done,
        },
        [ACTIONS.GUIDES.GUIDE.EXIT]: {
          actions: [goto.hipDashboard],
        },
        [ACTIONS.MEDIA.SAVE_INSPIRATION]: {
          actions: actions.media.saveInspirationMapping,
        },
        [ACTIONS.GUIDES.GUIDE.SAVE_PINNED_PHOTO]: {
          actions: actions.guides.guide.savePinnedPhoto,
        },
        [ACTIONS.GUIDES.GUIDE.REMOVE_PINNED_PHOTO]: {
          actions: actions.guides.guide.removePinnedPhoto,
        },
        [ACTIONS.HIPS.ROOM.NAVIGATE_ROOM]: {
          actions: hipsNavigateActions.goToRoomPage,
        },
        [ACTIONS.HIPS.DASHBOARD.START_SCOPE_OF_WORK]: {
          actions: hipsNavigateActions.goToProjectScope,
        },
        [ACTIONS.HIPS.DASHBOARD.START_GUIDES]: {
          actions: hipsActions.startGuides,
        },
        [ACTIONS.GUIDES.GUIDE.ADD_ROOMS]: {
          actions: actions.guides.guide.addRooms,
        },
        [ACTIONS.GUIDES.GUIDE.SET_HAS_SEEN_GUIDE_CTA]: {
          actions: actions.guides.guide.setHasSeenGuideCTA,
        },
        [ACTIONS.GUIDES.GUIDE.SET_IS_MEASUREMENTS_SHOWN]: {
          actions: actions.guides.guide.setIsMeasurementsShown,
        },
        [ACTIONS.GUIDES.GUIDE.SET_IS_GUIDE_BUDGET_BLOCK_OPEN]: {
          actions: actions.guides.guide.setIsBudgetBlockOpen,
        },
        ...CleanUpAfterUploadDeleteActions,
      },
    },
  };

  return createMachine<OrchestratorMachineContext>(
    {
      predictableActionArguments: true,
      id: ORCHESTRATOR_MACHINE_ID,
      initial: STATES.PROPERTY_ADDITION.INITIAL,
      context: getOrchestratorMachineContext(),
      states,
      on: {
        [ACTIONS.RESTORE_STATE]: {
          actions: assign(
            (
              ctx: OrchestratorMachineContext,
              event: StateActionEvent<OrchestratorMachineContext>
            ) => {
              const machineContext = createRestoreOrchestratorMachineContext(
                event.data,
                ctx.state
              );

              Object.assign(ctx, machineContext);
            }
          ),
        },
        [ACTIONS.SET_STATE]: Object.keys(states).map((state) => {
          return {
            target: state,
            cond: (_, event) => event.data.state === state,
          };
        }),
        ...drawerActions,
        ...headerActions,
        ...footerActions,
        ...pricesActions,
        ...sidebarActions,
        ...anyModalActions,
        ...globalActions,
      },
    },
    {}
  );
};
