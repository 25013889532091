import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";

import { LightingControlsConfigFloorPlan } from "./config.floorPlan";
import { LightingControlsConfigImages } from "./config.images";
import { LightingControlsConfigTexts } from "./config.texts";
import { LightingControlTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.LIGHTING_CONTROL,
  LightingControlTypes
>;

const types: Config["types"] = [
  LightingControlTypes.DIMMER,
  LightingControlTypes.SWITCH,
  LightingControlTypes.CONTROL_PANEL,
  LightingControlTypes.SENSOR,
  // Note(Adam): Pull chords are not a real guide item
  LightingControlTypes.PULL_CHORD,
];

export const LightingControlsConfig: Config = {
  guideType: GuideType.ELECTRICAL,
  category: GuideItemTypeCategory.LIGHTING_CONTROL,
  types,
  typesOrder: getOrderedMap(types),
  texts: LightingControlsConfigTexts,
  images: LightingControlsConfigImages,
  floorPlan: LightingControlsConfigFloorPlan,
  allowedInRooms: undefined,
  allowedIntents: undefined,
} as const;
