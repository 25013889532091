import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import brickFallback from "./assets/brick.png";
import brick from "./assets/brick.webp";
import carpetFallback from "./assets/carpet.png";
import carpet from "./assets/carpet.webp";
import concreteFallback from "./assets/concrete.png";
import concrete from "./assets/concrete.webp";
import { ReactComponent as InteriorFloorIcon } from "./assets/interior_floor_icon.svg";
import mainImageFallback from "./assets/interior_floor_main.png";
import mainImage from "./assets/interior_floor_main.webp";
import tileFallback from "./assets/tile.png";
import tile from "./assets/tile.webp";
import vinylFallback from "./assets/vinyl.png";
import vinyl from "./assets/vinyl.webp";
import woodFallback from "./assets/wood.png";
import wood from "./assets/wood.webp";
import { InteriorFloorMaterialTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL,
  InteriorFloorMaterialTypes
>["images"];

const images: Config["images"] = {
  [InteriorFloorMaterialTypes.CONCRETE]: {
    src: concrete,
    fallbackSrc: concreteFallback,
  },
  [InteriorFloorMaterialTypes.WOOD]: { src: wood, fallbackSrc: woodFallback },
  [InteriorFloorMaterialTypes.VINYL]: {
    src: vinyl,
    fallbackSrc: vinylFallback,
  },
  [InteriorFloorMaterialTypes.BRICK]: {
    src: brick,
    fallbackSrc: brickFallback,
  },
  [InteriorFloorMaterialTypes.TILE]: { src: tile, fallbackSrc: tileFallback },
  [InteriorFloorMaterialTypes.CARPET]: {
    src: carpet,
    fallbackSrc: carpetFallback,
  },
};

export const InteriorFloorMaterialsConfigImages: Config = {
  categoryIcon: InteriorFloorIcon,
  images,
  defaultImage: {
    src: mainImage,
    fallbackSrc: mainImageFallback,
  },
};
