import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import {
  AdjustmentTypes,
  ShapeAdjustmentTypes,
  WallAdjustmentTypes,
} from "./types";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.ADJUSTMENTS,
  AdjustmentTypes
>["floorPlan"];

export const AdjustmentsConfigFloorPlan: Config = {
  sizes: {
    [ShapeAdjustmentTypes.FLATTEN_CORNER]: { width: 40, height: 40 },
    [ShapeAdjustmentTypes.PULL_WALL]: { width: 40, height: 40 },
    [ShapeAdjustmentTypes.PUSH_WALL]: { width: 40, height: 40 },
    [ShapeAdjustmentTypes.ROUND_CORNER]: { width: 40, height: 40 },
    [ShapeAdjustmentTypes.UN_FLATTEN_CORNER]: { width: 40, height: 40 },
    [ShapeAdjustmentTypes.UN_ROUND_CORNER]: { width: 40, height: 40 },
    [WallAdjustmentTypes.PLACE_SHORT_WALL]: { width: 40, height: 40 },
    [WallAdjustmentTypes.REMOVE_WALL_OR_SECTION]: { width: 40, height: 40 },
  },
};
