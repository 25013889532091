import { ReactComponent as IconRecessed } from "constants/guides/Electrical/LightingFixtures/assets/icon_recessed.svg";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import DefaultFallback from "./assets/Default.png";
import Default from "./assets/Default.webp";
import MainFallback from "./assets/main.png";
import Main from "./assets/main.webp";
import { PackagedSystemTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.PACKAGED_SYSTEM,
  PackagedSystemTypes
>["images"];

const icons: Config["icons"] = {
  [PackagedSystemTypes.DEFAULT]: IconRecessed,
};

const images: Config["images"] = {
  [PackagedSystemTypes.DEFAULT]: {
    src: Default,
    fallbackSrc: DefaultFallback,
  },
};

export const PackagedSystemConfigImages: Config = {
  categoryIcon: icons[PackagedSystemTypes.DEFAULT],
  images,
  icons,
  defaultIcon: icons[PackagedSystemTypes.DEFAULT],
  defaultImage: {
    src: Main,
    fallbackSrc: MainFallback,
  },
};
