import { Line } from "components/HBFloorPlan/HBFloorPlan.types";
import { Coords } from "pages/Guides/types";
import { getMinMaxXYOfPolygon } from "shared/floorPlan/utils/polygon.utils";

interface IsInTheWallParams {
  item: Coords[];
  rotation: number;
  stickedWall: Line;
}

export const isInTheWall = (params: IsInTheWallParams): boolean => {
  const { item, stickedWall, rotation } = params;

  const { p1, p2 } = stickedWall;

  const {
    minX: wallMinX,
    minY: wallMinY,
    maxX: wallMaxX,
    maxY: wallMaxY,
  } = getMinMaxXYOfPolygon([p1, p2]);

  const {
    minX: itemMinX,
    minY: itemMinY,
    maxX: itemMaxX,
    maxY: itemMaxY,
  } = getMinMaxXYOfPolygon(item);

  const isLineRotationLessThan45Deg =
    Math.abs(rotation) % Math.PI <= Math.PI / 4;

  if (isLineRotationLessThan45Deg) {
    if (itemMinY < wallMinY || itemMaxY > wallMaxY) {
      return false;
    }
  } else {
    if (itemMinX < wallMinX || itemMaxX > wallMaxX) {
      return false;
    }
  }

  return true;
};
