import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import {
  GuideItemTypeConfig,
  RuleType,
} from "constants/guides/GuideItems.types";
import {
  getStickToTheClosestItemRule,
  RULE_DEFAULT_STICK_TO_THE_WALL,
} from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/PixiFloorPlanItem/PixiFloorPlanItem.rules";

import { LightingControlTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.LIGHTING_CONTROL,
  LightingControlTypes
>["floorPlan"];

const stickToFixturesRule = getStickToTheClosestItemRule({
  getItemsToStick: (items) =>
    items.filter(
      (item) => item.item.category === GuideItemTypeCategory.LIGHTING_FIXTURE
    ),
});

const sizes: Config["sizes"] = {
  [LightingControlTypes.PULL_CHORD]: { width: 21, height: 21 },
  [LightingControlTypes.CONTROL_PANEL]: { width: 21, height: 21 },
  [LightingControlTypes.DIMMER]: { width: 21, height: 18 },
  [LightingControlTypes.SENSOR]: { width: 21, height: 21 },
  [LightingControlTypes.SWITCH]: { width: 21, height: 18 },
};

const rules: Config["rules"] = {
  [LightingControlTypes.PULL_CHORD]: stickToFixturesRule,
  [LightingControlTypes.CONTROL_PANEL]: RULE_DEFAULT_STICK_TO_THE_WALL,
  [LightingControlTypes.DIMMER]: RULE_DEFAULT_STICK_TO_THE_WALL,
  [LightingControlTypes.SENSOR]: RULE_DEFAULT_STICK_TO_THE_WALL,
  [LightingControlTypes.SWITCH]: RULE_DEFAULT_STICK_TO_THE_WALL,
};

const ruleSets: Config["ruleSets"] = {
  [LightingControlTypes.PULL_CHORD]: [RuleType.STICK_TO_WALL],
  [LightingControlTypes.CONTROL_PANEL]: [RuleType.STICK_TO_WALL],
  [LightingControlTypes.DIMMER]: [RuleType.STICK_TO_WALL],
  [LightingControlTypes.SENSOR]: [RuleType.STICK_TO_WALL],
  [LightingControlTypes.SWITCH]: [RuleType.STICK_TO_WALL],
};

export const LightingControlsConfigFloorPlan: Config = {
  sizes,
  rules,
  ruleSets,
};
