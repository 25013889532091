import * as Sentry from "@sentry/react";
import { isFirefox } from "common/utils/isFirefox";
import {
  API_BASE,
  BUILD_VERSION,
  DEPLOYMENT_NAME,
  IS_DEV_ENV,
  IS_STAGING,
  SENTRY_DSN,
  SENTRY_SPOTLIGHT,
} from "constants/env";
import { use } from "i18next";
import { polyfill as mobileDragAndDropPolyfill } from "mobile-drag-drop";
import { initReactI18next } from "react-i18next";
import { elementScrollIntoViewPolyfill } from "seamless-scroll-polyfill";

import { isMobile } from "./common/utils/isMobile";
import i18nConfig from "./i18n.config";

const setupTranslations = () => {
  return use(initReactI18next).init({
    lng: "en",
    ns: ["translation"],
    nsSeparator: i18nConfig.namespaceSeparator,
    keySeparator: i18nConfig.keySeparator,
    pluralSeparator: i18nConfig.pluralSeparator,
    returnEmptyString: false,
    fallbackLng: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: false,
      transKeepBasicHtmlNodesFor: [],
    },
  });
};

const setupPolyfills = () => {
  elementScrollIntoViewPolyfill();
  mobileDragAndDropPolyfill();
};

const setupSpotlight = async () => {
  if (IS_DEV_ENV) {
    if (!SENTRY_SPOTLIGHT) {
      return;
    }
    // TODO(clemens): remove conditional once fix for firefox is released
    //  see https://github.com/getsentry/spotlight/issues/376
    if (isFirefox) {
      return;
    }
    // TODO(clemens): unhide the toggle once the overlay is more stable
    //  see https://github.com/getsentry/spotlight/issues/275
    const hideSpotlight = document.createElement("style");
    hideSpotlight.textContent = "#sentry-spotlight-root { display: none; }";
    document.head.appendChild(hideSpotlight);

    const Spotlight = await import("@spotlightjs/spotlight");
    await Spotlight.init({
      injectImmediately: true,
      sidecarUrl: `http://localhost:${process.env.REACT_APP_SENTRY_SPOTLIGHT_PORT}/stream`,
    });
  }
};

const setupSentry = () => {
  if (SENTRY_DSN || SENTRY_SPOTLIGHT) {
    const options: Sentry.BrowserOptions = {
      dsn: SENTRY_SPOTLIGHT ? undefined : SENTRY_DSN,
      environment: DEPLOYMENT_NAME,
      release: BUILD_VERSION,
      replaysSessionSampleRate: IS_STAGING ? 1 : 0,
      replaysOnErrorSampleRate: 1,
    };

    if (IS_STAGING) {
      const {
        replayIntegration,
        replayCanvasIntegration,
        httpClientIntegration,
      } = Sentry;

      options.integrations = [
        httpClientIntegration({
          failedRequestStatusCodes: [404, [500, 599]],
        }),
        replayIntegration({
          maskAllText: !IS_STAGING,
          blockAllMedia: !IS_STAGING,
          networkDetailAllowUrls: [API_BASE, "/health/"],
          networkRequestHeaders: ["X-Epoch"],
          networkResponseHeaders: [
            "X-Epoch",
            "Retry-After",
            "Location",
            "CF-Cache-Status",
            "Cache-Control",
          ],
        }),
        replayCanvasIntegration(),
      ];
    }

    Sentry.init(options);
  }
};

const setupDebuggingConsoleOnMobile = async () => {
  if (isMobile && IS_STAGING) {
    const { default: eruda } = await import("eruda");
    eruda.init();
  }
};

export const setupApp = async () => {
  setupPolyfills();
  setupSentry();
  await setupSpotlight();
  await setupTranslations();
  await setupDebuggingConsoleOnMobile();
};
