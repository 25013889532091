import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";

import { SplitSystemExteriorUnitConfigFloorPlan } from "./config.floorPlan";
import { SplitSystemConfigImages } from "./config.images";
import { SplitSystemConfigTexts } from "./config.texts";
import { SplitSystemExteriorUnitTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.SPLIT_SYSTEM_EXTERIOR_UNIT,
  SplitSystemExteriorUnitTypes
>;

const types: Config["types"] = [SplitSystemExteriorUnitTypes.DEFAULT];

export const SplitSystemExteriorUnitConfig: Config = {
  guideType: GuideType.MECHANICAL,
  category: GuideItemTypeCategory.SPLIT_SYSTEM_EXTERIOR_UNIT,
  types,
  typesOrder: getOrderedMap(types),
  texts: SplitSystemConfigTexts,
  images: SplitSystemConfigImages,
  floorPlan: SplitSystemExteriorUnitConfigFloorPlan,
  allowedInRooms: undefined,
  allowedIntents: undefined,
} as const;
