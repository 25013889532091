import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { SplitSystemTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.SPLIT_SYSTEM,
  SplitSystemTypes
>["floorPlan"];

export const SplitSystemConfigFloorPlan: Config = {
  sizes: {
    [SplitSystemTypes.DEFAULT]: { width: 40, height: 40 },
  },
};
