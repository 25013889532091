import { ReactComponent as IconFloorPlan } from "assets/icons/categories/floor_plan.svg";
import mainImageFallback from "constants/guides/Electrical/LightingFixtures/assets/lighting_fixture_main.png";
import mainImage from "constants/guides/Electrical/LightingFixtures/assets/lighting_fixture_main.webp";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { ReactComponent as FlattenCornerIcon } from "./assets/icons/flatten_corner.svg";
import { ReactComponent as HalfWallIcon } from "./assets/icons/half_wall.svg";
import { ReactComponent as PullWallIcon } from "./assets/icons/pull_wall.svg";
import { ReactComponent as PushWallIcon } from "./assets/icons/push_wall.svg";
import { ReactComponent as RemoveWallIcon } from "./assets/icons/remove_wall.svg";
import { ReactComponent as RoundCornerIcon } from "./assets/icons/round_corner.svg";
import { ReactComponent as UnFlattenCornerIcon } from "./assets/icons/un_flatten_corner.svg";
import { ReactComponent as UnRoundCornerIcon } from "./assets/icons/un_round_corner.svg";
import {
  AdjustmentTypes,
  ShapeAdjustmentTypes,
  WallAdjustmentTypes,
} from "./types";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.ADJUSTMENTS,
  AdjustmentTypes
>["images"];

const images: Config["images"] = Object.fromEntries(
  Object.values(ShapeAdjustmentTypes).map((type) => [
    type,
    { src: undefined, fallbackSrc: undefined },
  ])
) as Config["images"];

const icons: Config["icons"] = {
  [ShapeAdjustmentTypes.PULL_WALL]: PullWallIcon,
  [ShapeAdjustmentTypes.PUSH_WALL]: PushWallIcon,
  [ShapeAdjustmentTypes.FLATTEN_CORNER]: FlattenCornerIcon,
  [ShapeAdjustmentTypes.UN_FLATTEN_CORNER]: UnFlattenCornerIcon,
  [ShapeAdjustmentTypes.ROUND_CORNER]: RoundCornerIcon,
  [ShapeAdjustmentTypes.UN_ROUND_CORNER]: UnRoundCornerIcon,
  [WallAdjustmentTypes.REMOVE_WALL_OR_SECTION]: RemoveWallIcon,
  [WallAdjustmentTypes.PLACE_SHORT_WALL]: HalfWallIcon,
};

export const AdjustmentConfigImages: Config = {
  categoryIcon: IconFloorPlan,
  images,
  icons,
  defaultIcon: IconFloorPlan,
  defaultImage: {
    src: mainImage,
    fallbackSrc: mainImageFallback,
  },
};
