import { ReactComponent as IconRecessed } from "constants/guides/Electrical/LightingFixtures/assets/icon_recessed.svg";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import MainFallback from "./assets/main.png";
import Main from "./assets/main.webp";
import { SplitSystemTypes } from "./enums";
import { getSplitSystemCountImage } from "./utils";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.SPLIT_SYSTEM,
  SplitSystemTypes
>["images"];

const icons: Config["icons"] = {
  [SplitSystemTypes.DEFAULT]: IconRecessed,
};

const images: Config["images"] = {
  [SplitSystemTypes.DEFAULT]: getSplitSystemCountImage(1),
};

export const SplitSystemConfigImages: Config = {
  categoryIcon: icons[SplitSystemTypes.DEFAULT],
  images,
  icons,
  defaultIcon: icons[SplitSystemTypes.DEFAULT],
  defaultImage: {
    src: Main,
    fallbackSrc: MainFallback,
  },
};
