import { GuideItemCategoriesMap } from "constants/guides/GuideItems";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import {
  getGuideItemCategoryName,
  getGuideItemGeneralName,
} from "constants/guides/GuideItems.utils";
import { GuideType } from "constants/guides/GuideType";
import { isCategoryEnabled } from "constants/GuideType.utils";
import {
  SubCategory,
  SubCategoryGuideItemTypeCategoriesMap,
} from "constants/subcategories/subcategory.types";
import { orderCategories } from "pages/HIPs/ProjectScope/modules/Tasks/HIPsSOWTasks.utils";
import { TFunction } from "react-i18next";

const HIDDEN_CATEGORIES: GuideItemTypeCategory[] = [
  GuideItemTypeCategory.SPLIT_SYSTEM_EXTERIOR_UNIT,
  GuideItemTypeCategory.SPLIT_SYSTEM_INTERIOR_UNIT,
];
const HIDDEN_TYPES: GuideType[] = [GuideType.SITEWORK, GuideType.SHEATHING];
const SINGLE_CATEGORIES: GuideItemTypeCategory[] = [
  GuideItemTypeCategory.SPLIT_SYSTEM,
  GuideItemTypeCategory.MINISPLIT_SYSTEM,
  GuideItemTypeCategory.PACKAGED_SYSTEM,
];
const ALL_ROOMS_CATEGORIES: GuideItemTypeCategory[] = [];

export const getGuideItemCategoriesConfigList = () =>
  Object.values(GuideItemCategoriesMap).filter(
    (it) => !HIDDEN_TYPES.includes(it.guideType)
  );

export const getGuideItemCategoriesList = () => {
  const types = [
    ...new Set(
      getGuideItemCategoriesConfigList().map((item) => item.guideType)
    ),
  ];
  return orderCategories(types);
};

export const getSubCategoryItems = (category: GuideType) => {
  return getGuideItemCategoriesConfigList().filter(
    (item) =>
      item.guideType === category &&
      !HIDDEN_CATEGORIES.includes(item.category) &&
      isCategoryEnabled(item.category)
  );
};

export const getSubCategoryAllowedRooms = (
  category: GuideType,
  subCategory: GuideItemTypeCategory
) => {
  const subCategoryConfig = getSubCategoryItems(category).find(
    (config) => config.category === subCategory
  );
  return subCategoryConfig.allowedInRooms;
};

export const isGuideWithoutRoomsSelection = (category: GuideItemTypeCategory) =>
  ALL_ROOMS_CATEGORIES.includes(category);

export const getGuideItemTypeCategoryFromSubcategory = (
  subcategory: SubCategory
): GuideItemTypeCategory => {
  return SubCategoryGuideItemTypeCategoriesMap[subcategory];
};

export const getSOWCategoryTitle = (
  t: TFunction,
  category: GuideItemTypeCategory
) =>
  SINGLE_CATEGORIES.includes(category)
    ? getGuideItemGeneralName(t, category)
    : getGuideItemCategoryName(t, category);
