import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import {
  AllAllowed,
  GuideItemTypeConfig,
} from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";

import { SmokeAlarmConfigFloorPlan } from "./config.floorPlan";
import { SmokeAlarmConfigImages } from "./config.images";
import { SmokeAlarmConfigTexts } from "./config.texts";
import { SmokeAlarmTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.SMOKE_ALARM,
  SmokeAlarmTypes
>;

const types: Config["types"] = [
  SmokeAlarmTypes.HARDWIRED,
  SmokeAlarmTypes.BATTERY_OPERATED,
];

const allAllowed: AllAllowed = undefined;

export const SmokeAlarmConfig = {
  guideType: GuideType.ELECTRICAL,
  category: GuideItemTypeCategory.SMOKE_ALARM,
  types,
  texts: SmokeAlarmConfigTexts,
  images: SmokeAlarmConfigImages,
  floorPlan: SmokeAlarmConfigFloorPlan,
  typesOrder: getOrderedMap(types),
  allowedIntents: allAllowed,
  allowedInRooms: allAllowed,
} as const;
