import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";

import { AdjustmentsConfigFloorPlan } from "./config.floorPlan";
import { AdjustmentConfigImages } from "./config.images";
import { AdjustmentConfigTexts } from "./config.texts";
import {
  AdjustmentTypes,
  ShapeAdjustmentTypes,
  WallAdjustmentTypes,
} from "./types";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.ADJUSTMENTS,
  AdjustmentTypes
>;

const types: Config["types"] = [
  ...Object.values(ShapeAdjustmentTypes),
  ...Object.values(WallAdjustmentTypes),
];

export const AdjustmentConfig: Config = {
  guideType: GuideType.ADD_ROOM_LAYOUT,
  category: GuideItemTypeCategory.ADJUSTMENTS,
  types,
  typesOrder: getOrderedMap(types),
  texts: AdjustmentConfigTexts,
  images: AdjustmentConfigImages,
  floorPlan: AdjustmentsConfigFloorPlan,
  allowedInRooms: undefined,
  allowedIntents: undefined,
} as const;
