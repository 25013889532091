import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";

import { SplitSystemInteriorUnitConfigFloorPlan } from "./config.floorPlan";
import { SplitSystemConfigImages } from "./config.images";
import { SplitSystemConfigTexts } from "./config.texts";
import { SplitSystemInteriorUnitTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.SPLIT_SYSTEM_INTERIOR_UNIT,
  SplitSystemInteriorUnitTypes
>;

const types: Config["types"] = [SplitSystemInteriorUnitTypes.DEFAULT];

export const SplitSystemInteriorUnitConfig: Config = {
  guideType: GuideType.MECHANICAL,
  category: GuideItemTypeCategory.SPLIT_SYSTEM_INTERIOR_UNIT,
  types,
  typesOrder: getOrderedMap(types),
  texts: SplitSystemConfigTexts,
  images: SplitSystemConfigImages,
  floorPlan: SplitSystemInteriorUnitConfigFloorPlan,
  allowedInRooms: undefined,
  allowedIntents: undefined,
} as const;
