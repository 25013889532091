import { Point } from "@flatten-js/core";
import { Coords } from "pages/Guides/types";
import { isEqualCoords } from "shared/floorPlan/utils/points.utils";

import {
  isInsideShape,
  IsInsideShapeParams,
  IsInsideShapeResult,
} from "./isInsideShape";

interface ShouldStayInsideShapeRuleParams extends IsInsideShapeParams {
  start?: Coords;
  end: Coords;
}

interface ShouldStayInsideShapeRuleResult extends IsInsideShapeResult {
  success: boolean;
  newPosition: Coords;
  Start: Point;
  End: Point;
}

/**
 * Checks if an item is inside a shape.
 *
 * If not, then it tries to find last correct
 * position in the path from start to end.
 */
export const shouldStayInsideShapeRule = (
  params: ShouldStayInsideShapeRuleParams
): ShouldStayInsideShapeRuleResult => {
  const { shape, item, end, start } = params;
  const { isInside, Shape, Item } = isInsideShape({ shape, item });
  const Start = start ? new Point(start.x, start.y) : new Point(0, 0);
  const End = new Point(end.x, end.y);

  /**
   * If start and end points are the same, then we can't do much.
   */
  if (isEqualCoords(Start, end)) {
    return {
      success: true,
      isInside,
      Shape,
      Item,
      Start,
      End,
      newPosition: end,
    };
  }

  /**
   * If point is inside, then position is already good.
   */
  if (isInside) {
    return {
      success: true,
      isInside,
      Shape,
      Item,
      Start,
      End,
      newPosition: end,
    };
  }

  return {
    success: false,
    isInside,
    Shape,
    Item,
    Start,
    End,
    newPosition: {
      x: (start || end).x,
      y: (start || end).y,
    },
  };
};
