import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";
import { ROOMS_PIECES_VALUES } from "constants/rooms/roomPieces";
import { isExteriorSpace } from "constants/rooms/rooms.utils";

import { PackagedSystemConfigFloorPlan } from "./config.floorPlan";
import { PackagedSystemConfigImages } from "./config.images";
import { PackagedSystemConfigTexts } from "./config.texts";
import { PackagedSystemTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.PACKAGED_SYSTEM,
  PackagedSystemTypes
>;

const types: Config["types"] = [PackagedSystemTypes.DEFAULT];

export const PackagedSystemConfig: Config = {
  guideType: GuideType.MECHANICAL,
  category: GuideItemTypeCategory.PACKAGED_SYSTEM,
  types,
  typesOrder: getOrderedMap(types),
  texts: PackagedSystemConfigTexts,
  images: PackagedSystemConfigImages,
  floorPlan: PackagedSystemConfigFloorPlan,
  allowedInRooms: ROOMS_PIECES_VALUES.filter((room) => !isExteriorSpace(room)),
  allowedIntents: undefined,
} as const;
