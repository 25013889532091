import { ReactComponent as IconFloorPlan } from "assets/icons/categories/floor_plan.svg";
import mainImageFallback from "constants/guides/Electrical/LightingFixtures/assets/lighting_fixture_main.png";
import mainImage from "constants/guides/Electrical/LightingFixtures/assets/lighting_fixture_main.webp";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { ROOMS_PIECES_ICONS } from "constants/rooms/roomIcons";
import { ROOMS_PIECES, RoomType } from "constants/rooms/roomPieces";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.ROOM,
  RoomType
>["images"];

const images: Config["images"] = Object.fromEntries(
  Object.values(ROOMS_PIECES).map((room) => [
    room,
    { src: undefined, fallbackSrc: undefined },
  ])
) as Config["images"];

const icons: Config["icons"] = {
  ...ROOMS_PIECES_ICONS,
};

export const RoomConfigImages: Config = {
  categoryIcon: IconFloorPlan,
  images,
  icons,
  defaultIcon: IconFloorPlan,
  defaultImage: {
    src: mainImage,
    fallbackSrc: mainImageFallback,
  },
};
