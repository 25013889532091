export enum LightingFixtureTypes {
  FLUSHMOUNT = "flushmount",
  HANGING_LIGHT = "hanging_light",
  RECESSED_LIGHT = "recessed_light",
  WALL_SCONCES = "wall_sconces",
  MONORAIL = "monorails",
  LED = "led",
  SEMI_FLUSHMOUNT = "semi_flushmount",
  CHANDELIER = "chandelier",
}
