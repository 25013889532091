import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { MiniSplitSystemTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.MINISPLIT_SYSTEM,
  MiniSplitSystemTypes
>["floorPlan"];

export const MiniSplitSystemConfigFloorPlan: Config = {
  sizes: {
    [MiniSplitSystemTypes.DEFAULT]: { width: 40, height: 40 },
  },
};
