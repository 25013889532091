import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { InteriorFloorMaterialTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL,
  InteriorFloorMaterialTypes
>["floorPlan"];

const sizes: Config["sizes"] = {
  [InteriorFloorMaterialTypes.CARPET]: { width: 40, height: 40 },
  [InteriorFloorMaterialTypes.TILE]: { width: 40, height: 40 },
  [InteriorFloorMaterialTypes.WOOD]: { width: 40, height: 40 },
  [InteriorFloorMaterialTypes.VINYL]: { width: 40, height: 40 },
  [InteriorFloorMaterialTypes.CONCRETE]: { width: 40, height: 40 },
  [InteriorFloorMaterialTypes.BRICK]: { width: 40, height: 40 },
};

export const InteriorFloorMaterialConfigFloorPlan: Config = {
  sizes,
};
