import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import mainImageFallback from "./assets/ceiling_fan_control_main.png";
import mainImage from "./assets/ceiling_fan_control_main.webp";
import pullChordFallback from "./assets/contol_pull_chord.png";
import pullChord from "./assets/contol_pull_chord.webp";
import controlPanelFallback from "./assets/control_control_panel.png";
import controlPanel from "./assets/control_control_panel.webp";
import onOffSpeedControlFallback from "./assets/control_speed_control.png";
import onOffSpeedControl from "./assets/control_speed_control.webp";
import switchControlFallback from "./assets/control_switch.png";
import switchControl from "./assets/control_switch.webp";
import { ReactComponent as CategoryIcon } from "./assets/icon_category_fan_controls.svg";
import { ReactComponent as IconFanControl } from "./assets/icon_fan_control.svg";
import { ReactComponent as IconUnknown } from "./assets/icon_unknown.svg";
import { CeilingFanControlTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.CEILING_FAN_CONTROL,
  CeilingFanControlTypes
>["images"];

const icons: Config["icons"] = {
  [CeilingFanControlTypes.PULL_CHORD]: IconFanControl,
  [CeilingFanControlTypes.CONTROL_PANEL]: IconFanControl,
  [CeilingFanControlTypes.SWITCH]: IconFanControl,
  [CeilingFanControlTypes.ON_OFF_SPEED_CONTROL]: IconFanControl,
};

const images: Config["images"] = {
  [CeilingFanControlTypes.PULL_CHORD]: {
    src: pullChord,
    fallbackSrc: pullChordFallback,
  },
  [CeilingFanControlTypes.ON_OFF_SPEED_CONTROL]: {
    src: onOffSpeedControl,
    fallbackSrc: onOffSpeedControlFallback,
  },
  [CeilingFanControlTypes.SWITCH]: {
    src: switchControl,
    fallbackSrc: switchControlFallback,
  },
  [CeilingFanControlTypes.CONTROL_PANEL]: {
    src: controlPanel,
    fallbackSrc: controlPanelFallback,
  },
};

export const CeilingFanControlsConfigImages: Config = {
  categoryIcon: CategoryIcon,
  defaultIcon: IconUnknown,
  defaultImage: {
    src: mainImage,
    fallbackSrc: mainImageFallback,
  },
  icons,
  images,
};
