import { Point, Polygon, Segment } from "@flatten-js/core";
import { Coords } from "pages/Guides/types";
import { getPointAtLength } from "shared/floorPlan/utils/line.utils";
import {
  mapPointsToList,
  minusCoords,
  rotatePointWithAngle,
  roundCoords,
} from "shared/floorPlan/utils/points.utils";
import {
  rotatePolygon,
  translatePolygonToCoords,
} from "shared/floorPlan/utils/polygon.utils";

import { intersectsShape, IntersectsShapeParams } from "./intersectsShape";

interface ShouldStickToShapesRuleParams
  extends Omit<IntersectsShapeParams, "shape"> {
  currentCoords: Coords;
  normalCorners: Coords[];
  corners: Coords[];
  centerOffset: Coords;
  normalCenterOffset: Coords;
  shapes: Array<Coords[]> | Array<Polygon>;
  start?: Coords;
  end: Coords;
}

interface ShouldStickToShapesRuleResult {
  success: boolean;
  newPosition: Coords;
  newRotationRad: number | undefined;
  Start: Point;
  End: Point;
  Shapes: Polygon[];
  Item: Polygon;
}

/**
 * Sticks an item to the closest shape.
 */
export const shouldStickToShapesRule = (
  params: ShouldStickToShapesRuleParams
): ShouldStickToShapesRuleResult => {
  const {
    normalCorners,
    currentCoords,
    normalCenterOffset,
    shapes,
    item,
    end,
    start,
  } = params;
  const Shapes = shapes.map((shape) =>
    shape instanceof Polygon ? shape : new Polygon(mapPointsToList(shape))
  );
  const Item =
    item instanceof Polygon ? item : new Polygon(mapPointsToList(item));

  const Start = start ? new Point(start.x, start.y) : new Point(end.x, end.y);
  const End = new Point(end.x, end.y);

  if (Shapes.length === 0) {
    return {
      success: false,
      Item,
      Shapes,
      Start,
      End,
      newPosition: end,
      newRotationRad: undefined,
    };
  }

  const distances = Shapes.map((Shape) => ({
    Shape,
    distance: End.distanceTo(Shape),
  })).sort((a, b) => a.distance[0] - b.distance[0]);

  if (distances.length === 0) {
    return {
      success: false,
      Item,
      Shapes,
      Start,
      End,
      newPosition: end,
      newRotationRad: undefined,
    };
  }

  const originalToShapeSegment = distances[0].distance[1];
  const fromShapeExtendedSegmentEnd = getPointAtLength(
    originalToShapeSegment.reverse(),
    1000
  );
  const fromShapeToItemSegment = new Segment(
    originalToShapeSegment.pe,
    new Point(fromShapeExtendedSegmentEnd.x, fromShapeExtendedSegmentEnd.y)
  );
  const targetRotationRad = originalToShapeSegment.slope;

  const baseCorners = rotatePolygon(
    normalCorners,
    targetRotationRad,
    minusCoords(currentCoords, normalCenterOffset)
  );

  for (let i = 0; i < fromShapeToItemSegment.length; i += 1) {
    const testPosition = fromShapeToItemSegment.pointAtLength(i);
    const testCorners = translatePolygonToCoords(baseCorners, testPosition).map(
      (c) => roundCoords(c)
    );

    if (
      Shapes.some(
        (shape) => intersectsShape({ shape, item: testCorners }).intersects
      )
    ) {
      continue;
    }

    const newCenterOffset = rotatePointWithAngle(
      normalCenterOffset,
      targetRotationRad
    );
    const newPosition = roundCoords(minusCoords(testPosition, newCenterOffset));

    return {
      success: true,
      Item: new Polygon(mapPointsToList(testCorners)),
      Shapes,
      Start,
      End,
      newPosition,
      newRotationRad: targetRotationRad,
    };
  }

  return {
    success: false,
    Item,
    Shapes,
    Start,
    End,
    newPosition: end,
    newRotationRad: undefined,
  };
};
