import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import controlPanelFallback from "./assets/control_control_panel.png";
import controlPanel from "./assets/control_control_panel.webp";
import dimmerFallback from "./assets/control_dimmer.png";
import dimmer from "./assets/control_dimmer.webp";
import pullChordFallback from "./assets/control_pull_chord.png";
import pullChord from "./assets/control_pull_chord.webp";
import sensorFallback from "./assets/control_sensor.png";
import sensor from "./assets/control_sensor.webp";
import switchControlFallback from "./assets/control_switch.png";
import switchControl from "./assets/control_switch.webp";
import { ReactComponent as CategoryIcon } from "./assets/icon_category_lighting_controls.svg";
import { ReactComponent as IconControl } from "./assets/icon_control.svg";
import { ReactComponent as IconControlPanel } from "./assets/icon_control_panel.svg";
import { ReactComponent as IconControlPanelDisabled } from "./assets/icon_control_panel_disabled.svg";
import { ReactComponent as IconControlDisabled } from "./assets/icon_lighting_control_disabled.svg";
import { ReactComponent as IconSensorDisabled } from "./assets/icon_motion_sensor_disabled.svg";
import { ReactComponent as IconPullChord } from "./assets/icon_pull_chord.svg";
import { ReactComponent as IconPullChordDisabled } from "./assets/icon_pull_chord_disabled.svg";
import { ReactComponent as IconSensor } from "./assets/icon_sensor.svg";
import { ReactComponent as IconUnknown } from "./assets/icon_unknown.svg";
import mainImageFallback from "./assets/lighting_control_main.png";
import mainImage from "./assets/lighting_control_main.webp";
import { LightingControlIconStates, LightingControlTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.LIGHTING_CONTROL,
  LightingControlTypes
>["images"];

const icons: Config["icons"] = {
  [LightingControlTypes.DIMMER]: IconControl,
  [LightingControlTypes.SENSOR]: IconSensor,
  [LightingControlTypes.CONTROL_PANEL]: IconControlPanel,
  [LightingControlTypes.SWITCH]: IconControl,
  [LightingControlTypes.PULL_CHORD]: IconPullChord,
};

const modifiedIcons: Config["modifiedIcons"] = {
  [LightingControlIconStates.DIMMER_DISABLED]: IconControlDisabled,
  [LightingControlIconStates.SENSOR_DISABLED]: IconSensorDisabled,
  [LightingControlIconStates.CONTROL_PANEL_DISABLED]: IconControlPanelDisabled,
  [LightingControlIconStates.SWITCH_DISABLED]: IconControlDisabled,
  [LightingControlIconStates.PULL_CHORD_DISABLED]: IconPullChordDisabled,
};

const images: Config["images"] = {
  [LightingControlTypes.DIMMER]: { src: dimmer, fallbackSrc: dimmerFallback },
  [LightingControlTypes.SENSOR]: { src: sensor, fallbackSrc: sensorFallback },
  [LightingControlTypes.CONTROL_PANEL]: {
    src: controlPanel,
    fallbackSrc: controlPanelFallback,
  },
  [LightingControlTypes.SWITCH]: {
    src: switchControl,
    fallbackSrc: switchControlFallback,
  },
  [LightingControlTypes.PULL_CHORD]: {
    src: pullChord,
    fallbackSrc: pullChordFallback,
  },
};

export const LightingControlsConfigImages: Config = {
  icons,
  modifiedIcons,
  images,
  categoryIcon: CategoryIcon,
  defaultIcon: IconUnknown,
  defaultImage: {
    src: mainImage,
    fallbackSrc: mainImageFallback,
  },
};
