import { useSelector } from "@xstate/react";
import { useGlobalState } from "core/state/global/GlobalStateContext";
import { getActivePropertyContext } from "core/state/global/OrchestratorMachine/actions.utils";
import type { OrchestratorMachineContext } from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";

const orchestratorActiveGuidesSelector = (state: {
  context: OrchestratorMachineContext;
}) => getActivePropertyContext(state.context).activeGuides;
export const useOrchestratorActiveGuides = () => {
  const { orchestrator } = useGlobalState();

  return useSelector(orchestrator, orchestratorActiveGuidesSelector);
};

export const useOrchestratorHIPActiveGuides = (hipsId: string) => {
  const activeGuides = useOrchestratorActiveGuides();
  return activeGuides.filter((guide) => guide._hipId === hipsId);
};
