import { FloorPlanItem } from "pages/Guides/components/FloorPlan/FloorPlanItems/FloorPlanItems.types";
import {
  floorPlanItemToPolygon,
  getFloorPlanItemGeometry,
} from "pages/Guides/components/FloorPlan/FloorPlanItems/FloorPlanItems.utils";
import { Coords } from "pages/Guides/types";
import {
  hackShapesToClosedShape,
  hackShapesToShape,
  mapBaseShapeToPolygons,
} from "shared/floorPlan/utils/baseShape.utils";
import { getPointAtLength } from "shared/floorPlan/utils/line.utils";
import {
  isEqualCoords,
  minusCoords,
  sumCoords,
} from "shared/floorPlan/utils/points.utils";
import { translatePolygonToCoords } from "shared/floorPlan/utils/polygon.utils";
import { shouldBeInTheWallRule } from "shared/floorPlan/utils/rules/shouldBeInTheWallRule";
import { shouldStayInsideShapeRule } from "shared/floorPlan/utils/rules/shouldStayInsideShapeRule";
import { shouldStayOutsideShapesRule } from "shared/floorPlan/utils/rules/shouldStayOutsideShapesRule";
import { shouldStickToShapesRule } from "shared/floorPlan/utils/rules/shouldStickToShapesRule";

import { RestrictPositionRuleFn } from "./PixiFloorPlanItem.types";

export interface Gap {
  percentGap?: number;
  absoluteGap?: number;
}

interface CannotOverlapRuleParams {}

interface StickToTheClosestWallRule {}

interface StickToTheClosestItemRuleParams {
  getItemsToStick: (otherItems: FloorPlanItem[]) => FloorPlanItem[];
}

interface BeInTheWallRuleParams {
  wallsGap?: Gap;
  itemsGap?: Gap;
  offsetPosition?: number;
  offsetRotationRad?: number;
  rotationRad?: number;
}

const getCannotOverlapRule = (
  _params?: CannotOverlapRuleParams
): RestrictPositionRuleFn => {
  return (ruleParams) => {
    const { ctx, start, end, otherItems, baseShape, floorPlanItem } =
      ruleParams;

    let newPosition = end;
    let success = true;

    const itemGeometry = getFloorPlanItemGeometry({ floorPlanItem, ctx });

    if (!itemGeometry) {
      success = false;
    }

    if (success && baseShape) {
      const item = translatePolygonToCoords(
        itemGeometry.corners,
        sumCoords(newPosition, itemGeometry.centerOffset)
      );

      const rules = shouldStayInsideShapeRule({
        start,
        end,
        shape: hackShapesToClosedShape(baseShape.shapes),
        item,
      });

      if (rules.success) {
        newPosition = rules.newPosition;
      } else {
        success = false;
      }
    }

    if (success && baseShape) {
      const item = translatePolygonToCoords(
        itemGeometry.corners,
        sumCoords(newPosition, itemGeometry.centerOffset)
      );
      const shapes: Array<Coords[]> = [];
      const possibleShapes = baseShape.shapes || [];

      for (const shape of possibleShapes) {
        if (shape.length !== 0) {
          shapes.push(...mapBaseShapeToPolygons(shape));
        }
      }

      const rules = shouldStayOutsideShapesRule({
        start,
        end: newPosition,
        item,
        shapes,
      });

      if (rules.success) {
        newPosition = rules.newPosition;
      } else {
        success = false;
      }
    }

    if (success && otherItems && otherItems.length > 0) {
      const item = translatePolygonToCoords(
        itemGeometry.corners,
        sumCoords(newPosition, itemGeometry.centerOffset)
      );

      const shapes = otherItems
        .map(
          (item) =>
            getFloorPlanItemGeometry({ floorPlanItem: item, ctx })?.corners
        )
        .filter(Boolean);

      const rules = shouldStayOutsideShapesRule({
        start,
        end: newPosition,
        item,
        shapes,
      });

      if (rules.success) {
        newPosition = rules.newPosition;
      } else {
        success = false;
      }
    }

    return {
      success,
      coords: newPosition,
      rotation: floorPlanItem.item.rotationRad,
    };
  };
};

export const getShouldBeInTheWallRule = (
  params?: BeInTheWallRuleParams
): RestrictPositionRuleFn => {
  const { wallsGap, itemsGap, offsetPosition, offsetRotationRad, rotationRad } =
    params || {};

  return (ruleParams) => {
    const { ctx, start, end, otherItems, baseShape, floorPlanItem } =
      ruleParams;

    let newPosition = end;
    let success = true;
    let rotation = 0;

    if (baseShape) {
      const rules = shouldBeInTheWallRule({
        start,
        end,
        shape: hackShapesToShape(baseShape.shapes),
        floorPlanItem,
        ctx,
        wallsGap,
        offsetPosition,
        offsetRotationRad,
        rotationRad,
      });

      if (success && rules.success) {
        rotation = rules.rotation;
        newPosition = rules.newPosition;
      } else {
        success = false;
      }
    }

    if (otherItems && otherItems.length > 0) {
      const { percentGap, absoluteGap } = itemsGap || {};
      const { item } = floorPlanItemToPolygon({
        item: floorPlanItem,
        ctx,
        coords: newPosition,
        percentGap,
        absoluteGap,
      });
      const shapes = otherItems.map(
        (item) => floorPlanItemToPolygon({ item, ctx }).item
      );

      const rules = shouldStayOutsideShapesRule({
        start,
        end: newPosition,
        item,
        shapes,
      });

      if (success && rules.success) {
        newPosition = rules.newPosition;
      } else {
        success = false;
      }
    }

    return { success, coords: newPosition, rotation, itemEndPoints: undefined };
  };
};

const getStickToTheClosestWallRule = (
  _params?: StickToTheClosestWallRule
): RestrictPositionRuleFn => {
  return (ruleParams) => {
    const { ctx, start, end, otherItems, baseShape, floorPlanItem } =
      ruleParams;

    let newPosition = end;
    let newRotationRad: number | undefined = undefined;
    let success = true;
    let rules: ReturnType<
      | typeof shouldStayInsideShapeRule
      | typeof shouldStickToShapesRule
      | typeof shouldStayOutsideShapesRule
    >;

    const geometry = getFloorPlanItemGeometry({ floorPlanItem, ctx });

    if (!geometry) {
      success = false;
    }

    if (success && baseShape) {
      const shapes: Array<Coords[]> = [];
      const possibleShapes = baseShape.shapes || [];

      for (const shape of possibleShapes) {
        if (shape.length !== 0) {
          shapes.push(...mapBaseShapeToPolygons(shape));
        }
      }

      const item = translatePolygonToCoords(geometry.corners, newPosition);

      const localRules = shouldStickToShapesRule({
        currentCoords: geometry.coords,
        corners: geometry.corners,
        normalCorners: geometry.normalCorners,
        normalCenterOffset: geometry.normalCenterOffset,
        centerOffset: geometry.centerOffset,
        start,
        end: newPosition,
        item,
        shapes,
      });

      if (localRules.success) {
        newPosition = localRules.newPosition;
        newRotationRad = localRules.newRotationRad;
      } else {
        success = false;
      }

      rules = localRules;

      if (rules.success) {
        rules = shouldStayInsideShapeRule({
          start,
          end: newPosition,
          shape: hackShapesToClosedShape(baseShape.shapes),
          item: rules.Item,
        });

        /**
         * Note(pavel):
         *
         * Item is sticked, but outside the room:
         * let's try to stick it inside.
         */
        if (!rules.success) {
          const possiblePosition = getPointAtLength([newPosition, end], 100);

          if (!isEqualCoords(newPosition, possiblePosition)) {
            const item = translatePolygonToCoords(
              geometry.corners,
              possiblePosition
            );

            const localRules = shouldStickToShapesRule({
              currentCoords: geometry.coords,
              corners: geometry.corners,
              normalCorners: geometry.normalCorners,
              normalCenterOffset: geometry.normalCenterOffset,
              centerOffset: geometry.centerOffset,
              start,
              end: possiblePosition,
              item,
              shapes,
            });

            if (
              localRules.success &&
              shouldStayInsideShapeRule({
                start,
                end: localRules.newPosition,
                shape: hackShapesToClosedShape(baseShape.shapes),
                item: localRules.Item,
              }).success
            ) {
              newPosition = localRules.newPosition;
              newRotationRad = localRules.newRotationRad;
              rules = localRules;
            } else {
              success = false;
            }
          } else {
            success = false;
          }
        }

        if (rules.success) {
          newPosition = rules.newPosition;
        } else {
          success = false;
        }
      }
    }

    if (success && otherItems && otherItems.length > 0) {
      const item =
        rules?.Item || getFloorPlanItemGeometry({ floorPlanItem, ctx }).corners;
      const shapes = otherItems
        .map(
          (item) =>
            getFloorPlanItemGeometry({ floorPlanItem: item, ctx }).corners
        )
        .filter(Boolean);

      rules = shouldStayOutsideShapesRule({
        start,
        end: newPosition,
        item,
        shapes,
      });

      if (success && rules.success) {
        newPosition = rules.newPosition;
      } else {
        success = false;
      }
    }

    return { success, coords: newPosition, rotation: newRotationRad };
  };
};

export const getStickToTheClosestItemRule = (
  params?: StickToTheClosestItemRuleParams
): RestrictPositionRuleFn => {
  const { getItemsToStick } = params || {};

  return (ruleParams) => {
    const { ctx, start, end, otherItems, baseShape, floorPlanItem } =
      ruleParams;

    let newPosition = end;
    let newRotationRad: number | undefined = undefined;
    let success = true;
    let rules: ReturnType<
      | typeof shouldStayInsideShapeRule
      | typeof shouldStickToShapesRule
      | typeof shouldStayOutsideShapesRule
    >;

    const geometry = getFloorPlanItemGeometry({ floorPlanItem, ctx });

    if (!geometry) {
      success = false;
    }

    if (success && baseShape) {
      const item = translatePolygonToCoords(
        geometry.corners,
        minusCoords(newPosition, geometry.centerOffset)
      );
      rules = shouldStayInsideShapeRule({
        start,
        end: newPosition,
        shape: hackShapesToClosedShape(baseShape.shapes),
        item,
      });

      if (rules.success) {
        newPosition = rules.newPosition;
      } else {
        success = false;
      }
    }

    const itemsToStick = getItemsToStick(otherItems || []);

    if (success && itemsToStick.length > 0) {
      const shapes = itemsToStick
        .map(
          (itt) =>
            getFloorPlanItemGeometry({
              floorPlanItem: itt,
              ctx,
            })?.corners
        )
        .filter(Boolean);

      const shapeRules: ReturnType<
        typeof shouldStayInsideShapeRule | typeof shouldStickToShapesRule
      > = shouldStickToShapesRule({
        currentCoords: geometry.coords,
        corners: geometry.corners,
        normalCorners: geometry.normalCorners,
        normalCenterOffset: geometry.normalCenterOffset,
        centerOffset: geometry.centerOffset,
        start,
        end: newPosition,
        item: geometry.corners,
        shapes,
      });

      if (shapeRules.success) {
        newPosition = shapeRules.newPosition;
        newRotationRad = shapeRules.newRotationRad;
        rules = shapeRules;
      } else {
        success = false;
      }
    }

    if (success && otherItems && otherItems.length > 0) {
      const item =
        rules?.Item || getFloorPlanItemGeometry({ floorPlanItem, ctx }).corners;
      const shapes = otherItems
        .map(
          (item) =>
            getFloorPlanItemGeometry({ floorPlanItem: item, ctx })?.corners
        )
        .filter(Boolean);

      rules = shouldStayOutsideShapesRule({
        start,
        end: newPosition,
        item,
        shapes,
      });

      if (success && rules.success) {
        newPosition = rules.newPosition;
      } else {
        success = false;
      }
    }

    return { success, coords: newPosition, rotation: newRotationRad };
  };
};

export const RULE_DEFAULT_ALL_ALLOWED: RestrictPositionRuleFn = (params) => ({
  success: true,
  coords: params.end,
});
export const RULE_DEFAULT_CANNOT_OVERLAP = getCannotOverlapRule({});
export const RULE_DEFAULT_STICK_TO_THE_WALL = getStickToTheClosestWallRule({});
