import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";

import { CeilingFansConfigFloorPlan } from "./config.floorPlan";
import { CeilingFansConfigImages } from "./config.images";
import { CeilingFansConfigTexts } from "./config.texts";
import { CeilingFanTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.CEILING_FAN,
  CeilingFanTypes
>;

const types: Config["types"] = [
  CeilingFanTypes.STANDARD_FAN,
  CeilingFanTypes.FAN_WITH_LIGHT,
];

export const CeilingFansConfig = {
  guideType: GuideType.ELECTRICAL,
  category: GuideItemTypeCategory.CEILING_FAN,
  types,
  typesOrder: getOrderedMap(types),
  texts: CeilingFansConfigTexts,
  images: CeilingFansConfigImages,
  floorPlan: CeilingFansConfigFloorPlan,
  allowedIntents: undefined,
  allowedInRooms: undefined,
} as const;
