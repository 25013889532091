import { Point, Polygon } from "@flatten-js/core";
import { Coords } from "pages/Guides/types";
import { mapPointsToList } from "shared/floorPlan/utils/points.utils";

export interface IntersectsShapeParams {
  shape: Coords[] | Polygon;
  item: Coords[] | Polygon;
}
interface IntersectsShapeResult {
  intersects: boolean;
  Intersections: Point[];
  Shape: Polygon;
  Item: Polygon;
}

/**
 * Checks if an item intersects a shape.
 *
 * Returns boolean result plus intermediate classes.
 */
export const intersectsShape = (
  params: IntersectsShapeParams
): IntersectsShapeResult => {
  const { shape, item } = params;

  const Shape =
    shape instanceof Polygon ? shape : new Polygon(mapPointsToList(shape));
  const Item =
    item instanceof Polygon ? item : new Polygon(mapPointsToList(item));
  const Intersections = Shape.intersect(Item);
  const intersects = Intersections.length > 0;

  return { intersects, Shape, Item, Intersections };
};
