import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import DefaultFallback from "./assets/Default.png";
import Default from "./assets/Default.webp";
import { ReactComponent as IconDefault } from "./assets/icon_default.svg";
import { SplitSystemInteriorUnitTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.SPLIT_SYSTEM_INTERIOR_UNIT,
  SplitSystemInteriorUnitTypes
>["images"];

const icons: Config["icons"] = {
  [SplitSystemInteriorUnitTypes.DEFAULT]: IconDefault,
};

const images: Config["images"] = {
  [SplitSystemInteriorUnitTypes.DEFAULT]: {
    src: Default,
    fallbackSrc: DefaultFallback,
  },
};

export const SplitSystemConfigImages: Config = {
  categoryIcon: icons[SplitSystemInteriorUnitTypes.DEFAULT],
  images,
  icons,
  defaultIcon: icons[SplitSystemInteriorUnitTypes.DEFAULT],
  defaultImage: images[SplitSystemInteriorUnitTypes.DEFAULT],
};
