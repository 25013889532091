import { ReactComponent as IconRecessed } from "constants/guides/Electrical/LightingFixtures/assets/icon_recessed.svg";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import DefaultFallback from "./assets/Default.png";
import Default from "./assets/Default.webp";
import MainFallback from "./assets/main.png";
import Main from "./assets/main.webp";
import { BoilerTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.BOILER,
  BoilerTypes
>["images"];

const icons: Config["icons"] = {
  [BoilerTypes.DEFAULT]: IconRecessed,
};

const images: Config["images"] = {
  [BoilerTypes.DEFAULT]: {
    src: Default,
    fallbackSrc: DefaultFallback,
  },
};

export const BoilerConfigImages: Config = {
  categoryIcon: icons[BoilerTypes.DEFAULT],
  images,
  icons,
  defaultIcon: icons[BoilerTypes.DEFAULT],
  defaultImage: {
    src: Main,
    fallbackSrc: MainFallback,
  },
};
