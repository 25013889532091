import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { CeilingFanControlTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.CEILING_FAN_CONTROL,
  CeilingFanControlTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [CeilingFanControlTypes.ON_OFF_SPEED_CONTROL]: t("Speed Control"),
  [CeilingFanControlTypes.SWITCH]: t("Switch"),
  [CeilingFanControlTypes.CONTROL_PANEL]: t("Control Panel"),
  [CeilingFanControlTypes.PULL_CHORD]: t("Pull Chord"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [CeilingFanControlTypes.ON_OFF_SPEED_CONTROL]: t("Speed Control"),
  [CeilingFanControlTypes.SWITCH]: t("Switch"),
  [CeilingFanControlTypes.CONTROL_PANEL]: t("Control Panel"),
  [CeilingFanControlTypes.PULL_CHORD]: t("Pull Chord"),
});

export const CeilingFanControlsConfigTexts: Config = {
  categoryName: (t) => t("Ceiling Fan Controls"),
  itemName: (t) => t("Ceiling Fan Control"),
  categoryDescription: (t) =>
    t(
      "Fan controls provide convenient ways to adjust fan settings and airflow, enhancing the usability of ceiling fans and offering tailored comfort and efficiency according to individual preferences and changing environmental conditions."
    ),
  itemNames,
  typeNames,
};
