import { Graphics as PixiGraphics } from "@pixi/graphics";
import { Points } from "components/HBFloorPlan/HBFloorPlan.types";
import { WindowTypes } from "constants/guides/WindowsAndDoors/Windows/enums";
import lineToPolygon from "intersects/lineToPolygon";
import { Coords } from "pages/Guides/types";
import { Polygon } from "pixi.js";
import {
  WINDOW_BASE_COLOR,
  WINDOW_COLOR,
} from "shared/floorPlan/constants.colors";

import { minusCoords } from "./points.utils";

interface DrawWindowProps {
  g: PixiGraphics;
  endPoints: Points;
  rotationRad: number;
}

type DrawWindowFn = (props: DrawWindowProps) => void;

const WINDOW_WIDTH = 15;
const MIDDLE_WIDTH = 5;

const drawWindow = (props: DrawWindowProps) => {
  const { g, endPoints, rotationRad } = props;

  const spacingHeight = 6;
  const edgeHeight = 5;

  const start = endPoints[0];
  const end = endPoints[1];

  g.clear();

  g.lineStyle(WINDOW_WIDTH, WINDOW_BASE_COLOR);
  g.moveTo(start.x, start.y);
  g.lineTo(end.x, end.y);

  g.lineStyle(WINDOW_WIDTH, WINDOW_COLOR, 0.5);
  g.moveTo(start.x, start.y + spacingHeight);
  g.lineTo(start.x, start.y + (spacingHeight + edgeHeight));

  g.lineStyle(WINDOW_WIDTH, WINDOW_COLOR, 0.5);
  g.moveTo(end.x, end.y - (spacingHeight + edgeHeight));
  g.lineTo(end.x, end.y - spacingHeight);

  g.lineStyle(MIDDLE_WIDTH, WINDOW_COLOR, 0.5);
  g.moveTo(start.x, start.y + (spacingHeight + edgeHeight));
  g.lineTo(end.x, end.y - (spacingHeight + edgeHeight));

  g.rotation = rotationRad;

  const polygon = lineToPolygon(start.x, start.y, end.x, end.y, WINDOW_WIDTH);

  g.hitArea = new Polygon(polygon);
  g.eventMode = "static";
  g.accessible = false;

  g.endFill();
};

export const drawWindows: Partial<Record<WindowTypes, DrawWindowFn>> = {
  [WindowTypes.FIXED]: drawWindow,
  [WindowTypes.DOUBLE_HUNG]: drawWindow,
  [WindowTypes.ARCHED]: drawWindow,
  [WindowTypes.BAY]: drawWindow,
  [WindowTypes.BOW]: drawWindow,
  [WindowTypes.CASEMENT]: drawWindow,
  [WindowTypes.SINGLE_HUNG]: drawWindow,
  [WindowTypes.GARDEN]: drawWindow,
  [WindowTypes.GLASS_BLOCK]: drawWindow,
  [WindowTypes.HOPPER]: drawWindow,
  [WindowTypes.SLIDING]: drawWindow,
};

export const getEndPointsOfWindow = (props: {
  points: Points;
}): [Coords, Coords] => {
  const { points } = props;
  const start = points[0];
  const end = points[2];
  const p1 = { x: (start.x + end.x) / 2, y: start.y };
  const p2 = { x: p1.x, y: end.y };
  return [p1, p2];
};

export const getEndPointsFromCenter = (props: {
  center: Coords;
  length: number;
}): [Coords, Coords] => {
  const { center, length } = props;
  const distance = length / 2;
  const p1 = {
    x: center.x,
    y: center.y - distance,
  };
  const p2 = {
    x: center.x,
    y: center.y + distance,
  };
  return [p1, p2];
};

export const getWindowEndPoints = (props: {
  itemEndPoints: Points;
  center: Coords;
}): [Coords, Coords] => {
  const { itemEndPoints, center } = props;
  const [_p1, _p2] = itemEndPoints;
  const p1 = minusCoords(_p1, center);
  const p2 = minusCoords(_p2, center);
  return [p1, p2];
};

export const getUpdatedEndPointsWithOffset = (props: {
  endPoints: Points;
  center: Coords;
  offset: Coords;
  offsetType: "p1" | "p2";
}): [Coords, Coords] => {
  const { endPoints, center, offset, offsetType } = props;

  const offsetX = offsetType === "p1" ? -offset.x / 2 : offset.x / 2;
  const offsetY = offsetType === "p1" ? -offset.y / 2 : offset.y / 2;

  const start = {
    x: endPoints[0].x - offsetX,
    y: endPoints[0].y - offsetY,
  };
  const end = {
    x: endPoints[1].x + offsetX,
    y: endPoints[1].y + offsetY,
  };

  const p1 = minusCoords(start, center);
  const p2 = minusCoords(end, center);

  return [p1, p2];
};
