import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import {
  GuideItemTypeConfig,
  RuleType,
} from "constants/guides/GuideItems.types";
import {
  getStickToTheClosestItemRule,
  RULE_DEFAULT_STICK_TO_THE_WALL,
} from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/PixiFloorPlanItem/PixiFloorPlanItem.rules";

import { CeilingFanControlTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.CEILING_FAN_CONTROL,
  CeilingFanControlTypes
>["floorPlan"];

const stickToFansRule = getStickToTheClosestItemRule({
  getItemsToStick: (items) =>
    items.filter(
      (item) => item.item.category === GuideItemTypeCategory.CEILING_FAN
    ),
});

const sizes: Config["sizes"] = {
  [CeilingFanControlTypes.SWITCH]: { width: 21, height: 18 },
  [CeilingFanControlTypes.PULL_CHORD]: { width: 21, height: 21 },
  [CeilingFanControlTypes.CONTROL_PANEL]: { width: 21, height: 18 },
  [CeilingFanControlTypes.ON_OFF_SPEED_CONTROL]: { width: 21, height: 18 },
};

const rules: Config["rules"] = {
  [CeilingFanControlTypes.SWITCH]: RULE_DEFAULT_STICK_TO_THE_WALL,
  [CeilingFanControlTypes.CONTROL_PANEL]: RULE_DEFAULT_STICK_TO_THE_WALL,
  [CeilingFanControlTypes.ON_OFF_SPEED_CONTROL]: RULE_DEFAULT_STICK_TO_THE_WALL,
  [CeilingFanControlTypes.PULL_CHORD]: stickToFansRule,
};

const ruleSets: Config["ruleSets"] = {
  [CeilingFanControlTypes.SWITCH]: [RuleType.STICK_TO_WALL],
  [CeilingFanControlTypes.CONTROL_PANEL]: [RuleType.STICK_TO_WALL],
  [CeilingFanControlTypes.ON_OFF_SPEED_CONTROL]: [RuleType.STICK_TO_WALL],
  [CeilingFanControlTypes.PULL_CHORD]: [RuleType.STAY_INSIDE],
};

export const CeilingFanControlsConfigFloorPlan: Config = {
  sizes,
  rules,
  ruleSets,
};
