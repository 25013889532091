import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";

import { CeilingFanControlsConfigFloorPlan } from "./config.floorPlan";
import { CeilingFanControlsConfigImages } from "./config.images";
import { CeilingFanControlsConfigTexts } from "./config.texts";
import { CeilingFanControlTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.CEILING_FAN_CONTROL,
  CeilingFanControlTypes
>;

const types: Config["types"] = [
  CeilingFanControlTypes.SWITCH,
  CeilingFanControlTypes.CONTROL_PANEL,
  CeilingFanControlTypes.ON_OFF_SPEED_CONTROL,
  //Note(Adam): Pull chord is not a real guide item
  CeilingFanControlTypes.PULL_CHORD,
];

export const CeilingFanControlsConfig = {
  guideType: GuideType.ELECTRICAL,
  category: GuideItemTypeCategory.CEILING_FAN_CONTROL,
  types,
  typesOrder: getOrderedMap(types),
  images: CeilingFanControlsConfigImages,
  texts: CeilingFanControlsConfigTexts,
  floorPlan: CeilingFanControlsConfigFloorPlan,
  allowedIntents: undefined,
  allowedInRooms: undefined,
} as const;
