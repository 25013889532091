import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";
import { ROOMS_PIECES_VALUES, RoomType } from "constants/rooms/roomPieces";

import { RoomConfigFloorPlan } from "./config.floorPlan";
import { RoomConfigImages } from "./config.images";
import { RoomConfigTexts } from "./config.texts";

type Config = GuideItemTypeConfig<GuideItemTypeCategory.ROOM, RoomType>;

const types: Config["types"] = [...ROOMS_PIECES_VALUES];

export const RoomConfig: Config = {
  guideType: GuideType.ADD_ROOM_LAYOUT,
  category: GuideItemTypeCategory.ROOM,
  types,
  typesOrder: getOrderedMap(types),
  texts: RoomConfigTexts,
  images: RoomConfigImages,
  floorPlan: RoomConfigFloorPlan,
  allowedInRooms: undefined,
  allowedIntents: undefined,
} as const;
