import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { WallMaterialTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.WALL_MATERIAL,
  WallMaterialTypes
>["floorPlan"];

export const WallMaterialConfigFloorPlan: Config = {
  sizes: {
    [WallMaterialTypes.BRICK]: { width: 40, height: 40 },
    [WallMaterialTypes.DRYWALL]: { width: 40, height: 40 },
    [WallMaterialTypes.TILE]: { width: 40, height: 40 },
    [WallMaterialTypes.STONE]: { width: 40, height: 40 },
    [WallMaterialTypes.WOOD]: { width: 40, height: 40 },
    [WallMaterialTypes.LATH_AND_PLASTER]: { width: 40, height: 40 },
  },
};
