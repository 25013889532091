import { GetPricesResponse } from "api/models/GetPricesResponse";

/**
 * TODO(clemens): remove this and all related machinery
 *  see https://tree.taiga.io/project/homebase-engineering/us/1560
 * @deprecated
 */
export const pricesData: GetPricesResponse = {
  labor: {
    categories: {},
    types: {},
  },
  items: {
    categories: {},
    types: {},
    subtypes: {},
  },
};
