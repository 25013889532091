import { ReactComponent as IconFloorPlan } from "assets/icons/categories/floor_plan.svg";
import mainImageFallback from "constants/guides/Electrical/LightingFixtures/assets/lighting_fixture_main.png";
import mainImage from "constants/guides/Electrical/LightingFixtures/assets/lighting_fixture_main.webp";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { ReactComponent as IconCategoryDoorsWindows } from "constants/guides/WindowsAndDoors/assets/icon_category_doors_windows.svg";

import { ReactComponent as WindowsIcon } from "./assets/icons/windows.svg";
import archedFallback from "./assets/images/arched.png";
import arched from "./assets/images/arched.webp";
import bayFallback from "./assets/images/bay.png";
import bay from "./assets/images/bay.webp";
import bowFallback from "./assets/images/bow.png";
import bow from "./assets/images/bow.webp";
import casementFallback from "./assets/images/casement.png";
import casement from "./assets/images/casement.webp";
import doubleHungFallback from "./assets/images/double_hung.png";
import doubleHung from "./assets/images/double_hung.webp";
import fixedFallback from "./assets/images/fixed.png";
import fixed from "./assets/images/fixed.webp";
import gardenFallback from "./assets/images/garden.png";
import garden from "./assets/images/garden.webp";
import glassBlockFallback from "./assets/images/glass_block.png";
import glassBlock from "./assets/images/glass_block.webp";
import hopperFallback from "./assets/images/hopper.png";
import hopper from "./assets/images/hopper.webp";
import singleHungFallback from "./assets/images/single_hung.png";
import singleHung from "./assets/images/single_hung.webp";
import slidingFallback from "./assets/images/sliding.png";
import sliding from "./assets/images/sliding.webp";
import { WindowTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.WINDOWS,
  WindowTypes
>["images"];

const images: Config["images"] = {
  [WindowTypes.FIXED]: {
    src: fixed,
    fallbackSrc: fixedFallback,
  },
  [WindowTypes.DOUBLE_HUNG]: {
    src: doubleHung,
    fallbackSrc: doubleHungFallback,
  },
  [WindowTypes.ARCHED]: {
    src: arched,
    fallbackSrc: archedFallback,
  },
  [WindowTypes.BAY]: {
    src: bay,
    fallbackSrc: bayFallback,
  },
  [WindowTypes.BOW]: {
    src: bow,
    fallbackSrc: bowFallback,
  },
  [WindowTypes.CASEMENT]: {
    src: casement,
    fallbackSrc: casementFallback,
  },
  [WindowTypes.SINGLE_HUNG]: {
    src: singleHung,
    fallbackSrc: singleHungFallback,
  },
  [WindowTypes.GARDEN]: {
    src: garden,
    fallbackSrc: gardenFallback,
  },
  [WindowTypes.GLASS_BLOCK]: {
    src: glassBlock,
    fallbackSrc: glassBlockFallback,
  },
  [WindowTypes.HOPPER]: {
    src: hopper,
    fallbackSrc: hopperFallback,
  },
  [WindowTypes.SLIDING]: {
    src: sliding,
    fallbackSrc: slidingFallback,
  },
};

const icons: Config["icons"] = {
  [WindowTypes.FIXED]: WindowsIcon,
  [WindowTypes.DOUBLE_HUNG]: WindowsIcon,
  [WindowTypes.ARCHED]: WindowsIcon,
  [WindowTypes.BAY]: WindowsIcon,
  [WindowTypes.BOW]: WindowsIcon,
  [WindowTypes.CASEMENT]: WindowsIcon,
  [WindowTypes.SINGLE_HUNG]: WindowsIcon,
  [WindowTypes.GARDEN]: WindowsIcon,
  [WindowTypes.GLASS_BLOCK]: WindowsIcon,
  [WindowTypes.HOPPER]: WindowsIcon,
  [WindowTypes.SLIDING]: WindowsIcon,
};

export const WindowsConfigImages: Config = {
  categoryIcon: IconCategoryDoorsWindows,
  images,
  icons,
  defaultIcon: IconFloorPlan,
  defaultImage: {
    src: mainImage,
    fallbackSrc: mainImageFallback,
  },
};
