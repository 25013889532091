import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import MainImageFallback from "./assets/ElectricalPanelCategoryImage.png";
import MainImage from "./assets/ElectricalPanelCategoryImage.webp";
import { ReactComponent as CategoryIcon } from "./assets/icon_category.svg";
import { ReactComponent as MainPanelIcon } from "./assets/icon_main_panel.svg";
import { ReactComponent as SubPanelIcon } from "./assets/icon_sub_panel.svg";
import MainPanelFallback from "./assets/MainPanel.png";
import MainPanel from "./assets/MainPanel.webp";
import SubPanelFallback from "./assets/SubPanel.png";
import SubPanel from "./assets/SubPanel.webp";
import { ElectricalPanelTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.ELECTRICAL_PANEL,
  ElectricalPanelTypes
>["images"];

const icons: Config["icons"] = {
  [ElectricalPanelTypes.MAIN_PANEL]: MainPanelIcon,
  [ElectricalPanelTypes.SUB_PANEL]: SubPanelIcon,
};

const images: Config["images"] = {
  [ElectricalPanelTypes.MAIN_PANEL]: {
    src: MainPanel,
    fallbackSrc: MainPanelFallback,
  },
  [ElectricalPanelTypes.SUB_PANEL]: {
    src: SubPanel,
    fallbackSrc: SubPanelFallback,
  },
};

export const ElectricalPanelConfigImages: Config = {
  categoryIcon: CategoryIcon,
  defaultIcon: MainPanelIcon,
  defaultImage: {
    src: MainImage,
    fallbackSrc: MainImageFallback,
  },
  icons,
  images,
};
