import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import chandelierFallback from "./assets/fixture_chandelier.png";
import chandelier from "./assets/fixture_chandelier.webp";
import flushmountFallback from "./assets/fixture_flushmount.png";
import flushmount from "./assets/fixture_flushmount.webp";
import hangingFallback from "./assets/fixture_hanging.png";
import hanging from "./assets/fixture_hanging.webp";
import ledFallback from "./assets/fixture_led.png";
import led from "./assets/fixture_led.webp";
import monorailFallback from "./assets/fixture_monorail.png";
import monorail from "./assets/fixture_monorail.webp";
import recessedFallback from "./assets/fixture_recessed.png";
import recessed from "./assets/fixture_recessed.webp";
import semiFlushmountFallback from "./assets/fixture_semi_flushmount.png";
import semiFlushmount from "./assets/fixture_semi_flushmount.webp";
import wallSconcesFallback from "./assets/fixture_wall_sconces.png";
import wallSconces from "./assets/fixture_wall_sconces.webp";
import { ReactComponent as CategoryIcon } from "./assets/icon_category_lighting_fixtures.svg";
import { ReactComponent as IconChandelier } from "./assets/icon_chandelier.svg";
import { ReactComponent as IconFlushmount } from "./assets/icon_flushmount.svg";
import { ReactComponent as IconHanging } from "./assets/icon_hanging.svg";
import { ReactComponent as IconLED } from "./assets/icon_led_strip.svg";
import { ReactComponent as IconMonorail } from "./assets/icon_monorail.svg";
import { ReactComponent as IconRecessed } from "./assets/icon_recessed.svg";
import { ReactComponent as IconUnknown } from "./assets/icon_unknown.svg";
import { ReactComponent as IconWallSconce } from "./assets/icon_wall_sconce.svg";
import mainImageFallback from "./assets/lighting_fixture_main.png";
import mainImage from "./assets/lighting_fixture_main.webp";
import { LightingFixtureTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.LIGHTING_FIXTURE,
  LightingFixtureTypes
>["images"];

const icons: Config["icons"] = {
  [LightingFixtureTypes.RECESSED_LIGHT]: IconRecessed,
  [LightingFixtureTypes.WALL_SCONCES]: IconWallSconce,
  [LightingFixtureTypes.HANGING_LIGHT]: IconHanging,
  [LightingFixtureTypes.FLUSHMOUNT]: IconFlushmount,
  [LightingFixtureTypes.MONORAIL]: IconMonorail,
  [LightingFixtureTypes.LED]: IconLED,
  [LightingFixtureTypes.CHANDELIER]: IconChandelier,
  [LightingFixtureTypes.SEMI_FLUSHMOUNT]: IconFlushmount,
};

const images: Config["images"] = {
  [LightingFixtureTypes.FLUSHMOUNT]: {
    src: flushmount,
    fallbackSrc: flushmountFallback,
  },
  [LightingFixtureTypes.HANGING_LIGHT]: {
    src: hanging,
    fallbackSrc: hangingFallback,
  },
  [LightingFixtureTypes.RECESSED_LIGHT]: {
    src: recessed,
    fallbackSrc: recessedFallback,
  },
  [LightingFixtureTypes.WALL_SCONCES]: {
    src: wallSconces,
    fallbackSrc: wallSconcesFallback,
  },
  [LightingFixtureTypes.MONORAIL]: {
    src: monorail,
    fallbackSrc: monorailFallback,
  },
  [LightingFixtureTypes.SEMI_FLUSHMOUNT]: {
    src: semiFlushmount,
    fallbackSrc: semiFlushmountFallback,
  },
  [LightingFixtureTypes.CHANDELIER]: {
    src: chandelier,
    fallbackSrc: chandelierFallback,
  },
  [LightingFixtureTypes.LED]: { src: led, fallbackSrc: ledFallback },
};

export const LightingFixturesConfigImages: Config = {
  categoryIcon: CategoryIcon,
  images,
  icons,
  defaultIcon: IconUnknown,
  defaultImage: {
    src: mainImage,
    fallbackSrc: mainImageFallback,
  },
};
