import { ReactComponent as IconFloorPlan } from "assets/icons/categories/floor_plan.svg";
import { ReactComponent as IconUnknown } from "constants/guides/Electrical/LightingFixtures/assets/icon_unknown.svg";
import mainImageFallback from "constants/guides/Electrical/LightingFixtures/assets/lighting_fixture_main.png";
import mainImage from "constants/guides/Electrical/LightingFixtures/assets/lighting_fixture_main.webp";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import customFallback from "./assets/custom_shape.png";
import custom from "./assets/custom_shape.webp";
import lShapeFallback from "./assets/l_shape.png";
import lShape from "./assets/l_shape.webp";
import rectangleFallback from "./assets/rectangle_shape.png";
import rectangle from "./assets/rectangle_shape.webp";
import squareFallback from "./assets/square_shape.png";
import square from "./assets/square_shape.webp";
import { FloorPlanShapes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.SHAPES,
  FloorPlanShapes
>["images"];

const images: Config["images"] = {
  [FloorPlanShapes.SQUARE]: {
    src: square,
    fallbackSrc: squareFallback,
  },
  [FloorPlanShapes.RECTANGLE]: {
    src: rectangle,
    fallbackSrc: rectangleFallback,
  },
  [FloorPlanShapes.L_SHAPED]: {
    src: lShape,
    fallbackSrc: lShapeFallback,
  },
  [FloorPlanShapes.CUSTOM]: {
    src: custom,
    fallbackSrc: customFallback,
  },
};
const icons: Config["icons"] = {
  [FloorPlanShapes.SQUARE]: IconUnknown,
  [FloorPlanShapes.RECTANGLE]: IconUnknown,
  [FloorPlanShapes.L_SHAPED]: IconUnknown,
  [FloorPlanShapes.CUSTOM]: IconUnknown,
};

export const ShapesConfigImages: Config = {
  categoryIcon: IconFloorPlan,
  images,
  icons,
  defaultIcon: IconFloorPlan,
  defaultImage: {
    src: mainImage,
    fallbackSrc: mainImageFallback,
  },
};
