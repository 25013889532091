import { Point, Polygon } from "@flatten-js/core";
import { Line, Points } from "components/HBFloorPlan/HBFloorPlan.types";
import { Polygon as GeometricPolygon, polygonTranslate } from "geometric";
import { flatten, round as _round } from "lodash-es";
import { Coords } from "pages/Guides/types";
import { AUTO_ALIGN_OFFSET } from "shared/floorPlan/constants";
import { filterByIndexes } from "shared/util/list/filterByIndexes";

import { normalizeRadian } from "./polygon.utils";

export const extractCoords = <T extends Coords | Coords[]>(
  it: T
): T extends Coords[] ? Coords[] : Coords => {
  if (Array.isArray(it)) {
    return it.map((value) => ({ x: value.x, y: value.y })) as T extends Coords[]
      ? Coords[]
      : Coords;
  }

  return {
    x: it.x,
    y: it.y,
  } as T extends Coords[] ? Coords[] : Coords;
};

export const transformPointWithMatrix = (
  point: Coords,
  matrix: { a: number; b: number; c: number; d: number; tx: number; ty: number }
) => {
  return {
    x: matrix.a * point.x + matrix.c * point.y + matrix.tx,
    y: matrix.b * point.x + matrix.d * point.y + matrix.ty,
  };
};

/** @description Maps points to list.
 *
 * @example
 * [{x:0, y:0}, {x:1, y:1}] => [[0,0], [1,1]]
 * */
export const mapPointsToList = (points: Coords[]): GeometricPolygon => {
  if (!points) {
    return [];
  }
  return points.map(({ x, y }) => [x, y]);
};

/** @description Maps list of points to Coords.
 *
 * @example
 * [[0,0], [1,1]] => [{x:0, y:0}, {x:1, y:1}]
 * */
export const mapListOfPointsToCoords = (props: {
  pointsList: number[][];
  round?: boolean;
  precision?: number;
}): Coords[] => {
  const { pointsList, round = false, precision = 2 } = props;

  return pointsList.map(([x, y]) => ({
    x: round ? _round(x, precision) : x,
    y: round ? _round(y, precision) : y,
  }));
};

/** @description Translates points on x by distanceX and y by distanceY. */
export const translatePoints = (props: {
  points: Coords[];
  distanceX: number;
  distanceY: number;
}): Coords[] => {
  const { points, distanceX, distanceY } = props;

  const polygon = mapPointsToList(points) as GeometricPolygon;
  const xTranslatedPoints = polygonTranslate(polygon, 0, distanceX);
  const translatedPoints = polygonTranslate(xTranslatedPoints, 90, distanceY);

  return mapListOfPointsToCoords({ pointsList: translatedPoints, round: true });
};

/** @example
 * [{x:0, y:0}, {x:1, y:1}] => [0,0,1,1]
 * */
export const flattenPoints = (pointsList: Coords[]): number[] => {
  return flatten(mapPointsToList(pointsList));
};

export const filterPointsByIndexes = filterByIndexes;

export const rotatePointWithAngle = (point: Coords, angle: number): Coords => {
  const _angle = normalizeRadian(angle);
  const cosTheta = Math.cos(_angle);
  const sinTheta = Math.sin(_angle);

  return {
    x: point.x * cosTheta - point.y * sinTheta,
    y: point.x * sinTheta + point.y * cosTheta,
  };
};

export const pointToBox = (
  point: Coords,
  size: number | { width: number; height: number },
  extras?: { rotationRad?: number }
): [Coords, Coords, Coords, Coords] => {
  const width = typeof size === "number" ? size : size.width;
  const height = typeof size === "number" ? size : size.height;

  const coords: ReturnType<typeof pointToBox> = [
    {
      x: point.x - width / 2,
      y: point.y - height / 2,
    },
    {
      x: point.x + width / 2,
      y: point.y - height / 2,
    },
    {
      x: point.x + width / 2,
      y: point.y + height / 2,
    },
    {
      x: point.x - width / 2,
      y: point.y + height / 2,
    },
  ];

  if (extras?.rotationRad !== undefined) {
    const polygon = new Polygon(coords.map((c) => new Point(c.x, c.y)));
    const rotatedPolygon = polygon.rotate(
      extras.rotationRad,
      new Point(point.x, point.y)
    );
    const rotated = rotatedPolygon.vertices.map((v) => ({
      x: v.x,
      y: v.y,
    })) as ReturnType<typeof pointToBox>;

    return rotated;
  }

  return coords;
};

export const isEqualCoords = (p1: Coords, p2: Coords, precision = 8) => {
  if (p1 === p2) {
    return true;
  }

  const x1 = Number(p1.x).toFixed(precision);
  const y1 = Number(p1.y).toFixed(precision);
  const x2 = Number(p2.x).toFixed(precision);
  const y2 = Number(p2.y).toFixed(precision);

  return x1 === x2 && y1 === y2;
};

export const transformPoint = (point: Coords): Coords => {
  const { x, y } = point;
  const newX = -x;
  const newY = -y;
  return { x: newX, y: newY };
};

export const rotatePoint = (point: Coords): Coords => {
  return { x: -point.y, y: point.x };
};

export const isZeroCoords = (point: Coords): boolean => {
  return point.x === 0 && point.y === 0;
};

export const roundCoords = (coords: Coords, precision: number = 1) => ({
  x:
    precision === 0
      ? Math.round(coords.x)
      : Number(coords.x.toFixed(precision)),
  y:
    precision === 0
      ? Math.round(coords.y)
      : Number(coords.y.toFixed(precision)),
});

export const sumCoords = (p1: Coords, p2: Coords): Coords => {
  return {
    x: p1.x + p2.x,
    y: p1.y + p2.y,
  };
};

export const minusCoords = (p1: Coords, p2: Coords): Coords => {
  return {
    x: p1.x - p2.x,
    y: p1.y - p2.y,
  };
};

export const zoomInCoords = (point: Coords, scale: number): Coords => {
  return {
    x: point.x * scale,
    y: point.y * scale,
  };
};

export const zoomOutCoords = (point: Coords, scale: number): Coords => {
  return {
    x: point.x / scale,
    y: point.y / scale,
  };
};

export const covertFromCoordsToPoints = (coords: Coords[]): Point[] => {
  return coords.map((coord) => {
    return new Point(coord.x, coord.y);
  });
};

export const getNearAndFarPoints = (
  coords: Coords,
  line: Line
): [Coords, Coords] => {
  const { p1, p2 } = line;
  const fromPoint = new Point(coords.x, coords.y);

  const toPoint1 = new Point(p1.x, p1.y);
  const toPoint2 = new Point(p2.x, p2.y);

  const [distance1] = fromPoint.distanceTo(toPoint1);
  const [distance2] = fromPoint.distanceTo(toPoint2);

  if (distance1 < distance2) {
    return [p1, p2];
  } else {
    return [p2, p1];
  }
};

export const distanceBetweenCoords = (
  fromCoords: Coords,
  toCoords: Coords
): number => {
  const fromPoint = new Point(fromCoords.x, fromCoords.y);
  const toPoint = new Point(toCoords.x, toCoords.y);
  const [distance] = fromPoint.distanceTo(toPoint);
  return distance;
};

export const findCoordsPositionFromShapes = (
  shapes: Points[],
  targetPoint: Coords
): { shapeIndex: number; pointIndex: number } | null => {
  for (let shapeIndex = 0; shapeIndex < shapes.length; shapeIndex++) {
    const shape = shapes[shapeIndex];
    for (let pointIndex = 0; pointIndex < shape.length; pointIndex++) {
      if (isEqualCoords(shape[pointIndex], targetPoint)) {
        return { shapeIndex, pointIndex };
      }
    }
  }

  return null;
};

export const getAlignBetweenCoords = (params: {
  coords1: Coords;
  coords2: Coords;
  offset?: number;
}): { line: Line; offset: Coords } | undefined => {
  const { coords1, coords2, offset = AUTO_ALIGN_OFFSET } = params;
  const xOffset = coords1.x - coords2.x;
  const yOffset = coords1.y - coords2.y;

  if (Math.abs(xOffset) <= offset) {
    return {
      line: { p1: coords1, p2: { x: coords1.x, y: coords2.y } },
      offset: { x: xOffset, y: null },
    };
  }
  if (Math.abs(yOffset) <= offset) {
    return {
      line: { p1: coords1, p2: { x: coords2.x, y: coords1.y } },
      offset: { x: null, y: yOffset },
    };
  }
  return undefined;
};
