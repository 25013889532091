import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import batteryOperatedAlarmFallback from "./assets/battery_operated_alarm.png";
import batteryOperatedAlarm from "./assets/battery_operated_alarm.webp";
import hardwiredAlarmFallback from "./assets/hardwired_alarm.png";
import hardwiredAlarm from "./assets/hardwired_alarm.webp";
import { ReactComponent as BatteryOperatedAlarm } from "./assets/icon_battery_operated_alarm.svg";
import { ReactComponent as CategoryIcon } from "./assets/icon_category_smoke_alarm.svg";
import { ReactComponent as HardwiredAlarm } from "./assets/icon_hardwired_alarm.svg";
import mainImageFallback from "./assets/smoke_alarm_main.png";
import mainImage from "./assets/smoke_alarm_main.webp";
import { SmokeAlarmTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.SMOKE_ALARM,
  SmokeAlarmTypes
>["images"];

const icons: Config["icons"] = {
  [SmokeAlarmTypes.HARDWIRED]: HardwiredAlarm,
  [SmokeAlarmTypes.BATTERY_OPERATED]: BatteryOperatedAlarm,
};

const images: Config["images"] = {
  [SmokeAlarmTypes.HARDWIRED]: {
    src: hardwiredAlarm,
    fallbackSrc: hardwiredAlarmFallback,
  },
  [SmokeAlarmTypes.BATTERY_OPERATED]: {
    src: batteryOperatedAlarm,
    fallbackSrc: batteryOperatedAlarmFallback,
  },
};

export const SmokeAlarmConfigImages = {
  categoryIcon: CategoryIcon,
  defaultIcon: HardwiredAlarm,
  defaultImage: {
    src: mainImage,
    fallbackSrc: mainImageFallback,
  },
  icons,
  images,
};
