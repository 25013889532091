import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { SplitSystemExteriorUnitTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.SPLIT_SYSTEM_EXTERIOR_UNIT,
  SplitSystemExteriorUnitTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [SplitSystemExteriorUnitTypes.DEFAULT]: t("Exterior Unit"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [SplitSystemExteriorUnitTypes.DEFAULT]: t("Exterior Unit"),
});

export const SplitSystemConfigTexts: Config = {
  categoryName: (t) => t("Exterior Unit"),
  itemName: (t) => t("Exterior Unit"),
  categoryDescription: (t) => t("Exterior Unit description"),
  itemNames,
  typeNames,
};
