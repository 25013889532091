import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { PackagedSystemTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.PACKAGED_SYSTEM,
  PackagedSystemTypes
>["floorPlan"];

export const PackagedSystemConfigFloorPlan: Config = {
  sizes: {
    [PackagedSystemTypes.DEFAULT]: { width: 40, height: 40 },
  },
};
