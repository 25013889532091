import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { SplitSystemExteriorUnitTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.SPLIT_SYSTEM_EXTERIOR_UNIT,
  SplitSystemExteriorUnitTypes
>["floorPlan"];

export const SplitSystemExteriorUnitConfigFloorPlan: Config = {
  sizes: {
    [SplitSystemExteriorUnitTypes.DEFAULT]: { width: 40, height: 40 },
  },
};
