// Note(pavel): this should be the first import.
// https://github.com/welldone-software/why-did-you-render?tab=readme-ov-file#setup
// https://github.com/welldone-software/why-did-you-render/issues/231#issuecomment-1332201539
// eslint-disable-next-line filenames/no-index
import "./wdyr";
// eslint-disable-next-line filenames/no-index
import "./styles/_global.scss";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { HomebaseRouter } from "router/Router";

import { setupApp } from "./setupApp";

const container = document.getElementById("root");
const root = createRoot(container);

setupApp().then(() =>
  root.render(
    <StrictMode>
      <HomebaseRouter />
    </StrictMode>
  )
);
