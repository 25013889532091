import { useSelector } from "@xstate/react";
import { RoomType } from "constants/rooms/roomPieces";
import { isExteriorSpace } from "constants/rooms/rooms.utils";
import { useGlobalState } from "core/state/global/GlobalStateContext";
import { getActivePropertyContext } from "core/state/global/OrchestratorMachine/actions.utils";
import type { OrchestratorMachineContext } from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { RoomAndSpace } from "pages/RoomsAndSpaces/RoomsAndSpaces.types";
import { useMemo } from "react";

const orchestratorPropertyRoomsAndSpacesListSelector = (state: {
  context: OrchestratorMachineContext;
}) => getActivePropertyContext(state.context).rooms;

export const useOrchestratorRoomsAndSpaces = () => {
  const { orchestrator } = useGlobalState();
  return useSelector(
    orchestrator,
    orchestratorPropertyRoomsAndSpacesListSelector
  );
};

export const useOrchestratorRoomsMap = () => {
  const rooms = useOrchestratorRoomsAndSpaces();
  return useMemo(
    () =>
      rooms.reduce<Record<string, RoomAndSpace>>(
        (acc, room) => ({ ...acc, [room.id]: room }),
        {}
      ),
    [rooms]
  );
};
export const useOrchestratorExteriorRoomsAndSpaces = () => {
  const rooms = useOrchestratorRoomsAndSpaces();

  return useMemo(
    () => rooms.filter((room) => isExteriorSpace(room.type)),
    [rooms]
  );
};

export const useOrchestratorInteriorRoomsAndSpaces = () => {
  const rooms = useOrchestratorRoomsAndSpaces();

  return useMemo(
    () => rooms.filter((room) => !isExteriorSpace(room.type)),
    [rooms]
  );
};

export const useOrchestratorRoomsAndSpacesWithType = (
  type: RoomType
): RoomAndSpace[] => {
  const rooms = useOrchestratorRoomsAndSpaces();

  return useMemo(() => {
    return type ? rooms.filter((room) => type === room.type) : [];
  }, [rooms, type]);
};
