import { ReactComponent as NO_ICON } from "assets/icons/no_icon.svg";
import placeholderFallback from "constants/guides/Electrical/SmokeAlarms/assets/battery_operated_alarm.png";
import placeholderImage from "constants/guides/Electrical/SmokeAlarms/assets/battery_operated_alarm.webp";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { DoorCasingTrimTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.DOOR_CASING_TRIM,
  DoorCasingTrimTypes
>["images"];

//TODO: Fill me

const icons: Config["icons"] = {
  [DoorCasingTrimTypes.PLACEHOLDER]: NO_ICON,
};

const images: Config["images"] = {
  [DoorCasingTrimTypes.PLACEHOLDER]: {
    src: placeholderImage,
    fallbackSrc: placeholderFallback,
  },
};

export const DoorCasingTrimConfigImages = {
  categoryIcon: NO_ICON,
  defaultIcon: NO_ICON,
  defaultImage: {
    src: placeholderImage,
    fallbackSrc: placeholderFallback,
  },
  icons,
  images,
};
