import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import {
  GuideItemTypeConfig,
  RuleType,
} from "constants/guides/GuideItems.types";
import { RULE_DEFAULT_STICK_TO_THE_WALL } from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/PixiFloorPlanItem/PixiFloorPlanItem.rules";

import { ElectricalPanelTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.ELECTRICAL_PANEL,
  ElectricalPanelTypes
>["floorPlan"];

const sizes: Config["sizes"] = {
  [ElectricalPanelTypes.MAIN_PANEL]: { width: 28, height: 65 },
  [ElectricalPanelTypes.SUB_PANEL]: { width: 28, height: 65 },
};

const rules: Config["rules"] = {
  [ElectricalPanelTypes.MAIN_PANEL]: RULE_DEFAULT_STICK_TO_THE_WALL,
  [ElectricalPanelTypes.SUB_PANEL]: RULE_DEFAULT_STICK_TO_THE_WALL,
};

const ruleSets: Config["ruleSets"] = {
  [ElectricalPanelTypes.MAIN_PANEL]: [RuleType.STICK_TO_WALL],
  [ElectricalPanelTypes.SUB_PANEL]: [RuleType.STICK_TO_WALL],
};

export const ElectricalPanelConfigFloorPlan: Config = {
  sizes,
  rules,
  ruleSets,
};
