import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";

import { AdjustmentConfig } from "./Adjustments/config";
import { RoomConfig } from "./Room/config";
import { ShapesConfig } from "./Shapes/config";

export const RoomLayout = {
  [GuideItemTypeCategory.SHAPES]: ShapesConfig,
  [GuideItemTypeCategory.ADJUSTMENTS]: AdjustmentConfig,
  [GuideItemTypeCategory.ROOM]: RoomConfig,
};
