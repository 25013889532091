import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import {
  AllAllowed,
  GuideItemTypeConfig,
} from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";

import { DoorCasingTrimConfigFloorPlan } from "./config.floorPlan";
import { DoorCasingTrimConfigImages } from "./config.images";
import { DoorCasingTrimConfigTexts } from "./config.texts";
import { DoorCasingTrimTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.DOOR_CASING_TRIM,
  DoorCasingTrimTypes
>;

const types: Config["types"] = [DoorCasingTrimTypes.PLACEHOLDER];

const allAllowed: AllAllowed = undefined;

export const DoorCasingAndTrimConfig = {
  guideType: GuideType.MILLWORK_AND_TRIM,
  category: GuideItemTypeCategory.DOOR_CASING_TRIM,
  types,
  texts: DoorCasingTrimConfigTexts,
  images: DoorCasingTrimConfigImages,
  floorPlan: DoorCasingTrimConfigFloorPlan,
  typesOrder: getOrderedMap(types),
  allowedIntents: allAllowed,
  allowedInRooms: allAllowed,
} as const;
