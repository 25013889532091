import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";

import { LightingFixturesConfigFloorPlan } from "./config.floorPlan";
import { LightingFixturesConfigImages } from "./config.images";
import { LightingFixturesConfigTexts } from "./config.texts";
import { LightingFixtureTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.LIGHTING_FIXTURE,
  LightingFixtureTypes
>;

const types: Config["types"] = [
  LightingFixtureTypes.RECESSED_LIGHT,
  LightingFixtureTypes.WALL_SCONCES,
  LightingFixtureTypes.HANGING_LIGHT,
  LightingFixtureTypes.CHANDELIER,
  LightingFixtureTypes.FLUSHMOUNT,
  LightingFixtureTypes.SEMI_FLUSHMOUNT,
  LightingFixtureTypes.MONORAIL,
  LightingFixtureTypes.LED,
];

export const LightingFixturesConfig: Config = {
  guideType: GuideType.ELECTRICAL,
  category: GuideItemTypeCategory.LIGHTING_FIXTURE,
  types,
  typesOrder: getOrderedMap(types),
  texts: LightingFixturesConfigTexts,
  images: LightingFixturesConfigImages,
  floorPlan: LightingFixturesConfigFloorPlan,
  allowedInRooms: undefined,
  allowedIntents: undefined,
} as const;
