import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";
import { ROOMS_PIECES_VALUES } from "constants/rooms/roomPieces";
import { isExteriorSpace } from "constants/rooms/rooms.utils";

import { SplitSystemConfigFloorPlan } from "./config.floorPlan";
import { SplitSystemConfigImages } from "./config.images";
import { SplitSystemConfigTexts } from "./config.texts";
import { SplitSystemTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.SPLIT_SYSTEM,
  SplitSystemTypes
>;

const types: Config["types"] = [SplitSystemTypes.DEFAULT];

export const SplitSystemConfig: Config = {
  guideType: GuideType.MECHANICAL,
  category: GuideItemTypeCategory.SPLIT_SYSTEM,
  types,
  typesOrder: getOrderedMap(types),
  texts: SplitSystemConfigTexts,
  images: SplitSystemConfigImages,
  floorPlan: SplitSystemConfigFloorPlan,
  allowedInRooms: ROOMS_PIECES_VALUES.filter((room) => !isExteriorSpace(room)),
  allowedIntents: undefined,
} as const;
