import {
  ELECTRICAL_PANEL_GUIDE_ENABLED,
  FLOOR_MATERIAL_GUIDE_ENABLED,
  WINDOWS_AND_DOORS_GUIDE_ENABLED,
} from "./env";
import { GuideItemTypeCategory } from "./guides/GuideItems.enums";
import { GuideType } from "./guides/GuideType";

export const ENABLED_GUIDES: Partial<Record<GuideType, boolean>> = {
  [GuideType.ELECTRICAL]: true,
  [GuideType.WINDOWS_AND_DOORS]: WINDOWS_AND_DOORS_GUIDE_ENABLED,
  [GuideType.FLOOR_MATERIAL]: FLOOR_MATERIAL_GUIDE_ENABLED,
};

export const DISABLED_CATEGORIES: Partial<
  Record<GuideItemTypeCategory, boolean>
> = {
  [GuideItemTypeCategory.ELECTRICAL_PANEL]: !ELECTRICAL_PANEL_GUIDE_ENABLED,
};
