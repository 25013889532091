import { Point, Polygon } from "@flatten-js/core";
import { Coords } from "pages/Guides/types";
import { mapPointsToList } from "shared/floorPlan/utils/points.utils";

import { intersectsShape, IntersectsShapeParams } from "./intersectsShape";
import { isInsideShape } from "./isInsideShape";

interface ShouldStayOutsideShapesRuleParams
  extends Omit<IntersectsShapeParams, "shape"> {
  shapes: Array<Coords[]> | Array<Polygon>;
  start?: Coords;
  end: Coords;
}

interface ShouldStayOutsideShapesRuleResult {
  success: boolean;
  newPosition: Coords;
  Start: Point;
  End: Point;
  Shapes: Polygon[];
  Item: Polygon;
}

/**
 * Checks if an item is outside a shape.
 *
 * If not, then it tries to find last correct
 * position in the path from start to end.
 */
export const shouldStayOutsideShapesRule = (
  params: ShouldStayOutsideShapesRuleParams
): ShouldStayOutsideShapesRuleResult => {
  const { shapes, item, end, start } = params;
  const Shapes = shapes.map((shape) =>
    shape instanceof Polygon ? shape : new Polygon(mapPointsToList(shape))
  );
  const Item =
    item instanceof Polygon ? item : new Polygon(mapPointsToList(item));

  const Start = start ? new Point(start.x, start.y) : new Point(end.x, end.y);
  const End = new Point(end.x, end.y);

  const intersects = Shapes.some(
    (shape) =>
      intersectsShape({ item: Item, shape }).intersects ||
      isInsideShape({ item: Item, shape }).isInside ||
      isInsideShape({ shape: Item, item: shape }).isInside
  );

  if (!intersects) {
    return {
      success: true,
      Item,
      Shapes,
      Start,
      End,
      newPosition: {
        x: end.x,
        y: end.y,
      },
    };
  }

  return {
    success: false,
    Item,
    Shapes,
    Start,
    End,
    newPosition: {
      x: (start || end).x,
      y: (start || end).y,
    },
  };
};
