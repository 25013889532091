export enum WindowTypes {
  FIXED = "fixed",
  SINGLE_HUNG = "single_hung",
  DOUBLE_HUNG = "double_hung",
  CASEMENT = "casement",
  SLIDING = "sliding",
  HOPPER = "hopper",
  BAY = "bay",
  BOW = "bow",
  GARDEN = "garden",
  GLASS_BLOCK = "glass_block",
  ARCHED = "arched",
}
