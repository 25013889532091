import { Polygon } from "@flatten-js/core";
import { Coords } from "pages/Guides/types";
import { mapPointsToList } from "shared/floorPlan/utils/points.utils";

export interface IsInsideShapeParams {
  shape: Coords[] | Polygon;
  item: Coords[] | Polygon;
}
export interface IsInsideShapeResult {
  isInside: boolean;
  Shape: Polygon;
  Item: Polygon;
}

/**
 * Checks if an item is inside a shape.
 *
 * Returns boolean result plus intermediate classes.
 */
export const isInsideShape = (
  params: IsInsideShapeParams
): IsInsideShapeResult => {
  const { shape, item } = params;

  const Shape =
    shape instanceof Polygon ? shape : new Polygon(mapPointsToList(shape));
  const Item =
    item instanceof Polygon ? item : new Polygon(mapPointsToList(item));
  const isInside = Shape.contains(Item);

  return { isInside, Shape, Item };
};
