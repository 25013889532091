import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { HIPsRoomRouteParams, HIPsRouteParams } from "router/models";

export function useHIPsParams() {
  const params = useParams();

  return useMemo<
    HIPsRouteParams & Partial<Pick<HIPsRoomRouteParams, "roomId">>
  >(
    () => ({
      propertyId: Number(params.propertyId),
      hipsId: params.hipsId,
      roomId: params.roomId,
    }),
    [params]
  );
}
