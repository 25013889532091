import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";

import { FurnaceConfigFloorPlan } from "./config.floorPlan";
import { FurnaceConfigImages } from "./config.images";
import { FurnaceConfigTexts } from "./config.texts";
import { FurnaceTypes } from "./enums";

type Config = GuideItemTypeConfig<GuideItemTypeCategory.FURNACE, FurnaceTypes>;

const types: Config["types"] = [FurnaceTypes.DEFAULT];

export const FurnaceConfig: Config = {
  guideType: GuideType.MECHANICAL,
  category: GuideItemTypeCategory.FURNACE,
  types,
  typesOrder: getOrderedMap(types),
  texts: FurnaceConfigTexts,
  images: FurnaceConfigImages,
  floorPlan: FurnaceConfigFloorPlan,
  allowedInRooms: undefined,
  allowedIntents: undefined,
} as const;
