import { DropShadowFilterOptions } from "@pixi/filter-drop-shadow";
import { to0xHex } from "common/utils/colors";
import { Graphics } from "pixi.js";
import colors from "styles/_jscolors.module.scss";

export const AREA_FILL_COLOR = to0xHex(colors.colorWhite);
export const WALL_COLOR = to0xHex(colors.colorFloorPlanWall);
export const INACTIVE_WALL_COLOR = to0xHex(colors.colorGrey300);
export const INACTIVE_AREA_FILL_COLOR: Parameters<Graphics["beginFill"]> = [
  0, 0,
];

export const WALL_COLOR_THIN = to0xHex(colors.colorFloorPlanWallThin);
export const WALL_COLOR_THIN_HOVER = to0xHex(
  colors.colorFloorPlanWallThinHover
);
export const WALL_COLOR_THIN_ERROR = to0xHex(
  colors.colorFloorPlanWallThinError
);

export const CORNER_MARKER_COLOR = to0xHex(colors.mainBlue);
export const CORNER_MARKER_COLOR_HOVER = to0xHex(
  colors.colorFloorPlanWallThinHover
);
export const CORNER_MARKER_COLOR_ERROR = to0xHex(
  colors.colorFloorPlanWallThinError
);
export const CORNER_MARKER_COLOR_STROKE = to0xHex(colors.colorWhite);

export const CORNER_VIRTUAL_WALL_COLOR = to0xHex(
  colors.colorFloorPlanVirtualWall
);

export const WALL_ELEVATION_OPTIONS: Partial<DropShadowFilterOptions> = {
  color: to0xHex(colors.colorElevation2),
  alpha: 0.15,
  blur: 4,
  quality: 3,
};

export const WINDOW_COLOR = to0xHex(colors.mainBlue);
export const WINDOW_BASE_COLOR = to0xHex(colors.colorWhite);
export const GRID_BG_COLOR = to0xHex(colors.colorGrey200);
export const GRID_LINE_COLOR = to0xHex(colors.colorGrey400);
export const HALF_WALL_PATTERN_LINE_COLOR = to0xHex(
  colors.colorHalfWallPatternLine
);
export const ALIGN_LINE_COLOR = to0xHex(colors.colorFloorPlanWallThin);
export const DISTANCEHELPER_LINE_COLOR = to0xHex(colors.colorFloorPlanWallThin);
