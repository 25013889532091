import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";
import {
  WALL_AND_CEILING_MATERIAL_INTENTS_ALLOWED,
  WALL_AND_CEILING_MATERIAL_ROOMS_ALLOWED,
} from "constants/guides/WallAndCeilingMaterial/WallAndCeilingMaterial.const";

import { WallMaterialConfigFloorPlan } from "./config.floorPlan";
import { WallMaterialsConfigImages } from "./config.images";
import { WallMaterialsConfigTexts } from "./config.texts";
import { WallMaterialTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.WALL_MATERIAL,
  WallMaterialTypes
>;

const types: Config["types"] = [
  WallMaterialTypes.DRYWALL,
  WallMaterialTypes.LATH_AND_PLASTER,
  WallMaterialTypes.WOOD,
  WallMaterialTypes.TILE,
  WallMaterialTypes.BRICK,
  WallMaterialTypes.STONE,
];

const allowedInRooms: Config["allowedInRooms"] =
  WALL_AND_CEILING_MATERIAL_ROOMS_ALLOWED;
const allowedIntents: Config["allowedIntents"] =
  WALL_AND_CEILING_MATERIAL_INTENTS_ALLOWED;

export const WallMaterialsConfig: Config = {
  guideType: GuideType.WALL_AND_CEILING_MATERIAL,
  category: GuideItemTypeCategory.WALL_MATERIAL,
  texts: WallMaterialsConfigTexts,
  images: WallMaterialsConfigImages,
  floorPlan: WallMaterialConfigFloorPlan,
  types,
  typesOrder: getOrderedMap(types),
  allowedInRooms,
  allowedIntents,
};
