import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { InteriorFloorMaterialTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL,
  InteriorFloorMaterialTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [InteriorFloorMaterialTypes.TILE]: t("Tile"),
  [InteriorFloorMaterialTypes.BRICK]: t("Brick"),
  [InteriorFloorMaterialTypes.CARPET]: t("Carpet"),
  [InteriorFloorMaterialTypes.WOOD]: t("Wood"),
  [InteriorFloorMaterialTypes.VINYL]: t("Vinyl"),
  [InteriorFloorMaterialTypes.CONCRETE]: t("Concrete"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [InteriorFloorMaterialTypes.TILE]: t("Tile"),
  [InteriorFloorMaterialTypes.BRICK]: t("Brick"),
  [InteriorFloorMaterialTypes.CARPET]: t("Carpet"),
  [InteriorFloorMaterialTypes.WOOD]: t("Wood"),
  [InteriorFloorMaterialTypes.VINYL]: t("Vinyl"),
  [InteriorFloorMaterialTypes.CONCRETE]: t("Concrete"),
});

export const InteriorFloorMaterialConfigImages: Config = {
  categoryName: (t) => t("Interior Floor Material"),
  itemName: (t) => t("Interior Floor Material"),
  categoryDescription: (t) =>
    t(
      "Interior floor materials are essential elements that contribute to a home's overall ambiance and usability. Choosing the right flooring balances aesthetics and function, as it complements the design and supports daily activities.  The large variety of options allows homeowners to tailor their choices to meet specific needs and preferences."
    ),
  itemNames,
  typeNames,
};
