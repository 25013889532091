import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";
import { ROOMS_PIECES_VALUES } from "constants/rooms/roomPieces";
import { isExteriorSpace } from "constants/rooms/rooms.utils";

import { MiniSplitSystemConfigFloorPlan } from "./config.floorPlan";
import { MiniSplitSystemConfigImages } from "./config.images";
import { MiniSplitSystemConfigTexts } from "./config.texts";
import { MiniSplitSystemTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.MINISPLIT_SYSTEM,
  MiniSplitSystemTypes
>;

const types: Config["types"] = [MiniSplitSystemTypes.DEFAULT];

export const MiniSplitSystemConfig: Config = {
  guideType: GuideType.MECHANICAL,
  category: GuideItemTypeCategory.MINISPLIT_SYSTEM,
  types,
  typesOrder: getOrderedMap(types),
  texts: MiniSplitSystemConfigTexts,
  images: MiniSplitSystemConfigImages,
  floorPlan: MiniSplitSystemConfigFloorPlan,
  allowedInRooms: ROOMS_PIECES_VALUES.filter((room) => !isExteriorSpace(room)),
  allowedIntents: undefined,
} as const;
