import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import DefaultFallback from "./assets/Default.png";
import Default from "./assets/Default.webp";
import { ReactComponent as IconDefault } from "./assets/icon_default.svg";
import { SplitSystemExteriorUnitTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.SPLIT_SYSTEM_EXTERIOR_UNIT,
  SplitSystemExteriorUnitTypes
>["images"];

const icons: Config["icons"] = {
  [SplitSystemExteriorUnitTypes.DEFAULT]: IconDefault,
};

const images: Config["images"] = {
  [SplitSystemExteriorUnitTypes.DEFAULT]: {
    src: Default,
    fallbackSrc: DefaultFallback,
  },
};

export const SplitSystemConfigImages: Config = {
  categoryIcon: icons[SplitSystemExteriorUnitTypes.DEFAULT],
  images,
  icons,
  defaultIcon: icons[SplitSystemExteriorUnitTypes.DEFAULT],
  defaultImage: images[SplitSystemExteriorUnitTypes.DEFAULT],
};
