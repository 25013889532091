import { ReactComponent as IconFloorPlan } from "assets/icons/categories/floor_plan.svg";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { ReactComponent as IconCategoryDoorsWindows } from "constants/guides/WindowsAndDoors/assets/icon_category_doors_windows.svg";

import mainImageFallback from "./assets/doors_main.png";
import mainImage from "./assets/doors_main.webp";
import exteriorFallback from "./assets/exterior.png";
import exterior from "./assets/exterior.webp";
import { ReactComponent as DoorsIcon } from "./assets/icon_doors_main.svg";
import interiorFallback from "./assets/interior.png";
import interior from "./assets/interior.webp";
import { DoorTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.DOOR,
  DoorTypes
>["images"];

const images: Config["images"] = {
  [DoorTypes.INTERIOR]: {
    src: interior,
    fallbackSrc: interiorFallback,
  },
  [DoorTypes.EXTERIOR]: {
    src: exterior,
    fallbackSrc: exteriorFallback,
  },
};

const icons: Config["icons"] = {
  [DoorTypes.INTERIOR]: DoorsIcon,
  [DoorTypes.EXTERIOR]: DoorsIcon,
};

export const DoorsConfigImages: Config = {
  categoryIcon: IconCategoryDoorsWindows,
  images,
  icons,
  defaultIcon: IconFloorPlan,
  defaultImage: {
    src: mainImage,
    fallbackSrc: mainImageFallback,
  },
};
