import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { floorPlanItemToPolygon } from "pages/Guides/components/FloorPlan/FloorPlanItems/FloorPlanItems.utils";
import { getShouldBeInTheWallRule } from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/PixiFloorPlanItem/PixiFloorPlanItem.rules";
import {
  RestrictPositionRuleFn,
  UpdatePositionParams,
} from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/PixiFloorPlanItem/PixiFloorPlanItem.types";
import { WALL_ITEMS_SPACING, WALL_THICKNESS } from "shared/floorPlan/constants";
import { isEqualCoords } from "shared/floorPlan/utils/points.utils";
import {
  getEndPointsFromCenter,
  getEndPointsOfWindow,
} from "shared/floorPlan/utils/window.utils";

import { WindowTypes } from "./enums";
import { Window } from "./types";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.WINDOWS,
  WindowTypes
>["floorPlan"];

const WindowStickToTheWallRule: RestrictPositionRuleFn = (
  updatePositionParams: UpdatePositionParams
) => {
  const { floorPlanItem, start, ctx } = updatePositionParams;
  const window = floorPlanItem.item as Window;
  const rotationRad = window.rotationRad;

  const rule = getShouldBeInTheWallRule({
    wallsGap: {
      absoluteGap: WALL_THICKNESS,
    },
    itemsGap: {
      absoluteGap: WALL_ITEMS_SPACING,
    },
    rotationRad,
  })(updatePositionParams);

  if (!start) {
    const { item } = floorPlanItemToPolygon({
      item: floorPlanItem,
      ctx,
      coords: rule.coords,
    });

    const itemEndPoints = getEndPointsOfWindow({
      points: item,
    });

    rule.itemEndPoints = itemEndPoints;
  } else if (!isEqualCoords(rule.coords, start)) {
    const length = Math.abs(
      window.itemEndPoints[0].y - window.itemEndPoints[1].y
    );
    const itemEndPoints = getEndPointsFromCenter({
      center: rule.coords,
      length,
    });
    rule.itemEndPoints = itemEndPoints;
  }

  return rule;
};

const sizes: Config["sizes"] = {
  [WindowTypes.FIXED]: { width: 15, height: 80 },
  [WindowTypes.DOUBLE_HUNG]: { width: 15, height: 80 },
  [WindowTypes.ARCHED]: { width: 15, height: 80 },
  [WindowTypes.BAY]: { width: 15, height: 80 },
  [WindowTypes.BOW]: { width: 15, height: 80 },
  [WindowTypes.CASEMENT]: { width: 15, height: 80 },
  [WindowTypes.SINGLE_HUNG]: { width: 15, height: 80 },
  [WindowTypes.GARDEN]: { width: 15, height: 80 },
  [WindowTypes.GLASS_BLOCK]: { width: 15, height: 80 },
  [WindowTypes.HOPPER]: { width: 15, height: 80 },
  [WindowTypes.SLIDING]: { width: 15, height: 80 },
};

const rules: Config["rules"] = {
  [WindowTypes.FIXED]: WindowStickToTheWallRule,
  [WindowTypes.DOUBLE_HUNG]: WindowStickToTheWallRule,
  [WindowTypes.ARCHED]: WindowStickToTheWallRule,
  [WindowTypes.BAY]: WindowStickToTheWallRule,
  [WindowTypes.BOW]: WindowStickToTheWallRule,
  [WindowTypes.CASEMENT]: WindowStickToTheWallRule,
  [WindowTypes.SINGLE_HUNG]: WindowStickToTheWallRule,
  [WindowTypes.GARDEN]: WindowStickToTheWallRule,
  [WindowTypes.GLASS_BLOCK]: WindowStickToTheWallRule,
  [WindowTypes.HOPPER]: WindowStickToTheWallRule,
  [WindowTypes.SLIDING]: WindowStickToTheWallRule,
};

export const WindowsConfigFloorPlan: Config = {
  sizes,
  rules,
};
