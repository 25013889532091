import { ORCHESTRATOR_ACTIONS } from "core/state/global/OrchestratorMachine/constants";
import { useOrchestratorCallback } from "core/state/global/OrchestratorMachine/hooks";
import { useOrchestratorEphemeral } from "core/state/hooks/useOrchestratorEphemeral";
import { FC } from "react";

import { SidebarBugReport } from "./components/SidebarBugReport/SidebarBugReport";
import { SidebarHelp } from "./components/SidebarHelp/SidebarHelp";
import { SidebarSubscribe } from "./components/SidebarSubscribe/SidebarSubscribe";
import { SidebarSuggestion } from "./components/SidebarSuggestion/SidebarSuggestion";
import { useOpenSidebarByQueryParam } from "./hooks/useOpenSidebarByQueryParam";

export const SidebarForms: FC = () => {
  const ephemeral = useOrchestratorEphemeral();
  const sidebar = ephemeral?.sidebar;
  useOpenSidebarByQueryParam();
  const handleClose = useOrchestratorCallback(
    ORCHESTRATOR_ACTIONS.SIDEBAR.CLOSE_SIDEBAR
  );

  return (
    <>
      <SidebarBugReport isOpen={sidebar === "bug"} onClose={handleClose} />
      <SidebarSuggestion
        isOpen={sidebar === "suggestion"}
        onClose={handleClose}
      />
      <SidebarHelp isOpen={sidebar === "help"} onClose={handleClose} />
      <SidebarSubscribe
        isOpen={sidebar === "subscribe"}
        onClose={handleClose}
      />
    </>
  );
};
