import { Point } from "@flatten-js/core";
import { Line } from "components/HBFloorPlan/HBFloorPlan.types";
import { maxBy, minBy } from "lodash-es";
import { FloorPlanItem } from "pages/Guides/components/FloorPlan/FloorPlanItems/FloorPlanItems.types";
import { floorPlanItemToPolygon } from "pages/Guides/components/FloorPlan/FloorPlanItems/FloorPlanItems.utils";
import { FloorPlanItemsContextType } from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/context/FloorPlanItemsContext.types";
import { Gap } from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/PixiFloorPlanItem/PixiFloorPlanItem.rules";
import { Coords } from "pages/Guides/types";
import {
  getSegmentAngleInRad,
  getSegmentLength,
} from "shared/floorPlan/utils/line.utils";
import {
  getNearAndFarPoints,
  isEqualCoords,
} from "shared/floorPlan/utils/points.utils";
import {
  getPointFromStickedWall,
  getStickedWallFromShape,
  shortestSegmentFromPointToShape,
} from "shared/floorPlan/utils/polygon.utils";

import { isInTheWall } from "./isInTheWall";

interface ShouldBeInTheWallRuleParams {
  end: Coords;
  start?: Coords;
  shape: Coords[];
  floorPlanItem: FloorPlanItem;
  ctx: FloorPlanItemsContextType;
  wallsGap?: Gap;
  offsetPosition: number;
  offsetRotationRad: number;
  rotationRad: number;
}
interface ShouldBeInTheWallRuleResult {
  success: boolean;
  newPosition: Coords;
  rotation: number;
}

const calculateItemLength = (item: Coords[], rotationRad: number): number => {
  const isLineRotationLessThan45Deg =
    Math.abs(rotationRad) % Math.PI <= Math.PI / 4;

  const minX = minBy(item, "x").x;
  const maxX = maxBy(item, "x").x;
  const minY = minBy(item, "y").y;
  const maxY = maxBy(item, "y").y;

  const width = maxX - minX;
  const height = maxY - minY;

  if (isLineRotationLessThan45Deg) {
    return height;
  } else {
    return width;
  }
};

const getAvailablePosition = (
  item: Coords[],
  rotationRad: number,
  coords: Coords,
  stickedWall: Line
): Coords => {
  const [nearCoords, farCoords] = getNearAndFarPoints(coords, stickedWall);

  const itemLength = calculateItemLength(item, rotationRad);
  const lineLength = getSegmentLength({ p1: nearCoords, p2: farCoords });
  const ndx = farCoords.x - nearCoords.x;
  const ndy = farCoords.y - nearCoords.y;

  const vx = ndx / lineLength;
  const vy = ndy / lineLength;

  const newX = nearCoords.x + (vx * itemLength) / 2;
  const newY = nearCoords.y + (vy * itemLength) / 2;

  return { x: newX, y: newY };
};

const calculateItemSlopeAngleRadianFromStickedWall = (line: Line): number => {
  return getSegmentAngleInRad(line) - Math.PI / 2;
};

export const shouldBeInTheWallRule = (
  params: ShouldBeInTheWallRuleParams
): ShouldBeInTheWallRuleResult => {
  const {
    shape,
    start,
    end,
    floorPlanItem,
    ctx,
    wallsGap,
    offsetPosition = 0,
    offsetRotationRad = 0,
    rotationRad = 0,
  } = params;
  const { percentGap, absoluteGap } = wallsGap || {};

  const itemPosition = floorPlanItem.item.coords;

  if (start) {
    const deltaX = Math.sin(rotationRad + Math.PI / 2) * offsetPosition;
    const deltaY = Math.cos(rotationRad - Math.PI / 2) * offsetPosition;

    const stickedWall = getStickedWallFromShape(
      shape,
      new Point(itemPosition.x - deltaX, itemPosition.y - deltaY)
    );
    if (stickedWall) {
      const newPosition = getPointFromStickedWall(stickedWall, end);

      const { item } = floorPlanItemToPolygon({
        item: floorPlanItem,
        ctx,
        coords: newPosition,
        percentGap,
        absoluteGap,
      });

      let isStick = isInTheWall({
        item,
        rotation: rotationRad,
        stickedWall,
      });

      if (!isStick && isEqualCoords(start, end)) {
        const availablePosition = getAvailablePosition(
          item,
          rotationRad,
          newPosition,
          stickedWall
        );
        newPosition.x = availablePosition.x;
        newPosition.y = availablePosition.y;
        isStick = true;
      }

      newPosition.x += deltaX;
      newPosition.y += deltaY;

      return {
        success: isStick,
        newPosition: isStick ? newPosition : end,
        rotation: rotationRad,
      };
    } else {
      return {
        success: false,
        newPosition: end,
        rotation: rotationRad,
      };
    }
  } else {
    const segment = shortestSegmentFromPointToShape(end, shape);
    const stickedWall = getStickedWallFromShape(shape, segment.pe);
    if (stickedWall) {
      const _rotation =
        calculateItemSlopeAngleRadianFromStickedWall(stickedWall) +
        offsetRotationRad;

      const deltaX = Math.sin(_rotation + Math.PI / 2) * offsetPosition;
      const deltaY = Math.cos(_rotation - Math.PI / 2) * offsetPosition;

      const newPosition = {
        x: segment.pe.x + deltaX,
        y: segment.pe.y + deltaY,
      };

      return {
        success: true,
        newPosition: newPosition,
        rotation: _rotation,
      };
    } else {
      return {
        success: false,
        newPosition: end,
        rotation: rotationRad,
      };
    }
  }
};
