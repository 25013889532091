import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { BoilerTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.BOILER,
  BoilerTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [BoilerTypes.DEFAULT]: t("Default"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [BoilerTypes.DEFAULT]: t("Defaults"),
});

export const BoilerConfigTexts: Config = {
  categoryName: (t) => t("Boiler"),
  itemName: (t) => t("Boiler"),
  categoryDescription: (t) =>
    t(
      "Designed to heat water for various residential or commercial uses, such as radiators, underfloor heating systems, and hot water supply. It operates by burning a fuel source like natural gas or oil to heat water, which then circulates throughout the heating system to provide warmth. Modern boilers are known for their efficiency and can be integral to ensuring consistent and comfortable indoor temperatures, especially during colder months."
    ),
  itemNames,
  typeNames,
};
