import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { MiniSplitSystemTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.MINISPLIT_SYSTEM,
  MiniSplitSystemTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [MiniSplitSystemTypes.DEFAULT]: t("Default"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [MiniSplitSystemTypes.DEFAULT]: t("Defaults"),
});

export const MiniSplitSystemConfigTexts: Config = {
  categoryName: (t) => t("Mini-Split Systems"),
  itemName: (t) => t("Mini-Split System"),
  categoryDescription: (t) =>
    t(
      "Mini-split air conditioning systems, also known as ductless systems, comprise separate indoor and outdoor units.  Widely used for single rooms or specific zones, mini-splits offer efficient temperature control through refrigerant lines and wiring connecting the units. Installation is less invasive than traditional ducted systems, allowing for flexible placement and zoning options."
    ),
  itemNames,
  typeNames,
};
