import {
  FloorPlanItemsContextItem,
  FloorPlanItemsContextType,
} from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/context/FloorPlanItemsContext.types";
import { Coords, Size } from "pages/Guides/types";
import { getPixiContainerGeometry } from "shared/floorPlan/utils/pixi.utils";
import { pointToBox } from "shared/floorPlan/utils/points.utils";
import { rotatePolygon } from "shared/floorPlan/utils/polygon.utils";

import { FloorPlanItem } from "./FloorPlanItems.types";

export const getFloorPlanItemKey = (item: FloorPlanItem) => {
  const intents = item.intents.filter(Boolean).join("_");

  return `${item.item.id}_${item.item.index}_${intents}`;
};

export const getFloorPlanItemGeometry = (params: {
  floorPlanItem: FloorPlanItem;
  ctx: FloorPlanItemsContextType;
}): ReturnType<typeof getPixiContainerGeometry> => {
  const { floorPlanItem, ctx } = params;
  const { container } =
    ctx.getItems()[getFloorPlanItemKey(floorPlanItem)] || {};

  // Note(pavel): If there is no container - we'll try to fallback without pixi data.
  if (!container) {
    const rotationRad = floorPlanItem.item.rotationRad || 0;
    const coords = floorPlanItem.item.coords;
    const size = floorPlanItem.size;
    const centerOffset = { x: 0, y: 0 };
    const normalCorners = pointToBox(coords, size);
    const corners = rotatePolygon(normalCorners, rotationRad, coords);

    return {
      coords: floorPlanItem.item.coords,
      normalWidth: size.width,
      normalHeight: size.height,
      normalCenterOffset: centerOffset,
      normalCorners,
      corners,
      centerOffset,
    };
  }

  return getPixiContainerGeometry(container);
};

/**
 * Note(pavel):
 *
 * @deprecated Use getFloorPlanItemGeometry to more granular and precise operations.
 */
export const floorPlanItemToPolygonFromPixiContainer = (
  container: FloorPlanItemsContextItem["container"],
  otherParams: {
    item: FloorPlanItem;
    rotationParam?: number;
  }
) => {
  const { item, rotationParam } = otherParams;
  const { width, height, x, y } = container.getLocalBounds() || {};
  const rotation = container.rotation;

  if (!width || !height) {
    return undefined;
  }

  const size = {
    width,
    height,
  };
  // Note(pavel): Looks like this is not the best way of getting the center offset and the corners of an item,
  // so ultimately it leads to incorrect calculation in rules down the pipeline.
  // According to rules everything is OK and in bounds, but because of a diff between data layer's center offset
  // and real center offset we get some misaligned results: once we try to render calculated data.
  //
  // It seems that in some cases at least the real vertical offset is different to the offset
  // we get here (e.g. here we get 0 for wall sconces, but it doesn't look like zero)
  //
  // Either we need a way to get the whole polygon of an item, so we could calculate offset and bounds another way,
  // or I am missing something and we need more eyes on this scenario.
  // Right now the WALL_OFFSET is bigger than it should be because of this case described above.
  const centerOffset = {
    x: x + width / 2,
    y: y + height / 2,
  };

  let rotationRad: number | undefined;

  // Note(pavel): If we did not provide overrides to the rotation
  if (rotationParam === undefined) {
    // Note(pavel): And item is rotated but the container wasn't.
    if (rotation === 0 && item.item.rotationRad !== 0) {
      // Note(pavel): This means we have something special in item's render
      // and it's better to ignore whatever rotation we have in data and follow rendered thing.
      rotationRad = rotation;
    }
  }

  return {
    size,
    centerOffset,
    rotationRad,
  };
};

/**
 * Note(pavel):
 *
 * @deprecated Use getFloorPlanItemGeometry to more granular and precise operations.
 */
export const floorPlanItemToPolygon = (params: {
  item: FloorPlanItem;
  ctx: undefined | FloorPlanItemsContextType;
  ctxResult?: ReturnType<typeof floorPlanItemToPolygonFromPixiContainer>;
  coords?: Coords;
  rotationRad?: number;
  percentGap?: number | Size;
  absoluteGap?: number | Size;
  centerOffset?: Coords;
  centerOffsetDisabled?: boolean;
}) => {
  const {
    centerOffset: centerOffsetParam,
    ctx,
    ctxResult,
    item,
    coords: coordsParam,
    rotationRad: rotationParam,
    absoluteGap,
    percentGap,
    centerOffsetDisabled,
  } = params;

  if (item.item.points) {
    return {
      coords: coordsParam,
      centerOffset: centerOffsetParam,
      item: item.item.points,
    };
  }

  let coords = coordsParam || item.item.coords;
  let size = ctxResult ? ctxResult.size : item.size;
  let centerOffset = ctxResult ? ctxResult.centerOffset : centerOffsetParam;
  let rotationRad =
    ctxResult && ctxResult.rotationRad !== undefined
      ? ctxResult.rotationRad
      : rotationParam ?? item.item.rotationRad;

  if (!ctxResult && ctx) {
    const ctxItem = ctx.getItems()[getFloorPlanItemKey(item)];

    if (ctxItem?.container) {
      const data = floorPlanItemToPolygonFromPixiContainer(ctxItem.container, {
        item,
        rotationParam,
      });

      size = data.size;
      centerOffset = data.centerOffset;

      if (data.rotationRad !== undefined) {
        rotationRad = data.rotationRad;
      }
    }
  }

  if (centerOffset && !centerOffsetDisabled) {
    coords = {
      x: coords.x + centerOffset.x,
      y: coords.y + centerOffset.y,
    };
  }

  let deltaX = 0;
  let deltaY = 0;

  if (percentGap !== undefined) {
    if (typeof percentGap === "number") {
      deltaX = deltaX + size.width * (1 + percentGap);
      deltaY = deltaY + size.height * (1 + percentGap);
    } else {
      deltaX = deltaX + size.width * (1 + percentGap.width);
      deltaY = deltaY + size.height * (1 + percentGap.height);
    }
  }

  if (absoluteGap !== undefined) {
    if (typeof absoluteGap === "number") {
      deltaX += absoluteGap;
      deltaY += absoluteGap;
    } else {
      deltaX += absoluteGap.width;
      deltaY += absoluteGap.height;
    }
  }

  const resultItem = pointToBox(
    coords,
    {
      width: Math.max(0, size.width + deltaX),
      height: Math.max(0, size.height + deltaY),
    },
    {
      rotationRad,
    }
  );

  return {
    item: resultItem,
    coords,
    centerOffset,
  };
};
